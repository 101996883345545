import React from 'react'
import PropTypes from 'prop-types'

import { InputProps, TextField, TextFieldProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { isFieldHighlighted } from '@helpers'

import { ReactHookFormTextFieldProps } from './ReactHookFormTextField'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles as any)

const useInputStyles = makeStyles(theme => ({
  refreshRoot: {
    border: `1px solid ${theme.palette.grayscale.light}`,
    backgroundColor: theme.palette.grayscale.inputBackground,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 4,
  },
  refreshDisabled: {
    backgroundColor: theme.palette.grayscale.disabledInputBackground,
  },
  refreshInput: {
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
  refreshFocused: {
    borderColor: theme.palette.tertiary.outlineBlue,
  },
}))

interface TextFieldBehaviorProps extends ReactHookFormTextFieldProps {
  endAdornment?: InputProps['endAdornment']
  error: TextFieldProps['error']
  helperText: TextFieldProps['helperText']
  value: TextFieldProps['value']
}

//* integration component to display NumberFormat as TextField
export function TextFieldBehavior({
  className,
  disabled,
  endAdornment,
  highlighted,
  label,
  ...rest
}: TextFieldBehaviorProps) {
  const classes = useStyles()
  const inputClasses = useInputStyles()
  return (
    <TextField
      className={cx(className, 'form-control', { 'form-control-error': rest.error })}
      fullWidth
      label={label}
      margin="normal"
      InputProps={{
        disableUnderline: true,
        classes: {
          root: cx(classes.bootstrapRoot, {
            [inputClasses.refreshRoot]: !!endAdornment,
            [inputClasses.refreshDisabled]: !!endAdornment && disabled,
          }),
          input: cx(classes.bootstrapInput, {
            highlighted: isFieldHighlighted(highlighted, rest.value),
            [inputClasses.refreshInput]: !!endAdornment,
          }),
          focused: cx({ [inputClasses.refreshFocused]: !!endAdornment }),
          error: classes.bootstrapError,
        },
        disabled,
        endAdornment,
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.bootstrapFormLabel,
      }}
      {...rest}
    />
  )
}

TextFieldBehavior.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  highlighted: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}
