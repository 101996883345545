import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { CreatePaymentResult } from '@contexts/PaymentProvider'

import { CustomDialogActions, CustomDialogHeader } from '@components/ui'
import { Button } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'

import { PAYMENT_FLOW } from '@constants'

import { OkButtonMessage } from '@messages'
import { PaymentResponseDialogBody } from './styles'

const WideButton = styled(Button)`
  width: 180px;
`

// type guard to check what kind of response is provided
function isBankPaymentResponse(data: CreatePaymentResult): data is BackendPaymentsResult {
  return typeof (data as BackendPaymentsResult).id !== 'undefined'
}

interface PaymentResponseViewProps {
  data: CreatePaymentResult
  flow: PaymentFlowType
  onClose: VoidFunction
  paidThroughOptions: BackendPaidThroughOption[]
}

function PurePaymentResponseView({ onClose, data, flow, paidThroughOptions }: PaymentResponseViewProps) {
  const isBankPayment = isBankPaymentResponse(data)

  let paidThroughName = null
  if (isBankPayment) {
    paidThroughName = paidThroughOptions.find(option => option.id === data.paidThroughId)?.name || ''
  }

  return (
    <>
      <CustomDialogHeader
        borderless
        title={
          flow === PAYMENT_FLOW.express ? (
            <FormattedMessage id="payment.dialog.response.title.pay" defaultMessage="Sikeres kifizetés" />
          ) : (
            <FormattedMessage id="payment.dialog.response.title.record" defaultMessage="Sikeres rögzítés" />
          )
        }
      />
      <PaymentResponseDialogBody>
        <Typography align="center" color="gray-80" size="400-sm">
          {flow === PAYMENT_FLOW.express && (
            <FormattedMessage
              id="payment.dialog.responseMessage.express"
              defaultMessage="A kifizetés az Expressz pénztárcádba került."
            />
          )}
          {flow === PAYMENT_FLOW.manual && isBankPayment && (
            <FormattedMessage
              id="payment.dialog.responseMessage.manual"
              defaultMessage="A rögzítés a(z) {paidThroughName} pénztárcádba került."
              values={{ paidThroughName }}
            />
          )}
          {flow === PAYMENT_FLOW.manual && !isBankPayment && (
            <FormattedMessage
              id="payment.dialog.responseMessage.manual.cashPayment"
              defaultMessage=" Az eltérő pénznemű tételek automatikusan az annak megfelelő házipénztárba kerültek rögzítésre."
              values={{ paidThroughName }}
            />
          )}
        </Typography>
      </PaymentResponseDialogBody>
      <CustomDialogActions borderless>
        <WideButton onClick={onClose} variant="primaryContained">
          {OkButtonMessage}
        </WideButton>
      </CustomDialogActions>
    </>
  )
}

export const PaymentResponseView = connect((state: Store) => ({
  paidThroughOptions: state.payment.paidThroughOptions,
}))(PurePaymentResponseView)

PaymentResponseView.displayName = 'PaymentResponseView'
