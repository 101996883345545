import { CompanyIntegrationProviders } from '@constants'

export enum IntegrationAction {
  INIT = 'init',
  SUCCESS = 'success',
  FAILURE = 'failure',
  UPDATE = 'update',
}

export type AcountoIntegrationProvider = {
  acounto_username: Nullable<string>
  id: number
  integration: CompanyIntegrationProviders.acounto
}

export type BillingoIntegrationProvider = {
  billingo_private_key: Nullable<string>
  billingo_public_key: Nullable<string>
  id: number
  integration: CompanyIntegrationProviders.billingo
  suspended_at?: Nullable<string>
}

export type PayeeIntegrationProvider = {
  id: number
  integration: CompanyIntegrationProviders.payee
  key: Nullable<string>
}

export type SzamlazzIntegrationProvider = {
  id: number
  integration: CompanyIntegrationProviders.szamlazz
}

export type IntegrationProvider =
  | AcountoIntegrationProvider
  | BillingoIntegrationProvider
  | PayeeIntegrationProvider
  | SzamlazzIntegrationProvider

export interface IntegrationsBlockState {
  companyIntegrations: Partial<{
    [CompanyIntegrationProviders.acounto]: AcountoIntegrationProvider
    [CompanyIntegrationProviders.billingo]: BillingoIntegrationProvider
    [CompanyIntegrationProviders.payee]: PayeeIntegrationProvider
    [CompanyIntegrationProviders.szamlazz]: SzamlazzIntegrationProvider
  }>
  error: Nullable<string>
  loading: boolean
}
