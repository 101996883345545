import React from 'react'

import { Field, FieldProps, FieldRenderProps, useField } from 'react-final-form'

import { USE_FIELD_VALUE_CONFIG } from '@constants'

interface ConditionalAccountingPeriodFieldProps extends FieldProps<string, FieldRenderProps<string>> {
  isVisible?: boolean
}

export function ConditionalAccountingPeriodField({
  children,
  isVisible = false,
  ...rest
}: ConditionalAccountingPeriodFieldProps) {
  const {
    input: { value: isAccountingPeriodRequired },
  } = useField<boolean>('need_accounting_period', USE_FIELD_VALUE_CONFIG)

  return isAccountingPeriodRequired || isVisible ? (
    <>
      <Field {...rest} />
      {children}
    </>
  ) : null
}
