import React from 'react'

import __isEmpty from 'lodash/isEmpty'

import { getDecimal, getMaximumFractionDigitsByCurrency } from '@helpers/common'
import { INCOME_TYPE_CASH_REGISTER_VOUCHER } from '@oldComponents/pages/DashboardIncomePage/helpers'

import { EMPTY_INCOME_ASSIGNMENT } from '@constants'

import { IncomeCashRegisterFormValues } from './types'

export function useFormInitialValues(
  details: IncomeDetailsFrontendValues,
  defaultCurrencyId: number,
  companyVats: CompanyVatType[]
): IncomeCashRegisterFormValues {
  return React.useMemo(() => {
    const maximumFractionDigits = getMaximumFractionDigitsByCurrency(defaultCurrencyId)
    if (__isEmpty(details)) {
      return {
        assignments: companyVats
          .filter(vat => vat.cash_register_voucher_visibility && vat.percent !== null) // percent null is mixed built in VAT
          .map(vat => ({
            ...EMPTY_INCOME_ASSIGNMENT,
            gross_amount: getDecimal(0, { minimumFractionDigits: 0, maximumFractionDigits }), // add default 0 to every vat
            vat: vat.id,
          })),
        currency: defaultCurrencyId,
        exchange_date: null,
        exchange_rate: getDecimal(1),
        fulfilled_at: '',
        gross_amount: getDecimal(0),
        income_type: INCOME_TYPE_CASH_REGISTER_VOUCHER,
        invoice_number: '',
        net_amount: getDecimal(0),
        simple_tags: [],
        tags: [],
        vat_amount: getDecimal(0),
      }
    }

    return {
      assignments: details.assignments as IncomeCashRegisterFormValues['assignments'],
      currency: details.currency,
      exchange_date: details.exchange_date || null,
      exchange_rate: details.exchange_rate || getDecimal(1),
      fulfilled_at: details.fulfilled_at || '',
      gross_amount: details.gross_amount || getDecimal(0),
      id: details.id,
      income_type: details.income_type,
      invoice_number: details.invoice_number || '',
      net_amount: details.net_amount || getDecimal(0),
      simple_tags: details.simple_tags,
      tags: details.tags,
      vat_amount: details.vat_amount || getDecimal(0),
    }
  }, [companyVats, defaultCurrencyId, details])
}
