import { isIntegrationInvoiceWithoutNav, isNAVInvoice } from '@helpers'

type InvoiceDetailsControlsProps<Data> = {
  isEditorUser: boolean
  isInvoiceProcessed?: (payload: Data) => boolean
}

export type InvoiceDetailsControls = {
  hasIntegration: boolean
  invoiceEditDisabled: boolean
  isEditDisabled: boolean
  isNavInvoice: boolean
  isNotEditable: boolean
  isProcessedInvoice: boolean
  readOnly: boolean
}

export function useInvoiceDetailsControls<Data extends ExpenseDetailsFrontendValues>(
  data: Data,
  { isEditorUser, isInvoiceProcessed }: InvoiceDetailsControlsProps<Data>
): InvoiceDetailsControls {
  const hasIntegration = isIntegrationInvoiceWithoutNav(data)
  const invoiceEditDisabled = isInvoiceProcessed?.(data) ?? false
  const isProcessedInvoice = isEditorUser && invoiceEditDisabled
  const isEditDisabled = !isEditorUser || isProcessedInvoice
  const isNavInvoice = isNAVInvoice(data)
  const readOnly = !isEditorUser

  return {
    hasIntegration,
    invoiceEditDisabled,
    isEditDisabled,
    isNavInvoice,
    isNotEditable: invoiceEditDisabled || hasIntegration,
    isProcessedInvoice,
    readOnly,
  }
}
