import { defineMessage, defineMessages } from 'react-intl'

export const formSelectMessages = defineMessages({
  selectPlaceholder: {
    id: 'form.placeholders.select',
    defaultMessage: 'Válassz a listából', //! duplicated
  },
  creatableSelectPlaceholder: {
    id: 'form.placeholders.creatableSelect',
    defaultMessage: 'Kezdj el gépelni',
  },
  selectOnCreate: {
    id: 'form.select.onCreatePromptText',
    defaultMessage: 'Létrehoz',
  },
  selectNoResultsText: {
    id: 'form.select.noResultsText',
    defaultMessage: 'Nincs találat',
  },
  asyncNoResultsText: {
    id: 'form.select.asyncNoResultsText',
    defaultMessage: 'Kezdj el gépelni a kereséshez',
  },
  selectLoadingText: {
    id: 'form.select.selectLoadingText',
    defaultMessage: 'Partner keresése folyamatban...',
  },
  tagsEmptyCreateText: {
    id: 'form.select.tagsEmptyCreateText',
    defaultMessage: 'Kezdj el gépelni, hogy létrehozd az első címkéded',
  },
  expenseTypeEmptyCreateText: {
    id: 'form.select.expenseTypeEmptyCreateText',
    defaultMessage: 'Kezdj el gépelni, hogy létrehozd az első költségtípusod',
  },
  ledgerNumberEmptyCreateText: {
    id: 'form.select.ledgerNumberEmptyCreateText',
    defaultMessage: 'Kezdj el gépelni, hogy létrehozd az első kontír tételed',
  },
  jobNumberEmptyCreateText: {
    id: 'form.select.jobNumberEmptyCreateText',
    defaultMessage: 'Kezdj el gépelni, hogy létrehozd az első munkaszámot',
  },
  paidThroughLedgerNumberEmptyCreateText: {
    id: 'form.select.paidThroughLedgerNumberEmptyCreateText',
    defaultMessage: 'Kezdj el gépelni, hogy létrehozd az első főkönyvi számod',
  },
  typeEmptyCreateText: {
    id: 'form.select.typeEmptyCreateText',
    defaultMessage: 'Kezdj el gépelni, hogy létrehozd az első {type}od',
  },
  asyncLoadingText: {
    id: 'form.select.asyncLoadingText',
    defaultMessage: 'Adatok lekérdezése folyamatban...',
  },
})

// translated redux-form-validators built in messages
export const formErrorMessages = defineMessages({
  confirmation: {
    id: 'form.errors.confirmation',
    defaultMessage: 'Nem egyezik a(z) `{fieldLabel}` mezővel',
  },
  email: {
    id: 'form.errors.email',
    defaultMessage: 'Érvénytelen e-mail cím',
  },
  required: {
    id: 'form.errors.required',
    defaultMessage: 'A mező kitöltése kötelező',
  },
  requiredSelection: {
    id: 'form.errors.requiredSelection',
    defaultMessage: 'A kiválasztás kötelező',
  },
  minLength8: {
    id: 'form.errors.minLength8',
    defaultMessage: 'Legalább 8 karaktert kell tartalmaznia',
  },
  maxLength32: {
    id: 'form.errors.maxLength32',
    defaultMessage: 'Legfeljebb 32 karaktert tartalmazhat',
  },
  isUniqueInvoiceNumber: {
    id: 'form.errors.isUniqueInvoiceNumber',
    defaultMessage: 'Ez a számla sorszám már foglalt',
  },
  eulaAcceptance: {
    id: 'form.errors.eulaAcceptance',
    defaultMessage: 'Az Adatvédelmi Tájékoztató elfogadása kötelező',
  },
  greaterThan: {
    id: 'form.errors.greaterThan',
    defaultMessage: 'Nagyobb szám kell legyen, mint {number}',
  },
  numericality: {
    id: 'form.errors.numericality',
    defaultMessage: 'Érvénytelen szám',
  },
  termsAcceptance: {
    id: 'form.errors.termsAcceptance',
    defaultMessage: 'Az Általános Szerződési Feltételek elfogadása kötelező',
  },
  newsletterAcceptance: {
    id: 'form.errors.newsletterAcceptance',
    defaultMessage: 'Az elfogadása kötelező',
  },
  invalidDate: {
    id: 'form.errors.invalidDate',
    defaultMessage: 'Érvénytelen dátum',
  },
  invalidFutureDate: {
    id: 'form.errors.invalidFutureDate',
    defaultMessage: 'Jövőbeli dátum nem adható meg.',
  },
  invalidEndDate: {
    id: 'form.errors.invalidEndDate',
    defaultMessage: 'A periódus vége nem lehet korábbi dátum, mint a kezdete.',
  },
  invalidPercent: {
    id: 'form.errors.invalidPercent',
    defaultMessage: 'Érvénytelen százalék érték',
  },
  invalidTaxIdentificationNumber: {
    id: 'form.errors.invalidTaxIdentificationNumber',
    defaultMessage: 'Érvénytelen adóazonosító jel (8xxxxxxxxx)',
  },
  accountNumberMaxLength: {
    id: 'form.errors.accountNumberMaxLength',
    defaultMessage: 'Az azonosító maximum ${max} számjegyű lehet',
  },
  filingPrefixLength: {
    id: 'form.errors.filingPrefixLength',
    defaultMessage: 'A prefix maximum 10 karakter lehet',
  },
  // TODO: can these be duplicated?
  invalidAmountFieldErrorText: {
    id: 'validations.errors.invalidAmountFieldErrorText',
    defaultMessage: 'Eltérő értékek',
  },
  invalidGrossAmountsErrorText: {
    id: 'validations.errors.invalidGrossAmountsErrorText',
    defaultMessage: 'A bontásban szereplő bruttó összegek értéke nem egyezik a számla bruttó összegének értékével.', // eslint-disable-line max-len
  },
  accountingPeriodIsRequired: {
    id: 'validations.errors.accountingPeriodIsRequired',
    defaultMessage: 'Az elszámolási időszak kitöltése kötelező.',
  },
  accountingPeriodMixedErrorText: {
    id: 'validations.errors.accountingPeriodMixedErrorText',
    defaultMessage: 'Az elszámolási időszak egyszerre nem tölthető ki a számlára és a tételekre.', // eslint-disable-line max-len
  },
  accountingPeriodEmptyErrorText: {
    id: 'validations.errors.accountingPeriodEmptyErrorText',
    defaultMessage: 'Az elszámolási időszakot ki kell tölteni a számlára vagy a tételekre.',
  },
  noAssignmentsError: {
    id: 'validations.errors.noAssignmentsError',
    defaultMessage: 'Legalább egy tétel megadása kötelező.',
  },
  invalidExchangeRateError: {
    id: 'validations.errors.invalidExchangeRate',
    defaultMessage: 'Érvénytelen árfolyam.',
  },
  invalidAuthPINLength: {
    id: 'validations.errors.invalidAuthPINLength',
    defaultMessage: 'Érvénytelen PIN.',
  },
  partnerIsCustomerOrProvider: {
    id: 'validations.errors.partnerIsCustomerOrProvider',
    defaultMessage: 'A partner típusának (szállító vagy vevő) megadása kötelező.',
  },
  genericMaxLength: {
    id: 'form.errors.genericMaxLength',
    defaultMessage: 'Az érték maximum ${max} karakter hosszú lehet',
  },
  invalidSocialSecurityNumber: {
    id: 'form.errors.taxation.invalidSocialSecurityNumber',
    defaultMessage: 'Érvénytelen TAJ szám (9 számjegy)',
  },
  invalidZipCode: {
    id: 'form.errors.taxation.invalidZipCode',
    defaultMessage: 'Érvénytelen irányítószám (4 számjegy)',
  },
  positiveOnlyValidation: {
    id: 'form.errors.taxation.validation.positiveOnly',
    defaultMessage: 'Negatív adó nem lehetséges',
  },
  invalidBankAccountNumber: {
    id: 'validations.errors.invalidBankAccountNumber',
    defaultMessage: 'Érvénytelen bankszámlaszám',
  },
  amountCalculationError: {
    id: 'validations.errors.amountCalculationError',
    defaultMessage: 'Számítási eltérés',
  },
})

export const quarantineFormMessages = defineMessages({
  confirmLabel: {
    id: 'dialogs.quarantineRemove.confirmLabel',
    defaultMessage: 'Írd be, hogy "OK" a megerősítéshez',
  },
  notOK: {
    id: 'form.errors.notOK',
    defaultMessage: 'A megadott érték nem megfelelő',
  },
})

export const exchangeRateDateMessage = defineMessage({
  id: 'form.exchangeRate.button.title',
  defaultMessage: 'Lekérdez',
})
