import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { parseFiltersFromUrlWorkerRunner } from '@webWorkers'

import {
  downloadFileWithURL,
  getActiveCompany,
  getActiveCompanyId,
  getBankTransactionParamsFromStore,
  getErrorMessage,
  getFormErrors,
  getPaidThroughParamsFromStore,
  getPaymentParamsFromStore,
  getPaymentTransactionParamsFromStore,
  getRFFFormErrors,
  getUrlFilterOptionsFromStore,
} from '@helpers'

import { BackgroundProcessActions, PAYMENT_FLOW } from '@constants'

import authActions from '../auth/actions'
import { callUrl } from '../common/api'
import expenseActions from '../expense/actions'
import filtersActions from '../filters/actions'
import salaryActions from '../salary/actions'
import taxActions from '../tax/actions'
import actions from './actions'
import * as api from './api'
import { BankTransactionsExportBackgroundAction, PaymentTransactionsExportBackgroundAction } from './backgroundActions'

function* initPaidThroughListPageLoadSaga({ payload: { config, location, navigate }, meta: { resolve, reject } }) {
  try {
    //* call worker
    const { filters, params, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions: {},
      location,
    })
    yield all([
      put(filtersActions.initPaidThroughListFiltersFromUrl.request({ filters })),
      put(filtersActions.initPaidThroughListParamsFromUrl.request(params)),
    ])
    yield call(resolve, validationLevel)
    yield put(actions.fetchPaidThroughs.request({ navigate }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// paid through
function* fetchPaidThroughsSaga({ payload: { navigate } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getPaidThroughParamsFromStore, { withCursor: true })
    const response = yield call(api.fetchPaidThroughs, companyId, params)
    yield put(actions.fetchPaidThroughs.success(response.data))
    //* << sync from store to url
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: params, skipDateFilters: true }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPaidThroughs.failure(errorMsg))
  }
}

function* fetchPaidThroughsListSimpleSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getPaidThroughParamsFromStore, { withCursor: true })
    const response = yield call(api.fetchPaidThroughs, companyId, params)
    yield put(actions.fetchPaidThroughs.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPaidThroughs.failure(errorMsg))
  }
}

// cursor pagination (next or previous url)
function* fetchPaidThroughsByPagingSaga({ payload: { url } }) {
  try {
    const response = yield call(api.fetchDataByUrl, url)
    yield put(actions.fetchPaidThroughs.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPaidThroughs.failure(errorMsg))
  }
}

// options
function* fetchPaidThroughOptionsSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchPaidThroughOptions, companyId)
    yield put(actions.fetchPaidThroughOptions.success(response.data))
  } catch (error) {
    yield put(actions.fetchPaidThroughOptions.failure())
  }
}

function* removePaidThroughSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.removePaidThrough, companyId, payload)
    yield put(actions.removePaidThrough.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.removePaidThrough.failure())
    yield call(reject, errorMsg)
  }
}

function* createPaidThroughSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.createPaidThrough, companyId, payload)
    yield put(actions.createPaidThrough.success())
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* fetchPaidThroughDetailsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchPaidThroughDetails, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updatePaidThroughSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updatePaidThrough, companyId, payload)
    yield put(actions.updatePaidThrough.success())
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield put(actions.updatePaidThrough.failure())
    yield call(reject, formErrors)
  }
}

//* PAYMENTS
function* initPaymentOrderListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const paidThroughOptionsResponse = yield call(api.fetchPaidThroughOptions, companyId)
    yield put(actions.fetchPaidThroughOptions.success(paidThroughOptionsResponse.data))

    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)
    //* >> sync from url to store: call worker
    const { filters, params, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initPaymentOrderListFiltersFromUrl.request({ filters })),
      put(filtersActions.initPaymentOrderListParamsFromUrl.request(params)),
    ])
    yield call(resolve, validationLevel)
    yield put(actions.fetchPayments.request({ navigate }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchPaymentsSaga({ payload: { navigate } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getPaymentParamsFromStore, { withCursor: true })
    const response = yield call(api.fetchPaymentOrders, companyId, params)
    yield put(actions.fetchPayments.success(response.data))
    //* << sync from store to url
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: params }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPayments.failure(errorMsg))
  }
}

function* fetchPaymentsListSimpleSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getPaymentParamsFromStore, { withCursor: true })
    const response = yield call(api.fetchPaymentOrders, companyId, params)
    yield put(actions.fetchPayments.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPayments.failure(errorMsg))
  }
}

// cursor pagination (next or previous url)
function* fetchPaymentsByPagingSaga({ payload: { url } }) {
  try {
    const response = yield call(api.fetchDataByUrl, url)
    yield put(actions.fetchPayments.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPayments.failure(errorMsg))
  }
}

//* v2
function* createPaymentV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const { id: companyId, default_paid_through } = yield select(getActiveCompany)
    const response = yield call(api.createPayment, companyId, payload)
    yield put(actions.createPaymentV2.success(payload)) // pass request payload
    if (payload.flow === PAYMENT_FLOW.transfer && !default_paid_through) {
      // refresh company details when payment set default paid-through during payment create
      yield put(authActions.refreshCompany.request(companyId))
    }
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* successPaymentV2Saga({ payload: { transactionType } }) {
  if (transactionType === 'expense') {
    yield put(expenseActions.triggerExpenseListUpdate.request())
  } else if (transactionType === 'salary') {
    yield put(salaryActions.triggerSalaryListUpdate.request())
  } else if (transactionType === 'tax') {
    yield put(taxActions.triggerTaxListUpdate.request())
  }
}

//* v2
function* removePaymentSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(callUrl, payload)
    yield put(actions.removePayment.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* initializePaymentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const { data } = yield call(api.initializePayment, companyId, payload)
    if (data.transactions.length) {
      const paidThroughOptionsResponse = yield call(api.fetchPaidThroughOptions, companyId)
      yield put(actions.fetchPaidThroughOptions.success(paidThroughOptionsResponse.data))
    }
    yield call(resolve, data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchPaymentSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchPayment, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* updatePaymentSaga({ payload: { skipStoreUpdate, ...data }, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updatePayment, companyId, data)
    if (!skipStoreUpdate) {
      // need when payment updated from invoice payment history edit flow in expensedetilsform
      yield put(actions.updatePayment.success(response.data))
    }
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getFormErrors(error)
    yield call(reject, formErrors)
  }
}

// EXPORT
function* exportPaymentSaga({ payload, meta: { resolve, reject } }) {
  try {
    // this is a not delayed bg-job on backend so response containes processed data when returns
    const response = yield call(callUrl, payload)
    yield call(downloadFileWithURL, response) // download file
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// EXPENSE payments from details
function* loadInvoiceTransactionsSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.loadInvoiceTransactions, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// Payment form BALANCE
function* loadPaidThroughProviderBalanceSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.loadPaidThroughProviderBalance, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* BANK TRANSACTIONS
function* initBankTransactionListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const paidThroughOptionsResponse = yield call(api.fetchPaidThroughOptions, companyId)
    yield put(actions.fetchPaidThroughOptions.success(paidThroughOptionsResponse.data))

    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)
    //* call worker
    const { filters, params, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initBankTransactionListFiltersFromUrl.request({ filters })),
      put(filtersActions.initBankTransactionListParamsFromUrl.request(params)),
    ])
    yield call(resolve, validationLevel)
    yield put(actions.fetchBankTransactions.request({ navigate }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchBankTransactionsSaga({ payload: { navigate } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getBankTransactionParamsFromStore, { withCursor: true })
    const response = yield call(api.fetchBankTransactions, companyId, params)
    yield put(actions.fetchBankTransactions.success(response.data))
    //* << sync from store to url
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: params }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchBankTransactions.failure(errorMsg))
  }
}

function* fetchBankTransactionsListSimpleSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getBankTransactionParamsFromStore, { withCursor: true })
    const response = yield call(api.fetchBankTransactions, companyId, params)
    yield put(actions.fetchBankTransactions.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchBankTransactions.failure(errorMsg))
  }
}

// cursor pagination (next or previous url)
function* fetchBankTransactionsByPagingSaga({ payload: { url } }) {
  try {
    const response = yield call(api.fetchDataByUrl, url)
    yield put(actions.fetchBankTransactions.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchBankTransactions.failure(errorMsg))
  }
}

function* startBankTransactionsExportSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getBankTransactionParamsFromStore)
    const apiPayload = { action: BackgroundProcessActions.BANK_TRANSACTIONS_EXPORT, ...filters }
    const response = yield call(BankTransactionsExportBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBankTransactionsExportSaga() {
  yield call(BankTransactionsExportBackgroundAction.stop)
}

//* PAYMENT TRANSACTIONS
function* initPaymentTransactionListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const paidThroughOptionsResponse = yield call(api.fetchPaidThroughOptions, companyId)
    yield put(actions.fetchPaidThroughOptions.success(paidThroughOptionsResponse.data))

    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)
    //* call worker
    const { filters, params, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initPaymentTransactionListFiltersFromUrl.request({ filters })),
      put(filtersActions.initPaymentTransactionListParamsFromUrl.request(params)),
    ])
    yield call(resolve, validationLevel)
    yield put(actions.fetchPaymentTransactions.request({ navigate }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* fetchPaymentTransactionsSaga({ payload: { navigate } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getPaymentTransactionParamsFromStore, { withCursor: true })
    const response = yield call(api.fetchPaymentTransactions, companyId, params)
    yield put(actions.fetchPaymentTransactions.success(response.data))
    //* << sync from store to url
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: params }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPaymentTransactions.failure(errorMsg))
  }
}

function* fetchPaymentTransactionsListSimpleSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const params = yield select(getPaymentTransactionParamsFromStore, { withCursor: true })
    const response = yield call(api.fetchPaymentTransactions, companyId, params)
    yield put(actions.fetchPaymentTransactions.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPaymentTransactions.failure(errorMsg))
  }
}

function* fetchPaymentTransactionsByPagingSaga({ payload: { url } }) {
  try {
    const response = yield call(api.fetchDataByUrl, url)
    yield put(actions.fetchPaymentTransactions.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchPaymentTransactions.failure(errorMsg))
  }
}

function* startPaymentTransactionsExportSaga({ meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getPaymentTransactionParamsFromStore)
    const apiPayload = { action: BackgroundProcessActions.PAYMENT_TRANSACTIONS_EXPORT, ...filters }
    const response = yield call(PaymentTransactionsExportBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopPaymentTransactionsExportSaga() {
  yield call(PaymentTransactionsExportBackgroundAction.stop)
}

function* removePaymentTransactionSaga({ payload, meta: { resolve, reject } }) {
  try {
    yield call(callUrl, payload)
    yield put(actions.removePaymentTransaction.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  //* PAID THROUGHS
  yield takeLatest(actions.initPaidThroughListPageLoad.REQUEST, initPaidThroughListPageLoadSaga)
  yield takeLatest(actions.fetchPaidThroughs.REQUEST, fetchPaidThroughsSaga)
  yield takeLatest(
    [
      actions.createPaidThrough.SUCCESS,
      actions.updatePaidThrough.SUCCESS,
      actions.removePaidThrough.SUCCESS,
      actions.updatePaidThroughsRowsPerPage.REQUEST,
      actions.resetPaidThroughsPagination.REQUEST,
      filtersActions.updatePaidThroughFilters.REQUEST,
      filtersActions.resetPaidThroughFilters.REQUEST,
    ],
    fetchPaidThroughsListSimpleSaga
  )
  yield takeLatest(actions.fetchPaidThroughOptions.REQUEST, fetchPaidThroughOptionsSaga)
  yield takeLatest(actions.removePaidThrough.REQUEST, removePaidThroughSaga)
  yield takeLatest(actions.createPaidThrough.REQUEST, createPaidThroughSaga)
  yield takeLatest(actions.fetchPaidThroughDetails.REQUEST, fetchPaidThroughDetailsSaga)
  yield takeLatest(actions.updatePaidThrough.REQUEST, updatePaidThroughSaga)
  //* PAYMENT ORDERS
  yield takeLatest(actions.initPaymentOrderListPageLoad.REQUEST, initPaymentOrderListPageLoadSaga)
  yield takeLatest(actions.fetchPayments.REQUEST, fetchPaymentsSaga)
  yield takeLatest(
    [
      actions.updatePaymentsRowsPerPage.REQUEST,
      filtersActions.updatePaymentOrderFilters.REQUEST,
      filtersActions.resetPaymentOrderFilters.REQUEST,
      filtersActions.togglePaymentOrderDateFilter.REQUEST,
      actions.removePayment.SUCCESS,
      actions.resetPaymentOrdersPagination.REQUEST,
    ],

    fetchPaymentsListSimpleSaga
  )
  // cursor pagination
  yield takeLatest(actions.fetchPaymentsByPaging.REQUEST, fetchPaymentsByPagingSaga)
  yield takeLatest(actions.updatePayment.REQUEST, updatePaymentSaga)
  yield takeLatest(actions.removePayment.REQUEST, removePaymentSaga)
  yield takeLatest(actions.initializePayment.REQUEST, initializePaymentSaga)
  yield takeLatest(actions.fetchPayment.REQUEST, fetchPaymentSaga)
  // EXPORT (allow multiple call in parallel request)
  yield takeEvery(actions.exportPayment.REQUEST, exportPaymentSaga)
  // EXPENSE details
  yield takeLatest(actions.loadInvoiceTransactions.REQUEST, loadInvoiceTransactionsSaga)
  // PAYMENT BALANCE
  yield takeLatest(actions.loadPaidThroughProviderBalance.REQUEST, loadPaidThroughProviderBalanceSaga)
  //* BANK TRANSACTIONS
  yield takeLatest(actions.initBankTransactionListPageLoad.REQUEST, initBankTransactionListPageLoadSaga)
  yield takeLatest(actions.fetchBankTransactions.REQUEST, fetchBankTransactionsSaga)
  yield takeLatest(
    [
      actions.resetBankTransactionsPagination.REQUEST,
      actions.updateBankTransactionsRowsPerPage.REQUEST,
      filtersActions.resetBankTransactionsFilters.REQUEST,
      filtersActions.toggleBankTransactionsDateFilter.REQUEST,
      filtersActions.updateBankTransactionsFilters.REQUEST,
    ],
    fetchBankTransactionsListSimpleSaga
  )
  //* PAYMENT TRANSACTIONS
  yield takeLatest(actions.initPaymentTransactionListPageLoad.REQUEST, initPaymentTransactionListPageLoadSaga)
  yield takeLatest(actions.fetchPaymentTransactions.REQUEST, fetchPaymentTransactionsSaga)
  yield takeLatest(
    [
      actions.removePaymentTransaction.SUCCESS,
      actions.resetPaymentTransactionsPagination.REQUEST,
      actions.updatePaymentTransactionsRowsPerPage.REQUEST,
      filtersActions.resetPaymentTransactionsFilters.REQUEST,
      filtersActions.togglePaymentTransactionsDateFilter.REQUEST,
      filtersActions.updatePaymentTransactionsFilters.REQUEST,
    ],
    fetchPaymentTransactionsListSimpleSaga
  )

  // cursor pagination
  yield takeLatest(actions.fetchPaidThroughsByPaging.REQUEST, fetchPaidThroughsByPagingSaga)
  yield takeLatest(actions.fetchBankTransactionsByPaging.REQUEST, fetchBankTransactionsByPagingSaga)
  yield takeLatest(actions.fetchPaymentTransactionsByPaging.REQUEST, fetchPaymentTransactionsByPagingSaga)
  yield takeLatest(actions.createPaymentV2.REQUEST, createPaymentV2Saga)
  yield takeLatest(actions.createPaymentV2.SUCCESS, successPaymentV2Saga)
  yield takeLatest(actions.startBankTransactionsExport.REQUEST, startBankTransactionsExportSaga)
  yield takeLatest(actions.stopBankTransactionsExport.REQUEST, stopBankTransactionsExportSaga)
  yield takeLatest(actions.startPaymentTransactionsExport.REQUEST, startPaymentTransactionsExportSaga)
  yield takeLatest(actions.stopPaymentTransactionsExport.REQUEST, stopPaymentTransactionsExportSaga)
  yield takeLatest(actions.removePaymentTransaction.REQUEST, removePaymentTransactionSaga)
}
