import { FieldState, FieldValidator } from 'final-form'
import { IntlFormatters } from 'react-intl'

import { roundToDecimal } from '@helpers'

import { FORM_ERROR_GROSS_AMOUNT, FROM_ERROR_NO_ASSIGNMENTS } from '@constants'

import { formErrorMessages } from '@messages'

export type ValidatorProps = {
  callInvoiceNumberCheck: AsyncFunction<
    { id: number; invoice_number: string; partner: number; income_type?: number },
    { result: boolean }
  >
  companyDefaultCurrency: number
  currencies: Currency[]
  formatMessage: IntlFormatters['formatMessage']
  incomeType?: number
  [key: string]: unknown
}

export type ValidatorFormValues = {
  assignments: InvoiceDetailsAssignment[]
  gross_amount?: number
  id: number
  invoice_number: string
  partner?: number
  partner_id?: number
  [key: string]: unknown
}

type CustomValidationErrors = Record<string, string>

type ValidatorFunction = (values: ValidatorFormValues, props: ValidatorProps) => CustomValidationErrors

type ConditionFunction<FieldValue> = (value: FieldValue, allValues: object, meta?: FieldState<FieldValue>) => boolean

interface AsyncValidateInvoiceNumberProps
  extends Pick<ValidatorProps, 'callInvoiceNumberCheck' | 'formatMessage'>,
    Pick<ValidatorFormValues, 'id' | 'partner' | 'invoice_number'> {
  income_type?: ValidatorProps['incomeType']
}

function asyncValidateInvoiceNumber({
  callInvoiceNumberCheck,
  formatMessage,
  id,
  income_type,
  invoice_number,
  partner,
}: AsyncValidateInvoiceNumberProps) {
  const promise = new Promise<void>(resolve => {
    resolve()
  })
  if (!invoice_number || !partner) {
    // do nothing
    // NOTE: form>partner_id field validator will handle missing partner error
    return promise.then(() => ({}))
  } else {
    // have partner and invoice_number so do validation
    return callInvoiceNumberCheck({ id, invoice_number, partner, income_type }).then(data => {
      if (!data.result) {
        return {
          invoice_number: formatMessage(formErrorMessages.isUniqueInvoiceNumber),
        }
      }
      return {}
    })
  }
}

// must be return a promise
function asyncValidator(values: ValidatorFormValues, props: ValidatorProps) {
  return asyncValidateInvoiceNumber({
    callInvoiceNumberCheck: props.callInvoiceNumberCheck,
    formatMessage: props.formatMessage,
    id: values.id,
    income_type: props.incomeType,
    invoice_number: values.invoice_number,
    partner: values.partner_id,
  })
}

// VALIDATOR on invoice details form submit (DashboardCostPage:onSubmitHandler)
export function validateSumGrossAmounts(values: ValidatorFormValues, { formatMessage }: ValidatorProps) {
  const assignments = values?.assignments ?? []
  const mainGrossAmount = roundToDecimal(values?.gross_amount ?? 0)

  if (assignments.length > 0) {
    const total = assignments
      .map(assignment => Number(assignment?.gross_amount ?? 0))
      .reduce((acc, curr) => (acc += curr), 0)
    const assignmentsGrossAmount = roundToDecimal(total)

    const errors: CustomValidationErrors = {}
    if (mainGrossAmount !== assignmentsGrossAmount) {
      errors.gross_amount = formatMessage(formErrorMessages.invalidAmountFieldErrorText)
      assignments.forEach((a, index) => {
        errors[`assignments[${index}].gross_amount`] = formatMessage(formErrorMessages.invalidAmountFieldErrorText)
      })
      errors[FORM_ERROR_GROSS_AMOUNT] = formatMessage(formErrorMessages.invalidGrossAmountsErrorText)
    }

    return errors
  }
}

function validateAssignments(values: ValidatorFormValues, { formatMessage }: ValidatorProps) {
  const assignments = values?.assignments ?? []
  const errors: CustomValidationErrors = {}
  if (assignments.length === 0) {
    errors[FROM_ERROR_NO_ASSIGNMENTS] = formatMessage(formErrorMessages.noAssignmentsError)
  }
  return errors
}

function validateExchangeRate(values: ValidatorFormValues, { companyDefaultCurrency, formatMessage }: ValidatorProps) {
  const errors: CustomValidationErrors = {}
  if (values.exchange_rate) {
    const exchangeRate = Number(values.exchange_rate)
    if (values.currency !== companyDefaultCurrency && (!exchangeRate || exchangeRate === 1)) {
      errors['exchange_rate'] = formatMessage(formErrorMessages.invalidExchangeRateError)
    }
  }

  return errors
}

// generic higher order rule to apply validation conditionally
export function applyIf<FieldValue>(condition: ConditionFunction<FieldValue>) {
  return function validatorCreator(rule: FieldValidator<FieldValue>): FieldValidator<FieldValue> {
    return function validator(value, allValues, meta) {
      return condition(value, allValues, meta) && rule(value, allValues, meta)
    }
  }
}

// helper validation to sync meta.data.error with sync error on fields
export const customEngineValidator: FieldValidator<any> = (_value, _values, meta) => {
  // @ts-expect-error custom meta data is not provided via FieldState
  const { data: { error } = {} } = meta
  return error
}

export function composeFieldValidators<FieldValue>(
  ...validators: FieldValidator<FieldValue>[]
): FieldValidator<FieldValue> {
  return function composedFieldValidator(value, allValues, meta) {
    return validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)
  }
}

function composeValidators(...validators: ValidatorFunction[]): ValidatorFunction {
  return function composedValidator(values, props) {
    return validators.reduce(
      (error, validator) => ({ ...error, ...validator(values, props) }),
      {} as CustomValidationErrors
    )
  }
}

export function validator(values: ValidatorFormValues, props: ValidatorProps) {
  const errors = composeValidators(
    // validateSumGrossAmounts,
    validateAssignments,
    validateExchangeRate
  )(values, props)

  return errors && Object.keys(errors).length ? errors : asyncValidator(values, props)
}

//* INCOME VALIDATORS
export function manualIncomeValidatorONE(values: ValidatorFormValues, props: ValidatorProps) {
  return composeValidators(
    // validateSumGrossAmounts,
    // validateAssignments,
    validateExchangeRate
  )(values, props)
}
