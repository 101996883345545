import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { UploadedFileError } from '@components/UploadedFiles'
import { InvoiceClassifyConfirmDialog } from '@oldComponents/dialogs'
import FileDropzone, {
  FileDropzoneFontSizes,
  FileDropzoneVariants,
  useFileDropzone,
  UseFileDropzoneOnDropHandler,
  UseFileDropzoneUploadHandler,
} from '@oldComponents/FileDropzone'

import { StopRecognitionButton } from './StopRecognitionButton'
import { SmallUploadFormProps } from './types'

import { FileUploadErrorMessage } from '@messages'
import { MultipleFileUploadErrorMessage } from './messages'
import { useStyles } from './styles'

type ExtendedDropzoneState = {
  fileUploadPayload: Nullable<Record<string, unknown>>
  isClassifyDialogVisible: boolean
  isExtendedTimerPassed: boolean
}

export function SmallUploadForm({
  acceptedFileMimeTypes,
  disabled = false,
  fileUploadText,
  getFileUploadPayload,
  maxSize = 0,
  onUploadSuccess,
  showInvoiceClassifyConfirm = false,
  showServerError = false,
  uploadDescriptionText,
  uploadFile,
  uploadImgSrc,
  uploading,
  uploadSuggestionText,
  withExtendedTimer = 0,
}: SmallUploadFormProps) {
  const classes = useStyles()
  const cPromiseRef = useCancellablePromiseRef()
  const extendedTimerRef = React.useRef<number>()

  React.useEffect(() => {
    return () => {
      if (extendedTimerRef.current) {
        window.clearTimeout(extendedTimerRef.current)
      }
    }
  }, [])

  const uploadHandler: UseFileDropzoneUploadHandler<ExtendedDropzoneState> = React.useCallback(
    async (state, setState, argument) => {
      // when if it is an extended upload set timer
      if (withExtendedTimer > 0) {
        extendedTimerRef.current = window.setTimeout(() => {
          setState(prevState => ({ ...prevState, isExtendedTimerPassed: true }))
        }, withExtendedTimer)
      }
      try {
        cPromiseRef.current = cancellablePromise(uploadFile(argument?.fileUploadPayload || state.fileUploadPayload))
        // upload
        const results = await cPromiseRef.current.promise
        // if clear ongoing extended timer
        if (extendedTimerRef.current) {
          window.clearTimeout(extendedTimerRef.current)
        }
        return onUploadSuccess(results)
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          const error = (
            <>
              {FileUploadErrorMessage}
              {showServerError && <div>{errorMsg}</div>}
            </>
          )
          // because onDropHandler calls this function we need to set error here explicitly and return it so the hook will not think it is a success
          setState(state => ({ ...state, error }))
          return error
        }
      }
    },
    [cPromiseRef, onUploadSuccess, showServerError, uploadFile, withExtendedTimer]
  )

  const onDropHandler: UseFileDropzoneOnDropHandler<ExtendedDropzoneState> = React.useCallback(
    (acceptedFiles, rejectedFiles, state, setState) => {
      setState(prevState => ({ ...prevState, error: null }))
      // when dropzone is not having multiple prop it will automatically reject multiple files
      if (rejectedFiles.length > 1) {
        // multiple file upload, not supported
        setState(state => ({
          ...state,
          errorTimeout: 4000,
          error: MultipleFileUploadErrorMessage,
        }))
      } else if (rejectedFiles.length > 0) {
        setState(state => ({
          ...state,
          errorTimeout: 2000,
          error: <UploadedFileError errors={rejectedFiles[0].errors} />,
        }))
      } else {
        const fileUploadPayload = getFileUploadPayload(acceptedFiles)

        if (showInvoiceClassifyConfirm) {
          setState(prevState => ({
            ...prevState,
            isClassifyDialogVisible: true,
            fileUploadPayload,
          }))
        } else {
          uploadHandler(state, setState, { fileUploadPayload })
        }
      }
    },
    [getFileUploadPayload, showInvoiceClassifyConfirm, uploadHandler]
  )

  const {
    error,
    fileUploadPayload,
    handleOnDrop,
    handleUploadFiles,
    isClassifyDialogVisible,
    setExtendedState,
    isExtendedTimerPassed,
  } = useFileDropzone({
    uploadHandler,
    onDropHandler,
    extendedState: {
      fileUploadPayload: null as Nullable<Record<string, unknown>>,
      isClassifyDialogVisible: false,
      isExtendedTimerPassed: false,
    },
  })

  //* invoice classify control
  const confirmInvoiceClassify = React.useCallback(() => {
    const updatedFileUploadPayload = { ...(fileUploadPayload ? fileUploadPayload : {}), need_classify: true }
    setExtendedState({
      isClassifyDialogVisible: false,
      fileUploadPayload: updatedFileUploadPayload,
    })
    handleUploadFiles({ fileUploadPayload: updatedFileUploadPayload })
  }, [setExtendedState, fileUploadPayload, handleUploadFiles])

  const skipInvoiceClassify = React.useCallback(() => {
    setExtendedState({ isClassifyDialogVisible: false })
    handleUploadFiles()
  }, [setExtendedState, handleUploadFiles])

  const handleCancelFileUpload = React.useCallback(() => {
    setExtendedState({
      isClassifyDialogVisible: false,
      fileUploadPayload: null,
    })
  }, [setExtendedState])

  return (
    <>
      <FileDropzone
        acceptedFileMimeTypes={acceptedFileMimeTypes}
        className={cx(classes.dropzone, 'small')}
        customUploadingExtraContent={
          isExtendedTimerPassed ? (
            <div>
              <StopRecognitionButton />
            </div>
          ) : undefined
        }
        customUploadingText={
          isExtendedTimerPassed ? (
            <FormattedMessage
              id="fileUpload.texts.extendedUploading"
              defaultMessage="A felismerés várhatóan a szokásosnál tovább tart, a folyamat megszakításával a számlát azonnal szerkesztheted."
            />
          ) : (
            <FormattedMessage id="fileUpload.texts.uploading" defaultMessage="Fájl feltöltése folyamatban" />
          )
        }
        disabled={disabled || uploading}
        error={error}
        fileUploadText={
          <>
            {fileUploadText}&nbsp;{uploadDescriptionText}
          </>
        }
        maxSize={maxSize}
        multiple={false}
        onDrop={handleOnDrop}
        uploadImgSrc={uploadImgSrc}
        uploading={uploading}
        uploadSuggestionText={uploadSuggestionText}
        variant={FileDropzoneVariants.SMALL}
        fontSize={FileDropzoneFontSizes.BIG}
      />
      {showInvoiceClassifyConfirm && (
        <InvoiceClassifyConfirmDialog
          open={isClassifyDialogVisible}
          onClose={handleCancelFileUpload}
          onConfirm={confirmInvoiceClassify}
          onSkip={skipInvoiceClassify}
        />
      )}
    </>
  )
}

SmallUploadForm.propTypes = {
  acceptedFileMimeTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  disabled: PropTypes.bool,
  fileUploadText: PropTypes.node.isRequired,
  getFileUploadPayload: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  onUploadSuccess: PropTypes.func.isRequired,
  showServerError: PropTypes.bool,
  uploadDescriptionText: PropTypes.node.isRequired,
  uploadFile: PropTypes.func.isRequired,
  uploadImgSrc: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  uploadSuggestionText: PropTypes.node.isRequired,
  withExtendedTimer: PropTypes.number,
  showInvoiceClassifyConfirm: PropTypes.bool,
}
