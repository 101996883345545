import React from 'react'

import styled from 'styled-components'

import { InfoIcon } from '@components/ui/svgIcons'
import { LightTooltip } from '@oldComponents/ui'

import { TABS_CLASS_NAMES } from '@constants'

const LabelWrapper = styled.span`
  .${TABS_CLASS_NAMES.selectedTabClassName} & {
    color: ${({ theme }) => theme.colors.gray[80]};
  }
  &::after {
    content: '';
    display: block;
    height: 2px;
    border-radius: 2px 2px 0px 0px;

    .${TABS_CLASS_NAMES.selectedTabClassName} & {
      background-color: ${({ theme }) => theme.colors.secondary[50]};
    }
  }
`

const PaymentDialogTabLabelWrapper = styled.span`
  display: flex;
  gap: 10px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors.gray[70]};
`

interface PaymentDialogTabLabelProps {
  label: StringOrMessage
  tooltip: StringOrMessage
}

// helper to avoid buttonBase ref forwarding issues
export function PaymentDialogTabLabel({ label, tooltip }: PaymentDialogTabLabelProps) {
  return (
    <PaymentDialogTabLabelWrapper>
      <LabelWrapper>{label}</LabelWrapper>
      <LightTooltip title={tooltip} placement="top" PopperProps={{ disablePortal: true }}>
        <span>
          <InfoIcon size={14} />
        </span>
      </LightTooltip>
    </PaymentDialogTabLabelWrapper>
  )
}
