import __find from 'lodash/find'
import { IntlShape } from 'react-intl'

import { CURRENCY_OPTIONS } from '../constants'

export function getCurrency(currencyId: number, currencies: Currency[]) {
  return __find(currencies, ['id', currencyId])?.['name'] ?? CURRENCY_OPTIONS.currency
}

export function formatCurrency(
  value: number | string,
  {
    formatter,
    currency,
    currencies,
  }: { formatter: IntlShape['formatNumber']; currency: number; currencies: Currency[] }
) {
  return formatter(Number(value), {
    ...CURRENCY_OPTIONS,
    currency: getCurrency(currency, currencies),
  })
}

export function getCurrencyOptions(currency: string) {
  return {
    ...CURRENCY_OPTIONS,
    currency,
  }
}

export function getFormattedCurrency(
  locale: Locale,
  options: typeof CURRENCY_OPTIONS,
  formatNumber: IntlShape['formatNumber']
) {
  const formatted = formatNumber(1, options)
  return locale === 'hu' ? formatted.substring(2) : formatted.substring(0, formatted.length - 2)
}

export function getDefaultCurrencyName(state: Store) {
  // for safety because company is not always provided
  if (!state.auth.company.data.default_currency) {
    return CURRENCY_OPTIONS.currency
  }
  return getCurrency(state.auth.company.data.default_currency, state.dashboard.common.currencies)
}
