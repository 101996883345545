import styled from 'styled-components'

export const TagIconContainerDiv = styled.div`
  display: flex;
  & > svg {
    margin-left: 3px;
  }
`

export const ButtonsContainerDiv = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
`
