import Qs from 'qs'

import { injectCompanyToURL } from '@helpers'
import { transformV2ParamsToV1 } from '@services/helpers'

import { CreatePaymentPayload, EditPaymentPayload } from '@contexts/PaymentProvider/types'

import Api from '../api'
import {
  API_BANK_TRANSACTIONS_ACTIONS_URL,
  API_BANK_TRANSACTIONS_LIST_URL,
  API_EXPENSE_PAYMENT_TRANSACTIONS_URL,
  API_PAID_THROUGH_BALANCE_URL,
  API_PAID_THROUGH_CREATE_URL,
  API_PAID_THROUGH_DELETE_URL,
  API_PAID_THROUGH_DETAILS_URL,
  API_PAID_THROUGH_LIST_URL,
  API_PAID_THROUGH_OPTIONS_URL,
  API_PAID_THROUGH_UPDATE_URL,
  API_PAYMENT_CREATE_URL,
  API_PAYMENT_DETAILS_URL,
  API_PAYMENT_LIST_URL,
  API_PAYMENT_TRANSACTIONS_ACTIONS_URL,
  API_PAYMENT_TRANSACTIONS_LIST_URL,
  API_PAYMENT_UPDATE_URL,
  API_SETUP_PAYMENT_URL,
} from './urls'

// PAID THROUGHS
export function fetchPaidThroughs(companyId: number, params: Partial<PaidThroughsStoreFilters>) {
  return Api.get(injectCompanyToURL(API_PAID_THROUGH_LIST_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(v2Params) {
        return Qs.stringify(transformV2ParamsToV1(v2Params))
      },
    },
  })
}

export function fetchPaidThroughOptions(companyId: number) {
  return Api.get<BackendPaidThroughOptionsResponse>(injectCompanyToURL(API_PAID_THROUGH_OPTIONS_URL, companyId))
}

export function createPaidThrough(companyId: number, data: Record<string, unknown>) {
  return Api.post(injectCompanyToURL(API_PAID_THROUGH_CREATE_URL, companyId), data)
}

export function updatePaidThrough(companyId: number, data: Record<string, unknown>) {
  return Api.patch(
    injectCompanyToURL(API_PAID_THROUGH_UPDATE_URL, companyId).replace('{paid_through_id}', String(data.id)),
    data
  )
}

export function removePaidThrough(companyId: number, paidThroughId: number) {
  return Api.delete(
    injectCompanyToURL(API_PAID_THROUGH_DELETE_URL, companyId).replace('{paid_through_id}', String(paidThroughId))
  )
}

export function fetchPaidThroughDetails(companyId: number, paidThroughId: number, params: Record<string, string>) {
  return Api.get(
    injectCompanyToURL(API_PAID_THROUGH_DETAILS_URL, companyId).replace('{paid_through_id}', String(paidThroughId)),
    {
      params,
    }
  )
}

//* PAYMENTS
export function fetchPaymentOrders(companyId: number, params: Partial<PaymentOrdersStoreFilters>) {
  return Api.get<BackendPaginatedListResponse<BackendPaymentsResult>>(
    injectCompanyToURL(API_PAYMENT_LIST_URL, companyId),
    {
      params,
      paramsSerializer: {
        serialize(params) {
          return Qs.stringify(params)
        },
      },
    }
  )
}

export function initializePayment(companyId: number, data: PaymentSetupPayload) {
  return Api.post(injectCompanyToURL(API_SETUP_PAYMENT_URL, companyId), data)
}

export function fetchPayment(companyId: number, paymentId: number) {
  return Api.get(injectCompanyToURL(API_PAYMENT_DETAILS_URL, companyId).replace('{payment_id}', String(paymentId)))
}

export function createPayment(companyId: number, data: CreatePaymentPayload) {
  return Api.post(injectCompanyToURL(API_PAYMENT_CREATE_URL, companyId), data)
}

export function updatePayment(companyId: number, data: Omit<EditPaymentPayload, 'skipStoreUpdate'>) {
  return Api.patch(injectCompanyToURL(API_PAYMENT_UPDATE_URL, companyId).replace('{payment_id}', String(data.id)), data)
}

// EXPENSE payments from details
export function loadInvoiceTransactions(companyId: number, expenseId: number) {
  return Api.get(
    injectCompanyToURL(API_EXPENSE_PAYMENT_TRANSACTIONS_URL, companyId).replace('{expense_id}', String(expenseId))
  )
}

// PAYMENT paidThrough BALANCE

export function loadPaidThroughProviderBalance(companyId: number, paidThroughId: number) {
  return Api.get(
    injectCompanyToURL(API_PAID_THROUGH_BALANCE_URL, companyId).replace('{paid_through_id}', String(paidThroughId))
  )
}

//* BANK TRANSACTIONS
export function fetchBankTransactions(companyId: number, params: Partial<BankTransactionsStoreFilters>) {
  return Api.get<BackendPaginatedListResponse<BackendBankTransactionsResult>>(
    injectCompanyToURL(API_BANK_TRANSACTIONS_LIST_URL, companyId),
    {
      params,
      paramsSerializer: {
        serialize(v2Params) {
          return Qs.stringify(transformV2ParamsToV1(v2Params))
        },
      },
    }
  )
}

export function fetchDataByUrl(url: string) {
  return Api.get(url)
}

//* Bank Transactions background-action API
export function bankTransactionsBackgroundAction(companyId: number, payload: unknown) {
  return Api.post<BackgroundActionResponse>(injectCompanyToURL(API_BANK_TRANSACTIONS_ACTIONS_URL, companyId), payload)
}

//* PAYMENT TRANSACTIONS
export function fetchPaymentTransactions(companyId: number, params: Partial<PaymentTransactionsStoreFilters>) {
  return Api.get<BackendPaginatedListResponse<BackendPaymentTransactionsResult>>(
    injectCompanyToURL(API_PAYMENT_TRANSACTIONS_LIST_URL, companyId),
    {
      params,
      paramsSerializer: {
        serialize(params) {
          return Qs.stringify(params)
        },
      },
    }
  )
}

//* Bank Transactions background-action API
export function paymentTransactionsBackgroundAction(companyId: number, payload: unknown) {
  return Api.post<BackgroundActionResponse>(
    injectCompanyToURL(API_PAYMENT_TRANSACTIONS_ACTIONS_URL, companyId),
    payload
  )
}
