import React from 'react'
import PropTypes from 'prop-types'

import { FormattedDate, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getDefaultCurrencyName, StrongTextValues } from '@helpers'

import { PreviewProvider } from '@contexts'

import { PaymentExport } from '@components/PaymentExport'
import {
  Currency,
  CustomDialogActions,
  CustomDialogBody,
  CustomDialogHeader,
  ExternalLink,
  InfoText,
} from '@components/ui'
import { AsyncButton, Button } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'
import { FlexRow } from '@components/ui/Wrappers'

import { PAYMENT_FLOW } from '@constants'
import LINKS from '@constants/links'

import { useCalculateTotal } from './paymentHooks'
import { PaymentSummaryTable } from './PaymentSummaryTable'

import { MoreInformationMessage } from '@messages'
import { PaymentSummaryTitleMessage } from './messages'
import { PaymentDialogBodyWrapper } from './styles'

const StyledCustomDialogHeader = styled(CustomDialogHeader)`
  padding: 40px 0 22px;
  border: none;
`

const SummaryFlexRow = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  height: 44px;
  margin-top: 12px;
`

const FlexGroup = styled(FlexRow)`
  gap: 32px;
`

const StyledTypography = styled(Typography)`
  padding-right: 20px;
`

const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start; // prevent stretch
  gap: 12px;
  max-width: 800px;
  margin-top: 32px;

  //for the children to align with the InfoText Typography:
  & > *:not(:first-child) {
    padding-left: 34px;
  }
`

const StyledCustomDialogActions = styled(CustomDialogActions)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${Button} {
    width: 240px;
  }
`

// TODO consider to update global message
const CloseButtonMessage = <FormattedMessage id="payment.dialog.summary.close" defaultMessage="Bezárás" />

export interface PaymentSummaryViewProps {
  companyDefaultCurrencyName: string
  companyDefaultPaidThrough: Nullable<number>
  data: BackendPaymentsResult
  flow: PaymentFlowType
  onCallOfAttention: VoidFunction
  onClose: VoidFunction
  paidThroughOptions: BackendPaidThroughOption[]
}

export function PurePaymentSummaryView({
  companyDefaultCurrencyName,
  companyDefaultPaidThrough,
  data,
  flow,
  onCallOfAttention,
  onClose,
  paidThroughOptions,
}: PaymentSummaryViewProps) {
  const summaryPaidThroughName = paidThroughOptions.find(option => option.id === data.paidThroughId)?.name || ''
  const [totalValue, totalCurrency] = useCalculateTotal(data.transactions, companyDefaultCurrencyName)
  const companyHasNoDefaultPaidThroughRef = React.useRef(!companyDefaultPaidThrough && data.links.export)

  return (
    <>
      <StyledCustomDialogHeader title={PaymentSummaryTitleMessage} />
      <CustomDialogBody as={PaymentDialogBodyWrapper}>
        <PreviewProvider>
          <PaymentSummaryTable flow={flow} transactions={data.transactions} transactionType={data.transactionType} />
        </PreviewProvider>
        <SummaryFlexRow>
          <FlexGroup>
            <Typography color="gray-70" size="400-md">
              <FormattedMessage
                id="payment.dialog.paidThroughDate"
                defaultMessage="<b>Fizetési időpontja: </b>"
                values={{ ...StrongTextValues }}
              />
              <FormattedDate value={data.date} />
            </Typography>
            <Typography color="gray-70" size="400-md">
              <FormattedMessage
                id="payment.dialog.paidThrough"
                defaultMessage="<b>Pénztárca: </b>"
                values={{ ...StrongTextValues }}
              />
              {data.paidThroughId === companyDefaultPaidThrough ? (
                <FormattedMessage
                  id="payment.dialog.paidThrough.default"
                  defaultMessage="{name} (alapértelmezett)"
                  values={{ name: summaryPaidThroughName }}
                />
              ) : (
                summaryPaidThroughName
              )}
            </Typography>
          </FlexGroup>
          <StyledTypography size="heading-4">
            <FormattedMessage
              id="payment.dialog.sumTotal"
              defaultMessage="<b>Összesen: {value}</b>"
              values={{
                ...StrongTextValues,
                value: <Currency value={totalValue} currency={totalCurrency} />,
              }}
            />
          </StyledTypography>
        </SummaryFlexRow>
        {companyHasNoDefaultPaidThroughRef && (
          <InfoTextContainer>
            <InfoText iconColor="gray-40" size="400-sm">
              <FormattedMessage
                id="payment.dialog.summary.infoText.defaultWallet"
                defaultMessage="Az Utaláskönnyítő létrehozása során használt pénztárcádat elmentettük alapértelmezettnek. Ha meg szeretnéd változtatni, a cégprofilban (adminisztrátori jogosultsággal) megteheted."
              />
            </InfoText>
            <ExternalLink href={LINKS.defaultWallet} text={MoreInformationMessage} typographySize="400-sm" />
          </InfoTextContainer>
        )}
        {!data.links.export && (
          <InfoTextContainer>
            <InfoText iconColor="gray-40" size="400-sm">
              <FormattedMessage
                id="payment.dialog.summary.infoText.noExport"
                defaultMessage="Az Utaláskönnyítő létrehozása során olyan pénztárcát adtál meg, amihez nem tartoznak banki adatok, ezért nem tudtunk letölthető utalási fájlt generálni. A táblázat elemeire kattintva tudod kimásolni az értékek az utalásodhoz."
              />
            </InfoText>
            <ExternalLink href={LINKS.defaultWallet} text={MoreInformationMessage} typographySize="400-sm" />
          </InfoTextContainer>
        )}
      </CustomDialogBody>
      <StyledCustomDialogActions borderless>
        {data.links.export ? (
          <>
            <PaymentExport
              methodUrl={data.links.export}
              onDownloadSuccess={onClose}
              requireTransferTypeSelection={data.download.requireTransferTypeSelection}
              transferTypeOptions={data.download.transferTypes}
            >
              {({ downloading, onClick }) => (
                <AsyncButton loading={downloading} onClick={onClick} variant="primaryContained">
                  <FormattedMessage id="payment.dialog.summary.download" defaultMessage="Letöltés" />
                </AsyncButton>
              )}
            </PaymentExport>

            <Button onClick={onCallOfAttention} variant="primaryText">
              {CloseButtonMessage}
            </Button>
          </>
        ) : (
          <Button onClick={onCallOfAttention} variant="primaryContained">
            {CloseButtonMessage}
          </Button>
        )}
      </StyledCustomDialogActions>
    </>
  )
}

PurePaymentSummaryView.propTypes = {
  companyDefaultCurrencyName: PropTypes.string.isRequired,
  companyDefaultPaidThrough: PropTypes.number,
  data: PropTypes.object.isRequired as React.Validator<PaymentSummaryViewProps['data']>,
  flow: PropTypes.oneOf(Object.values(PAYMENT_FLOW)).isRequired,
  onCallOfAttention: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  paidThroughOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      provider: PropTypes.number,
      provider_name: PropTypes.string,
    }).isRequired
  ).isRequired as React.Validator<PaidThroughOption[]>,
}

export const PaymentSummaryView = connect((state: Store) => ({
  companyDefaultCurrencyName: getDefaultCurrencyName(state),
  companyDefaultPaidThrough: state.auth.company.data.default_paid_through,
  paidThroughOptions: state.payment.paidThroughOptions,
}))(PurePaymentSummaryView)

PaymentSummaryView.displayName = 'PaymentSummaryView'
