import React from 'react'

import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { taxNumberFormatter, transformSnakeCaseObjectToCamelCase } from '@helpers'

import { Currency } from '@components/ui'
import { QuickLogo } from '@oldComponents/ui'

import { BackendInvoicePreviewDataV1, GeneratedInvoicePreviewProps, InvoicePreviewData } from '../types'
import { CustomerInfo } from './CustomerInfo'
import { ProviderInfo } from './ProviderInfo'
import { formatVatPercent, getItemName } from './utils'

import detailsFormMessages from '@oldComponents/forms/DetailsForm/messages'
import messages from './messages'
import styles from './styles'

const useStyles = makeStyles(styles)

function transformToData(details: BackendInvoicePreviewDataV1) {
  return {
    ...transformSnakeCaseObjectToCamelCase(details),
    issuedAt:
      (details as ExpenseDetailsFrontendValues)?.created_at || (details as IncomeDetailsFrontendValues).issued_at,
  } as InvoicePreviewData
}

const PureGeneratedInvoicePreview = React.forwardRef<HTMLDivElement, GeneratedInvoicePreviewProps>(
  function InvoicePreview(
    {
      detailsV1, //  backwards compatibility
      details,
      paymentMethods,
      company,
      variant = 'expense',
    },
    ref
  ) {
    const classes = useStyles()
    const { formatMessage } = useIntl()

    const {
      currency,
      currencyName,
      dueAt,
      fulfilledAt,
      grossAmount,
      invoiceNumber,
      issuedAt,
      items = [], // NOTE it can be null
      partnerAddress,
      partnerCity,
      partnerName,
      partnerTaxNumber,
      partnerZipCode,
      paymentMethod,
    } = (details || transformToData(detailsV1)) as InvoicePreviewData

    const hasItems = !!items.length

    // do not show when no items
    const netTotal = items.reduce((acc, curr) => acc + parseFloat(curr.netAmount), 0)
    // use invoice grossAmount data when no items
    const grossTotal = hasItems
      ? items.reduce((acc, curr) => acc + parseFloat(curr.grossAmount), 0)
      : Number(grossAmount)

    // only display when filled
    const partnerDisplayedName = partnerName || ''
    const partnerCityWithZipCode = (partnerZipCode && partnerCity && `${partnerZipCode} ${partnerCity}`) || ''
    const partnerStreet = partnerAddress || ''
    const partnerTaxNo =
      (partnerTaxNumber && `${formatMessage(messages.taxNoLabel)}: ${taxNumberFormatter.format(partnerTaxNumber)}`) ||
      ''
    const companyTaxNo =
      (company.tax_account_number &&
        `${formatMessage(messages.taxNoLabel)}: ${taxNumberFormatter.format(company.tax_account_number)}`) ||
      ''

    return (
      <Grid container direction="column" alignItems="center" className={classes.root} ref={ref}>
        {/* HEADER SECTION START */}
        <Grid item container className={classes.headerContainer}>
          <Grid item>
            <QuickLogo width={88} height={45} />
          </Grid>
          <Grid item>
            {variant === 'expense' ? (
              <CustomerInfo
                className={classes.text1}
                partnerName={partnerDisplayedName}
                partnerCity={partnerCityWithZipCode}
                partnerStreet={partnerStreet}
                partnerTaxNo={partnerTaxNo}
              />
            ) : (
              <ProviderInfo
                className={classes.text1}
                companyName={company.name}
                companyCountry={company.country_name}
                companyTaxNo={companyTaxNo}
              />
            )}
          </Grid>
        </Grid>
        {/* HEADER SECTION END */}
        {/* INFO SECTION START */}
        <Grid item container wrap="nowrap" justifyContent="space-between" className={classes.infoContainer}>
          <Grid item xs={6}>
            {variant === 'expense' ? (
              <ProviderInfo
                className={classes.text1}
                companyName={company.name}
                companyCountry={company.country_name}
                companyTaxNo={companyTaxNo}
              />
            ) : (
              <CustomerInfo
                className={classes.text1}
                partnerName={partnerDisplayedName}
                partnerCity={partnerCityWithZipCode}
                partnerStreet={partnerStreet}
                partnerTaxNo={partnerTaxNo}
              />
            )}
          </Grid>
          <Grid item xs={6} container direction="column">
            <Grid item container justifyContent="space-between" component="p">
              <Typography variant="body1" className={classes.text1} component="span">
                {formatMessage(messages.paymentMethodLabel)}:
              </Typography>
              <Typography variant="body1" className={cx(classes.text1, 'right')} component="strong">
                {paymentMethods.find(method => method.value === paymentMethod)?.label}
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" component="p">
              <Typography variant="body1" className={classes.text1} component="span">
                {formatMessage(detailsFormMessages.invoiceNumberLabel)}:
              </Typography>
              <Typography variant="body1" className={cx(classes.text1, 'right')} component="strong">
                {invoiceNumber}
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" component="p">
              <Typography variant="body1" className={classes.text1} component="span">
                {formatMessage(detailsFormMessages.fulfilledDateLabel)}:
              </Typography>
              <Typography variant="body1" className={cx(classes.text1, 'right')} component="strong">
                {fulfilledAt}
              </Typography>
            </Grid>
            <Grid item container justifyContent="space-between" component="p">
              <Typography variant="body1" className={classes.text1} component="span">
                {formatMessage(detailsFormMessages.createdDateLabel)}:
              </Typography>
              <Typography variant="body1" className={cx(classes.text1, 'right')} component="strong">
                {issuedAt}
              </Typography>
            </Grid>
            <Grid>
              <Grid item container justifyContent="space-between" className={classes.dueAtContainer} component="p">
                <Typography variant="body1" className={classes.text1} component="strong">
                  {formatMessage(detailsFormMessages.deadlineDateLabel)}:
                </Typography>
                <Typography variant="body1" className={cx(classes.text1, 'right')} component="strong">
                  {dueAt}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* INFO SECTION END */}
        {/* DETAILS SECTION START */}
        <Grid item className={classes.detailsContainer}>
          <Table aria-label="spanning table" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="invoice.nav.preview.name" defaultMessage="Megnevezés" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="invoice.nav.preview.quantity" defaultMessage="Menny." />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="invoice.nav.preview.unitPrice" defaultMessage="Egységár" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="invoice.nav.preview.netAmount" defaultMessage="Nettó ár" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="invoice.nav.preview.vat" defaultMessage="ÁFA" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="invoice.nav.preview.vatAmount" defaultMessage="ÁFA érték" />
                </TableCell>
                <TableCell align="right">
                  <FormattedMessage id="invoice.nav.preview.grossAmount" defaultMessage="Bruttó ár" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((row, idx) => (
                <TableRow key={row.id} className={classes.tableRow}>
                  <TableCell>{getItemName(row.name, idx)}</TableCell>
                  <TableCell align="right">
                    {row.quantity && row.amountUnit ? `${row.quantity} ${row.amountUnit}` : ''}
                  </TableCell>
                  <TableCell align="right">
                    <Currency value={Number(row.netUnitPrice)} currencyId={currency} currency={currencyName} />
                  </TableCell>
                  <TableCell align="right">
                    <Currency value={Number(row.netAmount)} currencyId={currency} currency={currencyName} />
                  </TableCell>
                  <TableCell align="right">{formatVatPercent(row.vatPercent)}</TableCell>
                  <TableCell align="right">
                    <Currency value={Number(row.vatAmount)} currencyId={currency} currency={currencyName} />
                  </TableCell>
                  <TableCell align="right">
                    <Currency value={Number(row.grossAmount)} currencyId={currency} currency={currencyName} />
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className={classes.tableSum}>
                <TableCell>
                  <FormattedMessage id="invoice.nav.preview.total" defaultMessage="Összesen" />
                </TableCell>
                <TableCell align="right" colSpan={3}>
                  <Currency value={netTotal} currencyId={currency} currency={currencyName} />
                </TableCell>
                <TableCell align="right" colSpan={3}>
                  <Currency value={grossTotal} currencyId={currency} currency={currencyName} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        {/* DETAILS SECTION END */}
        {/* WATERMARK START */}
        <Typography className={classes.watermark}>
          <FormattedMessage id="nav.preview.watermark" defaultMessage="Generált számlakép" />
        </Typography>
        {/* WATERMARK END */}
      </Grid>
    )
  }
)

export const GeneratedInvoicePreview = connect(
  (state: Store) => ({
    company: state.auth.company.data,
    paymentMethods: state.dashboard.common.payment_methods,
  }),
  null,
  null,
  { forwardRef: true }
)(PureGeneratedInvoicePreview)

GeneratedInvoicePreview.displayName = 'GeneratedInvoicePreview'
