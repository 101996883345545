import React from 'react'
import PropTypes from 'prop-types'

import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as yup from 'yup'

import {
  ReactHookForm,
  ReactHookFormCheckboxField,
  ReactHookFormSubmitButton,
  ReactHookFormTextField,
} from '@components/ui'

import { LINKS, ROUTES } from '@constants'

import { formErrorMessages, globalMessages } from '@messages'
import messages from '@components/forms/messages'

export const signupFormMessages = defineMessages({
  eula: {
    id: 'form.label.eula',
    defaultMessage: 'Adatvédelmi tájékoztató',
  },
  terms: {
    id: 'form.label.terms',
    defaultMessage: 'Általános Szerződési Feltételek',
  },
  signupButtonText: {
    id: 'signup.buttonText',
    defaultMessage: 'Regisztrálok',
  },
})

const StyledLink = styled.a`
  color: inherit;
  font-weight: 500;
`

const SignupButton = styled(ReactHookFormSubmitButton)`
  margin-top: 15px;
  height: 50px;
`

interface SignupFormValues {
  email: string
  password: string
  confirm_password?: string
  eula: boolean
  terms: boolean
  newsletter_status?: boolean
  remember_me?: boolean
  hash?: string
}

function useValidationSchema(): yup.SchemaOf<SignupFormValues> {
  const { formatMessage } = useIntl()

  return yup.object({
    email: yup
      .string()
      .email(formatMessage(formErrorMessages.email))
      .required(formatMessage(formErrorMessages.required)),
    password: yup.string().required(formatMessage(formErrorMessages.required)),
    confirm_password: yup.string().oneOf(
      [yup.ref('password'), null],
      formatMessage(formErrorMessages.confirmation, {
        fieldLabel: formatMessage(globalMessages.formLabelPassword),
      })
    ),
    eula: yup
      .boolean()
      .oneOf([true], formatMessage(formErrorMessages.eulaAcceptance))
      .required(formatMessage(formErrorMessages.eulaAcceptance)),
    terms: yup
      .boolean()
      .oneOf([true], formatMessage(formErrorMessages.termsAcceptance))
      .required(formatMessage(formErrorMessages.termsAcceptance)),
    newsletter_status: yup.boolean(),
    remember_me: yup.boolean(),
    hash: yup.string(),
  })
}

export interface SignupFormProps {
  email?: string
  hash?: string
  onSubmit: AsyncFunction<SignupFormValues, unknown>
}

export function SignupForm({ email, hash, onSubmit }: SignupFormProps) {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const validationSchema = useValidationSchema()

  const INITIAL_VALUES = {
    email: email ?? '',
    password: '',
    confirm_password: '',
    hash: hash,
  }

  function onSubmitSuccess() {
    navigate(ROUTES.root)
  }

  return (
    <ReactHookForm
      autoComplete="off"
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      skipUnsavedChanges
      validationSchema={validationSchema}
    >
      <ReactHookFormTextField
        name="email"
        label={formatMessage(globalMessages.formLabelEmail)}
        disabled={Boolean(email)}
      />
      <ReactHookFormTextField name="password" label={formatMessage(globalMessages.formLabelPassword)} type="password" />
      <ReactHookFormTextField
        name="confirm_password"
        label={formatMessage(messages.formLabelConfirmPassword)}
        type="password"
      />
      <ReactHookFormCheckboxField
        name="eula"
        label={
          <FormattedMessage
            id="form.label.eulaText"
            defaultMessage="Elfogadom az {link}-ban írtakat"
            values={{
              link: (
                <StyledLink href={LINKS.eula} target="_blank" rel="noopener noreferrer">
                  {formatMessage(signupFormMessages.eula)}
                </StyledLink>
              ),
            }}
          />
        }
        data-testid="checkbox-eula"
      />
      <ReactHookFormCheckboxField
        name="terms"
        label={
          <FormattedMessage
            id="form.label.termsText"
            defaultMessage="Elfogadom az {link}-et"
            values={{
              link: (
                <StyledLink href={LINKS.terms} target="_blank" rel="noopener noreferrer">
                  {formatMessage(signupFormMessages.terms)}
                </StyledLink>
              ),
            }}
          />
        }
        data-testid="checkbox-terms"
      />
      <ReactHookFormCheckboxField
        name="newsletter_status"
        label={formatMessage(globalMessages.formLabelNewsletterText)}
        data-testid="checkbox-newsletter"
      />
      {!hash && ( //if hash is present, it means it's an InviteRegistration instance
        <ReactHookFormCheckboxField name="remember_me" label={formatMessage(globalMessages.formLabelRememberMe)} />
      )}

      <SignupButton buttonText={formatMessage(signupFormMessages.signupButtonText)} fullWidth size="large" />
    </ReactHookForm>
  )
}

SignupForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
