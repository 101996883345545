import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const StyledCheckbox = styled(Checkbox)`
  height: 34px;
  width: 34px;
  transform: translate(0, -4px);
  margin: -4px 8px 0 4px;

  &.MuiCheckbox-colorPrimary.Mui-disabled {
    opacity: 0.3;
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
  padding-top: 6px;
  margin-bottom: 5px;
`

interface CheckboxProps {
  name: string
  label: StringOrMessage
  disabled?: boolean
}

export function ReactHookFormCheckboxField({ name, label, disabled = false }: CheckboxProps) {
  const {
    control,
    formState: { errors, isSubmitting },
  } = useFormContext()

  const hasError = !!errors[name]
  const isFieldDisabled = disabled || isSubmitting

  const inputId = `${name}-checkbox`

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl error={hasError} fullWidth>
          <StyledFormControlLabel
            control={
              <StyledCheckbox
                checked={field.value}
                color="primary"
                disabled={isFieldDisabled}
                id={inputId}
                {...field}
              />
            }
            htmlFor={inputId}
            label={label}
          />
          {hasError && <FormHelperText>{errors[name]?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

ReactHookFormCheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
}
