import React from 'react'

import { Typography } from '@material-ui/core'
import { useField } from 'react-final-form'
import { defineMessage, FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { required } from 'redux-form-validators'
import styled from 'styled-components'

import { applyIf } from '@helpers'
import { readFile } from '@oldComponents/pages/DashboardIncomePage/helpers'

import { UploadPreview } from '@components/DocViewer'
import { FileUploadForm } from '@oldComponents/forms/FileUploadForm'

import { A4_PAPER_SIZE } from '@constants'

import uploadSVGSrc from '@assets/img/scan_upload_folder.svg'

import { AttachmentUploadFormValues, PreviewFile } from '../types'
import { AttachmentType } from './AttachementTypeSelector'

const RequiredFileMesssage = defineMessage({
  id: 'form.errors.requiredFile',
  defaultMessage: 'A fájl kiválasztása kötelező',
})

const UploadContainerDiv = styled.div`
  padding: 0;
  width: 100%;
`

interface AttachmentFileFieldProps {
  acceptedFileMimeTypes: string[]
  maxSize: number
}

function PureAttachmentFileField({ acceptedFileMimeTypes, maxSize }: AttachmentFileFieldProps) {
  const { formatMessage } = useIntl()
  const [uploadedPreviewFile, setUploadedPreviewFile] = React.useState<Nullable<PreviewFile>>(null)
  const {
    input: { value, onChange },
    meta: { error, submitError, dirtySinceLastSubmit, touched },
  } = useField('file', {
    validate: applyIf<string>(
      (value, allValues) => (allValues as AttachmentUploadFormValues).type === AttachmentType.FILE
    )(
      required({
        message: formatMessage(RequiredFileMesssage),
      })
    ),
  })

  const handleClear = React.useCallback(() => {
    setUploadedPreviewFile(null)
    onChange(null)
  }, [onChange])

  // clear preview file if value is null
  React.useEffect(() => {
    if (!value) {
      setUploadedPreviewFile(null)
    }
  }, [value])

  const filePreview = React.useMemo(() => {
    if (uploadedPreviewFile) {
      return (
        <UploadPreview
          file={uploadedPreviewFile.file}
          fileUrl={uploadedPreviewFile.url}
          maxHeight={Math.round((A4_PAPER_SIZE.height * 2) / 3)}
          onClear={handleClear}
          withPaper
        />
      )
    }

    return null
  }, [handleClear, uploadedPreviewFile])

  // preview file upload
  function handleFileUpload(uploadedFile: PreviewFile) {
    return readFile(uploadedFile.file)
  }

  function handleFileUploadSuccess(uploadedFile: PreviewFile) {
    setUploadedPreviewFile(uploadedFile)
    onChange(uploadedFile.file)
  }

  return (
    <>
      <div>
        {filePreview || (
          <UploadContainerDiv>
            <FileUploadForm
              acceptedFileMimeTypes={acceptedFileMimeTypes}
              fileUploadText={
                <FormattedMessage
                  id="fileUpload.texts.upload"
                  defaultMessage="Húzd ide a fájlt, vagy válaszd ki manuálisan."
                />
              }
              getFileUploadPayload={acceptedFiles => ({
                file: acceptedFiles[0],
              })}
              maxSize={maxSize}
              onUploadSuccess={handleFileUploadSuccess as any}
              uploadFile={handleFileUpload as any}
              uploadImgSrc={uploadSVGSrc}
              uploading={false}
              withDialog
            />
          </UploadContainerDiv>
        )}
      </div>
      {((touched && error) || (!dirtySinceLastSubmit && submitError)) && (
        <Typography color="error" align="left" variant="caption" component="div" style={{ marginTop: '1rem' }}>
          {error || submitError}
        </Typography>
      )}
    </>
  )
}

export const AttachmentFileField = connect((state: Store) => ({
  acceptedFileMimeTypes: state.dashboard.common.allowed_document_types,
  maxSize: state.dashboard.common.document_max_size,
}))(PureAttachmentFileField)
