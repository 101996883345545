import React from 'react'
import PropTypes from 'prop-types'

import { Field, FormRenderProps, useField, useFormState } from 'react-final-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { accountNumberFormatter, isAdvancedAccountingAvailable } from '@helpers'

import { ChangeFieldListener } from '@components/forms'
import { Button, FirstColumn, FormError, FormGrid2Column, RffSubmitButton, SecondColumn } from '@components/ui'
import { PaidThroughLedgerNumberSelect } from '@oldComponents/forms/DetailsForm/elements'
import { RenderFormFieldSelect, RenderTextField } from '@oldComponents/ui/form'

import { COMPANY_USER_PERMISSIONS, FIELD_SUBSCRIPTION, PaidThroughType } from '@constants'

import { FeaturePermissons, isPlanPermissionEnabled, permissionDeniedForUser } from '@permissions'

import { EditorFormBalanceSection } from './elements'
import { currencyOnChangeHandler, paidthroughTypeOnChangeHandler } from './helpers'
import { PaidThroughFormValues, PurePaidThroughEditorFormProps } from './types'

import { CancelButtonMessage, formSelectMessages, globalMessages } from '@messages'
import messages from './messages'
import { CollapseGrid2ColumnWithNoPaddingTop, FormDialogActions, FormSection } from './styles'

/**
 * Form body component for editing or creating a paid through.
 *
 * @param {PurePaidThroughEditorFormProps} {
 *   currencies,
 *   defaultCurrencyId,
 *   handleSubmit,
 *   form: { change },
 *   bankProviders,
 *   isEdit,
 *   onClose,
 *   detailsData,
 * }
 * @returns
 */
export function PurePaidThroughEditorForm({
  aria,
  bankProviders,
  currencies,
  detailsData,
  form: { change },
  handleSubmit,
  isAdvancedAccountingAvailableForUser,
  isBankTransactionsAllowed,
  isEdit,
  onClose,
}: PurePaidThroughEditorFormProps) {
  const { submitting } = useFormState({
    subscription: {
      submitting: true,
    },
  })

  const {
    input: { value: providerValue },
    meta: { initial: providerInitialValue },
  } = useField('provider', {
    subscription: {
      value: true,
      initial: true,
    },
  })
  const {
    input: { value: paidThroughTypeValue },
  } = useField<PaidThroughType>('paidthrough_type')

  const provider = Number(providerValue)
  const initialProvider = Number(providerInitialValue)

  // providerChangeListener
  React.useEffect(() => {
    if (provider !== initialProvider || !provider) {
      change('is_autokassza', false)
    }

    if (!provider) {
      change('account_number', '')
    }
  }, [change, initialProvider, provider])

  const { formatMessage } = useIntl()

  const paidThroughTypeOptions = [
    { id: PaidThroughType.CASH, name: formatMessage(messages.cash) },
    { id: PaidThroughType.BANK_ACCOUNT, name: formatMessage(messages.bankAccount) },
  ]

  const getAccountNumberFormatter = (provider: number | string) => (value: string) => {
    // 2 - Magnet
    return provider === 2 ? accountNumberFormatter.format(value) : value
  }

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      noValidate
      id={aria.describedby}
      data-testid="paid-through-editor-form"
    >
      <FormSection as={FormGrid2Column}>
        <FirstColumn>
          <Field
            component={RenderFormFieldSelect}
            disabled={submitting}
            isClearable={false}
            label={formatMessage(messages.paidThroughTypeLabel)}
            labelKey="name"
            name="paidthrough_type"
            noResultsText={formatMessage(formSelectMessages.selectNoResultsText)}
            options={paidThroughTypeOptions}
            placeholder={formatMessage(formSelectMessages.selectPlaceholder)}
            required
            subscription={FIELD_SUBSCRIPTION}
            valueKey="id"
          />
        </FirstColumn>
        <ChangeFieldListener
          name="paidthrough_type"
          handler={paidthroughTypeOnChangeHandler}
          currencies={currencies}
          pettyCashMessage={formatMessage(globalMessages.pettyCash)}
        />
        <SecondColumn>
          <Field
            subscription={FIELD_SUBSCRIPTION}
            component={RenderFormFieldSelect}
            options={currencies}
            label={formatMessage(messages.currencyLabel)}
            name="currency"
            valueKey="id"
            labelKey="name"
            disabled={submitting}
            placeholder={formatMessage(formSelectMessages.selectPlaceholder)}
            noResultsText={formatMessage(formSelectMessages.selectNoResultsText)}
            required
            isClearable={false}
          />
          <ChangeFieldListener
            name="currency"
            handler={currencyOnChangeHandler}
            currencies={currencies}
            pettyCashMessage={formatMessage(globalMessages.pettyCash)}
          />
        </SecondColumn>
        <FirstColumn>
          <Field
            component={RenderTextField}
            disabled={submitting}
            label={formatMessage(messages.paidThroughNameLabel)}
            name="name"
            subscription={FIELD_SUBSCRIPTION}
            required
          />
        </FirstColumn>
        {isAdvancedAccountingAvailableForUser && (
          <SecondColumn>
            <Field
              component={RenderFormFieldSelect}
              disabled={submitting}
              label={formatMessage(messages.paidThroughLedgerNumberLabel)}
              name="ledger_number"
              selectComponent={PaidThroughLedgerNumberSelect}
              subscription={FIELD_SUBSCRIPTION}
            />
          </SecondColumn>
        )}
        <CollapseGrid2ColumnWithNoPaddingTop open={paidThroughTypeValue === PaidThroughType.BANK_ACCOUNT}>
          <FirstColumn>
            <Field
              subscription={FIELD_SUBSCRIPTION}
              component={RenderFormFieldSelect}
              options={bankProviders}
              label={formatMessage(messages.providerLabel)}
              name="provider"
              valueKey="id"
              labelKey="name"
              disabled={submitting}
              placeholder={formatMessage(messages.providerPlaceholder)}
              noResultsText={formatMessage(formSelectMessages.selectNoResultsText)}
              isClearable
            />
          </FirstColumn>
          <SecondColumn>
            <Field
              subscription={FIELD_SUBSCRIPTION}
              required={Boolean(provider)}
              component={RenderTextField}
              name="account_number"
              label={formatMessage(messages.bankAccountNumberLabel)}
              disabled={submitting || !provider}
              format={getAccountNumberFormatter(provider)}
              parse={accountNumberFormatter.parse}
            />
          </SecondColumn>
        </CollapseGrid2ColumnWithNoPaddingTop>
      </FormSection>
      {isBankTransactionsAllowed && (
        <EditorFormBalanceSection
          data-testid="editor-form-balance-section"
          detailsData={detailsData}
          isEdit={isEdit}
          provider={provider}
        />
      )}
      <FormDialogActions>
        <FormError />
        <div>
          <Button onClick={onClose} variant="secondaryContained" type="button">
            {CancelButtonMessage}
          </Button>
          <RffSubmitButton variant="primaryContained">
            {isEdit ? formatMessage(globalMessages.saveButtonText) : formatMessage(globalMessages.addButtonText)}
          </RffSubmitButton>
        </div>
      </FormDialogActions>
    </form>
  )
}

PurePaidThroughEditorForm.propTypes = {
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  defaultCurrencyId: PropTypes.number.isRequired,
  detailsData: PropTypes.shape({
    balances: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.string.isRequired,
        value_date: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }) as React.Validator<PaidThroughDetailData>,
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
  }).isRequired as React.Validator<FormRenderProps<PaidThroughFormValues>['form']>,
  handleSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bankProviders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      autokassza_available: PropTypes.bool.isRequired,
    }).isRequired
  ).isRequired,
  isAdvancedAccountingAvailableForUser: PropTypes.bool.isRequired,
  isBankTransactionsAllowed: PropTypes.bool.isRequired,
}

const PaidThroughEditorForm = connect((state: Store) => ({
  currencies: state.dashboard.common.currencies,
  defaultCurrencyId: state.auth.company.data.default_currency,
  bankProviders: state.dashboard.common.bank_providers,
  isAdvancedAccountingAvailableForUser: isAdvancedAccountingAvailable(state),
  isBankTransactionsAllowed:
    isPlanPermissionEnabled(state, FeaturePermissons.BANK_TRANSACTIONS) &&
    !permissionDeniedForUser(state, COMPANY_USER_PERMISSIONS.bankTransactions),
}))(PurePaidThroughEditorForm)

PaidThroughEditorForm.displayName = 'PaidThroughEditorForm'

export default PaidThroughEditorForm
