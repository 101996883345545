import React from 'react'
import PropTypes from 'prop-types'

import { TextFieldProps } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { ReactHookFormTextFieldProps } from './ReactHookFormTextField'
import { TextFieldBehavior } from './TextFieldBehavior'

//* local helper to format date string
function formatDate(value: string) {
  if (!value) return value
  const numericStringValue = value.replace(/[^\d]/g, '')
  if (numericStringValue.length < 5) {
    return numericStringValue
  }
  if (numericStringValue.length < 7) {
    return `${numericStringValue.substring(0, 4)}-${numericStringValue.substring(4)}`
  }
  return `${numericStringValue.substring(0, 4)}-${numericStringValue.substring(4, 6)}-${numericStringValue.substring(
    6,
    8
  )}`
}

interface ReactHookFormDateFieldProps extends ReactHookFormTextFieldProps, Pick<TextFieldProps, 'onFocus' | 'onBlur'> {}

export function ReactHookFormDateField({ name, disabled, ...rest }: ReactHookFormDateFieldProps) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const isFieldDisabled = disabled || isSubmitting
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => {
        return (
          <NumberFormat
            {...rest}
            disabled={isFieldDisabled}
            error={!!errors[name]}
            helperText={errors[name]?.message ?? ''}
            name={name}
            customInput={TextFieldBehavior}
            autoComplete="off"
            format={formatDate}
            value={value ?? ''}
            type="tel"
            isNumericString
            onChange={onChange}
            allowEmptyFormatting
          />
        )
      }}
    />
  )
}

ReactHookFormDateField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}
