import React from 'react'

import { FormattedMessage } from 'react-intl'

import { InformationalMessage } from '@components/ui'

export function PaymentInformationalMessage() {
  return (
    <InformationalMessage iconSize={16} typographySize="700-xs">
      <FormattedMessage
        id="paymentEditor.infoText"
        defaultMessage="A kiválasztott költségek közül egy vagy több nem tartalmaz fizetendő összeget, hiányosan van kitöltve, NAV-os összerendelésre vár, vagy duplikáció így nem került bele az utalási csomagba!"
      />
    </InformationalMessage>
  )
}
