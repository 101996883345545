import React from 'react'

import { Field, FieldProps, FieldRenderProps, useField, useForm } from 'react-final-form'

import { USE_FIELD_VALUE_CONFIG } from '@constants'

type ConditionalDeliveredAtFieldProps = FieldProps<string, FieldRenderProps<string>>

export function ConditionalDeliveredAtField(props: ConditionalDeliveredAtFieldProps) {
  const {
    input: { value: isReverseTaxSubject },
  } = useField<boolean>('reverse_tax_subject', USE_FIELD_VALUE_CONFIG)
  const { change } = useForm()

  React.useEffect(() => {
    // clear delivered_at field value when isReverseTaxSubject is False
    if (!isReverseTaxSubject) {
      change('delivered_at', null)
    }
  }, [change, isReverseTaxSubject])

  return isReverseTaxSubject ? <Field {...props} /> : null
}
