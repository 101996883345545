import __cloneDeep from 'lodash/cloneDeep'
import __find from 'lodash/find'

import { calculateNetAndVatAmount, getDecimal, getMaximumFractionDigitsByCurrency, roundToDecimal } from '@helpers'

import { GetAssignmentFieldsProps, InvoiceAssignmentsFields } from './types'

/**
 * Calculate amounts for the new assignment based on the last assignment's VAT
 * @param {GetAssignmentFieldsProps} param - The parameters for calculating the assignment fields.
 * @returns {AssignmentFields} - The calculated assignment fields.
 */
export function getAssignmentFields({ currency, fields, grossTotal, vatOptions }: GetAssignmentFieldsProps) {
  const assignments = fields.value
  const maximumFractionDigits = getMaximumFractionDigitsByCurrency(currency)
  const assignmentsGrossAmountTotal = assignments.reduce((acc, curr) => (acc += Number(curr.gross_amount) || 0), 0)
  const numericGrossTotal = Number(grossTotal) || 0
  const remainingGrossAmount = roundToDecimal(numericGrossTotal - assignmentsGrossAmountTotal, {
    maximumFractionDigits,
    numeric: true,
  })

  const grossAmountValue = roundToDecimal(Math.max(remainingGrossAmount, 0), {
    maximumFractionDigits,
    numeric: false,
  }) // >= 0

  // get last assignment's vat
  const lastAssignmentVat = assignments[assignments.length - 1]?.vat ?? null

  if (!lastAssignmentVat) {
    // when no vat is selected, return gross amount only because we can't calculate net and vat amounts
    return {
      gross_amount: grossAmountValue,
      net_amount: '',
      vat_amount: '',
      vat: null,
    }
  }

  const percent = __find(vatOptions, ['id', lastAssignmentVat])?.percent ?? null
  const amounts = calculateNetAndVatAmount(remainingGrossAmount, percent, currency)

  return {
    ...amounts,
    gross_amount: grossAmountValue,
    vat: lastAssignmentVat,
  }
}

/**
 * Copies an assignment and updates the necessary fields.
 *
 * @param {InvoiceAssignmentsFields} fields - The invoice assignment fields.
 * @param {number} index - The index of the assignment to copy.
 * @param {string} grossTotal - The total gross amount.
 * @param {CompanyVatType[]} vatOptions - The VAT options.
 * @param {number} currency - The currency.
 * @returns {React.MouseEventHandler<HTMLButtonElement>} - The event handler for the copy button.
 */
export function copyAssignment(
  fields: InvoiceAssignmentsFields,
  index: number,
  grossTotal: string,
  vatOptions: CompanyVatType[],
  currency: number
): React.MouseEventHandler<HTMLButtonElement> {
  return function handler() {
    const fieldToCopy = fields.value[index]

    if (fieldToCopy) {
      // Clone the current field's value as ExpenseDetailsAssignment because of field handling
      const clonedField = __cloneDeep(fieldToCopy) as ExpenseDetailsAssignment

      // Remove the id and note from the cloned field
      clonedField.id = undefined
      clonedField.note = null

      // Calculate the total gross_amount for all fields
      const totalGrossAmount = fields.value.reduce((total, field) => total + (Number(field.gross_amount) || 0), 0)

      // Calculate the remainder
      const remainingGrossAmount = Number(grossTotal) - totalGrossAmount

      // Assign the remainder to the cloned field's gross_amount, or 0 if remainder is less than 0
      clonedField.gross_amount = getDecimal(remainingGrossAmount > 0 ? remainingGrossAmount : 0)

      // Calculate new net and vat amounts
      const vatPercent = vatOptions.find(option => option.id === clonedField.vat)?.percent
      const { net_amount, vat_amount } = calculateNetAndVatAmount(clonedField.gross_amount, vatPercent, currency)
      clonedField.net_amount = net_amount
      clonedField.vat_amount = vat_amount

      // Add the cloned field to the fields array
      fields.push(clonedField)
    }
  }
}

export function isInvoicePartnerDetailsFilled(data: InvoiceDetailsFormInitialValues): boolean {
  if (!data.partner_id) {
    return false
  }

  const fields: Array<keyof InvoiceDetailsFormInitialValues> = [
    'partner_account_number',
    'partner_address',
    'partner_city',
    'partner_country',
    'partner_name',
    'partner_tax_number',
    'partner_zip_code',
  ]

  return fields.some(field => Boolean(data[field]))
}
