import React from 'react'
import { bool, func, oneOf, shape, string } from 'prop-types'

import { IconButton, InputAdornment } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'

import { LightTooltip } from '@oldComponents/ui'

const size = 8
const Dot = withStyles(theme => ({
  root: {
    display: 'block',
    width: size,
    height: size,
    borderRadius: '50%',
    backgroundColor: theme.palette.grayscale.xLight,

    '&:hover': {
      backgroundColor: theme.palette.grayscale.light,
    },

    '&$disabled': {
      '&, &:hover': {
        backgroundColor: theme.palette.grayscale.xxLight,
      },
    },
  },
  active: {
    '&, &$disabled': {
      backgroundColor: theme.palette.primary.main,
    },

    '&:hover': {
      backgroundColor: theme.palette.primary.mDark,
    },
  },
  disabled: {},
}))(({ active, disabled, classes }) => (
  <span
    className={cx(classes.root, {
      [classes.active]: active,
      [classes.disabled]: disabled,
    })}
  />
))

function CalculationInputAdornment({ classes, name, current, toggle, title, toggleDisabled }) {
  const handleClick = () => {
    toggle(name)
  }

  return (
    <InputAdornment position="end" className={classes.root}>
      <LightTooltip title={title}>
        <div>
          <IconButton aria-label="toggle calculation base" onClick={handleClick} disabled={toggleDisabled}>
            <Dot active={current === name} disabled={toggleDisabled} />
          </IconButton>
        </div>
      </LightTooltip>
    </InputAdornment>
  )
}

CalculationInputAdornment.propTypes = {
  name: oneOf(['gross_amount', 'net_amount']).isRequired,
  current: oneOf(['gross_amount', 'net_amount']).isRequired,
  toggle: func.isRequired,
  title: string.isRequired,
  toggleDisabled: bool.isRequired,
  classes: shape({}),
}

export default withStyles(() => ({
  root: {
    position: 'absolute',
    right: 7,
  },
}))(CalculationInputAdornment)
