import React from 'react'

export enum EditorMode {
  CREATE = 'create',
  EDIT = 'edit',
}

export interface EditorStateProps<EditorDetailsPayload> {
  payload: Nullable<EditorDetailsPayload>
  mode: EditorMode
  open: boolean
}

export function useEditor<EditorDetailsPayload>() {
  const [state, setState] = React.useState<EditorStateProps<EditorDetailsPayload>>({
    open: false,
    mode: EditorMode.CREATE,
    payload: null,
  })

  function openEditorForCreate() {
    setState({ open: true, mode: EditorMode.CREATE, payload: null })
  }

  function openEditorForEdit(payload: EditorDetailsPayload) {
    setState({ open: true, mode: EditorMode.EDIT, payload })
  }

  function closeEditor() {
    setState(state => ({ ...state, open: false }))
  }

  return {
    editorState: state,
    openEditorForCreate,
    openEditorForEdit,
    closeEditor,
  }
}
