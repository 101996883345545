import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Paper, Typography } from '@material-ui/core'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import { cancellablePromise, parseApiErrorMessage, zeroFileSizeValidator } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { UploadedFileError } from '@components/UploadedFiles'
import FileDropzone, {
  FileDropzoneFontSizes,
  useFileDropzone,
  UseFileDropzoneOnDropHandler,
  UseFileDropzoneUploadHandler,
} from '@oldComponents/FileDropzone'

import { FileUploadFormProps } from './types'

import { MultipleFileUploadErrorMessage } from './messages'
import { useStyles } from './styles'

export function FileUploadForm({
  acceptedFileMimeTypes,
  fileUploadText,
  getFileUploadPayload,
  maxSize = 0,
  onUploadSuccess,
  showServerError = false,
  title,
  uploadDescriptionText,
  uploadFile,
  uploadImgSrc,
  uploading,
  uploadSuggestionText,
  withDialog = false,
  withPaper = false,
}: FileUploadFormProps) {
  const classes = useStyles()
  const cPromiseRef = useCancellablePromiseRef()

  const uploadHandler: UseFileDropzoneUploadHandler = React.useCallback(
    async (state, setState, fileUploadPayload) => {
      try {
        cPromiseRef.current = cancellablePromise(uploadFile(fileUploadPayload))
        // upload
        const results = await cPromiseRef.current.promise

        return onUploadSuccess(results)
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          const error = (
            <>
              <FormattedMessage id="fileUpload.errors.uploadFailed" defaultMessage="A fájl feltöltése nem sikerült" />
              {showServerError && <div>{errorMsg}</div>}
            </>
          )
          // because onDropHandler calls this function we need to set error here explicitly and return it so the hook will not think it is a success
          setState(state => ({ ...state, error }))
          return error
        }
      }
    },
    [cPromiseRef, onUploadSuccess, showServerError, uploadFile]
  )

  const onDropHandler: UseFileDropzoneOnDropHandler = React.useCallback(
    (acceptedFiles, rejectedFiles, state, setState) => {
      setState(prevState => ({ ...prevState, error: null }))
      // when dropzone is not having multiple prop it will automatically reject multiple files
      if (rejectedFiles.length > 1) {
        // multiple file upload, not supported
        setState(state => ({
          ...state,
          errorTimeout: 4000,
          error: MultipleFileUploadErrorMessage,
        }))
      } else if (rejectedFiles.length > 0) {
        setState(state => ({
          ...state,
          errorTimeout: 2000,
          error: <UploadedFileError errors={rejectedFiles[0].errors} />,
        }))
      } else {
        const fileUploadPayload = getFileUploadPayload(acceptedFiles)

        uploadHandler(state, setState, fileUploadPayload)
      }
    },
    [getFileUploadPayload, uploadHandler]
  )

  const { error, handleOnDrop } = useFileDropzone({
    uploadHandler,
    onDropHandler,
  })

  function renderWithWrapper(content: JSX.Element) {
    if (withPaper) {
      return <Paper className={classes.paper}>{content}</Paper>
    }
    return content
  }

  return renderWithWrapper(
    <Grid
      container
      justifyContent="center"
      alignItems="flex-start"
      className={cx(classes.root, { [classes.rootPaper]: withPaper })}
    >
      <Grid item xs={12} className={classes.item}>
        {title && <Typography variant="h3">{title}</Typography>}

        {uploadDescriptionText && (
          <div className={cx({ [classes.xMarginTopRow]: withPaper })}>
            <Typography variant="body1" className={classes.uploadDescription}>
              {uploadDescriptionText}
            </Typography>
          </div>
        )}

        <FileDropzone
          acceptedFileMimeTypes={acceptedFileMimeTypes}
          className={cx(classes.dropzone, { [classes.dropzoneDialog]: withDialog })}
          disabled={uploading}
          error={error}
          fileUploadText={fileUploadText}
          fontSize={FileDropzoneFontSizes.BIG}
          onDrop={handleOnDrop}
          uploadImgSrc={uploadImgSrc}
          uploading={uploading}
          uploadSuggestionText={uploadSuggestionText}
          validator={zeroFileSizeValidator}
          {...(maxSize ? { maxSize } : {})}
        />
      </Grid>
    </Grid>
  )
}

FileUploadForm.propTypes = {
  acceptedFileMimeTypes: PropTypes.arrayOf(PropTypes.string.isRequired),
  fileUploadText: PropTypes.node.isRequired,
  getFileUploadPayload: PropTypes.func.isRequired,
  maxSize: PropTypes.number,
  onUploadSuccess: PropTypes.func.isRequired,
  showServerError: PropTypes.bool,
  title: PropTypes.node,
  uploadDescriptionText: PropTypes.node,
  uploadFile: PropTypes.func.isRequired,
  uploadImgSrc: PropTypes.string.isRequired,
  uploading: PropTypes.bool.isRequired,
  uploadSuggestionText: PropTypes.node,
  withDialog: PropTypes.bool,
  withPaper: PropTypes.bool,
}
