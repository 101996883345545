import React from 'react'

import { FormControl, FormControlLabel, Switch } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import cx from 'classnames'
import { useField } from 'react-final-form'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { customEngineValidator } from '@helpers'

import { LightTooltip } from '@oldComponents/ui'

import { FIELD_SUBSCRIPTION } from '@constants'

const LabelWithInfoIconWrapperSpan = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
`

const IconWrapperSpan = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.blue[40]};
`

const StyledFormControl = styled(FormControl)`
  &.form-control {
    margin-top: 20px;
  }
`

const SwitchOffLabelMessage = (
  <FormattedMessage
    id="form.label.vatValidationField.off"
    defaultMessage="A számla adatait ellenőriztem. Az áfaértékre vonatkozó automatikus ellenőrzést kikapcsolom erre a számlára."
  />
)
const SwitchOnLabelMessage = (
  <FormattedMessage
    id="form.label.vatValidationField.on"
    defaultMessage="Az áfaértékre vonatkozó automatikus ellenőrzés kikapcsolva ezen a számlán."
  />
)
const SwitchOnTooltipMessage = (
  <FormattedMessage
    id="form.label.vatValidationField.tooltip"
    defaultMessage="A számla korábbi mentésekor eltérést találtunk az ÁFA értékben, amelyet a számla szerkesztője helyesnek ítélt, ezért kikapcsolta az erre vonatkozó ellenőrzést. A részleteket a számlatörténetben találod. Az automatikus ellenőrzéshez állítsd át a kapcsolót."
  />
)

interface DisableVatValidationFieldProps {
  className?: string
  disabled: boolean
  name: string
}

export function DisableVatValidationField({ className, disabled, name }: DisableVatValidationFieldProps) {
  const {
    input: { value, onChange },
    meta: { submitError, dirtySinceLastSubmit, error, touched, modifiedSinceLastSubmit, initial, submitSucceeded },
  } = useField(name, {
    subscription: {
      ...FIELD_SUBSCRIPTION,
      initial: true,
      submitSucceeded: true,
    },
    validate: customEngineValidator,
  })

  // keep visible when initial true or when submitError is present
  const [visible, setVisible] = React.useState(initial === true)

  function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    onChange(checked)
  }

  const displayedError = touched && (error || (!dirtySinceLastSubmit && !modifiedSinceLastSubmit && submitError))
  const hasError = Boolean(displayedError)

  React.useEffect(() => {
    // set visible when initial is false and hasError is true
    if (initial === false && hasError && !visible) {
      setVisible(true)
    }
  }, [hasError, initial, visible])

  React.useEffect(() => {
    // hide after successful submit if no error
    if (initial === false && !hasError && !value && visible && submitSucceeded) {
      setVisible(false)
    }
  }, [hasError, initial, submitSucceeded, value, visible])

  if (!visible) {
    return null
  }

  return (
    <StyledFormControl
      className={cx(className, 'form-control', { 'form-control-error': hasError })}
      disabled={disabled}
      error={hasError}
    >
      <FormControlLabel
        control={<Switch color="primary" checked={value} onChange={onChangeHandler} disabled={disabled} />}
        label={
          initial ? (
            <LabelWithInfoIconWrapperSpan>
              {SwitchOnLabelMessage}
              <LightTooltip title={SwitchOnTooltipMessage} placement="top">
                <IconWrapperSpan>
                  <InfoIcon style={{ fontSize: 20 }} color="inherit" />
                </IconWrapperSpan>
              </LightTooltip>
            </LabelWithInfoIconWrapperSpan>
          ) : (
            SwitchOffLabelMessage
          )
        }
      />
    </StyledFormControl>
  )
}
