import React from 'react'

import { Field, FieldProps, FieldRenderProps, useField } from 'react-final-form'

import { USE_FIELD_VALUE_CONFIG } from '@constants'

interface ConditionalReviewDoneFieldProps extends FieldProps<boolean, FieldRenderProps<boolean>> {
  isInvoiceExported: boolean
}

export function ConditionalReviewDoneField({ isInvoiceExported, ...rest }: ConditionalReviewDoneFieldProps) {
  const {
    input: { value: needsReview },
  } = useField<boolean>('needs_review', USE_FIELD_VALUE_CONFIG)

  return needsReview && isInvoiceExported ? <Field {...rest} /> : null
}
