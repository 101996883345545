import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { Button } from '@components/ui/Buttons'
import { CustomDialogActions, CustomDialogBody, CustomDialogHeader } from '@components/ui/Dialogs'
import { Typography } from '@components/ui/Typography'

import { OkButtonMessage } from '@messages'
import { PaymentDialogTitleMessage } from './messages'

const StyledCustomDialogBody = styled(CustomDialogBody)`
  max-width: 600px;
`

interface PaymentNoTransactionsViewProps {
  onClose: VoidFunction
}

export function PaymentNoTransactionsView({ onClose }: PaymentNoTransactionsViewProps) {
  return (
    <>
      <CustomDialogHeader title={PaymentDialogTitleMessage} borderless />
      <StyledCustomDialogBody>
        <Typography align="center">
          <FormattedMessage
            id="paymentEditor.emptyDialog.text"
            defaultMessage="A kiválasztott költségek közül egyik sem tartalmaz fizetendő hátralékot, hiányosan vannak kitöltve, NAV-os összerendelésre várnak, vagy duplikációk."
          />
        </Typography>
      </StyledCustomDialogBody>
      <CustomDialogActions borderless>
        <Button onClick={onClose} variant="primaryContained">
          {OkButtonMessage}
        </Button>
      </CustomDialogActions>
    </>
  )
}
