import React from 'react'

import { components as reactSelectComponents, GroupBase, OptionProps } from 'react-select'
import styled from 'styled-components'

// need to customize the recommended option
const ReactSelectOption = reactSelectComponents.Option

export type PaidThroughSelectOption = PaidThroughOption & { icon: JSX.Element }

type CustomPaidThroughSelectOptionProps = OptionProps<
  PaidThroughSelectOption,
  boolean,
  GroupBase<PaidThroughSelectOption>
>

// windowed list option's height is 48px - 8px (padding)
const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  line-height: 1.2;

  svg {
    flex-shrink: 0;
  }
`

export function CustomPaidThroughSelectOption({ children, ...rest }: CustomPaidThroughSelectOptionProps) {
  const { data } = rest
  return (
    <ReactSelectOption {...rest}>
      <OptionWrapper>
        {data.icon}
        {children}
      </OptionWrapper>
    </ReactSelectOption>
  )
}
