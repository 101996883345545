import React from 'react'
import PropTypes from 'prop-types'

import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { cancellablePromise, parseApiErrorMessage, taxNumberFormatter } from '@helpers'

import { useCancellablePromiseRef } from '@hooks'

import { AttachmentsContainer, ChangeEventTypes } from '@components/Attachments'
import { CustomDialog, CustomDialogBody, CustomDialogHeader } from '@components/ui'
import { Dialog, DialogContent, DialogTitle } from '@oldComponents/dialogs'
import DialogCloseButton from '@oldComponents/ui/buttons/DialogCloseButton'

import { DIALOG_CLASS_NAMES } from '@constants'

import { TabBadge } from '../DetailsTabs/AttachmentTab'
import { Tab, TabPanel, Tabs } from '../DetailsTabs/DetailsTabs'
import LockToggleButton from '../ui/LockToggleButton'
import WarningText from '../ui/WarningText'
import { PartnerEditorLayout, PartnerError, PartnerLoader } from './elements'
import { AppBar } from './elements/Tabs'
import { createInitialValues, getPartnerInfo } from './helpers'
import PartnerAlternateNames from './PartnerAlternateNames'
import PartnerEditorForm from './PartnerEditorForm'
import { initialState, reducer } from './reducer'
import { InitialValues, PartnerDialogData } from './types'

import { editorStyles, layoutStyles, styles } from './styles'

const StyledCustomDialogHeader = styled(CustomDialogHeader)`
  z-index: 11; // higher than AppBar's z-index (10)
`

const StyledCustomDialog = styled(CustomDialog)`
  &.${DIALOG_CLASS_NAMES.content.base} {
    max-width: min(-40px + 100vw, 1440px);
  }
`

const StyledCustomDialogBody = styled(CustomDialogBody)`
  background-color: ${({ theme }) => theme.colors.gray[102]};
  padding-left: 0;
  padding-right: 0;
`

const StyledDialogCloseButton = styled(DialogCloseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const useEditorStyles = makeStyles(editorStyles)
const useLayoutStyles = makeStyles(layoutStyles)
const useStyles = makeStyles(styles)

interface PartnerCreateDialogProps {
  initialValues: InitialValues
  onClose: VoidFunction
  onSubmit: AsyncFunction<InitialValues, unknown>
  open: boolean
}

export function PartnerCreateDialog({ open, onClose, onSubmit, initialValues }: PartnerCreateDialogProps) {
  const classes = useStyles()
  const editorClasses = useEditorStyles()
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="partner-create-dialog-title">
      <DialogTitle id="partner-create-dialog-title" className={classes.editorHeader}>
        <FormattedMessage id="partner.editor.dialog.title" defaultMessage="Új partner hozzáadása" />
        <DialogCloseButton onClick={onClose} />
      </DialogTitle>
      <DialogContent className={editorClasses.editorBody}>
        <PartnerEditorForm initialValues={initialValues} onSubmit={onSubmit} variant="modal" />
      </DialogContent>
    </Dialog>
  )
}

PartnerCreateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

interface PartnerEditDialogProps {
  isExpenseVisibleForUser: boolean
  isIncomeVisibleForUser: boolean
  loadData: AsyncFunction<number, PartnerDialogData>
  onClose: VoidFunction
  onSubmit: AsyncFunction<InitialValues, unknown>
  open: boolean
  partnerId: number
  updatePartnerAttachment: (payload: { id: number; has_attachment: boolean }) => void
  variant: 'provider' | 'customer'
}

export function PartnerEditDialog({
  isExpenseVisibleForUser,
  isIncomeVisibleForUser,
  loadData,
  onClose,
  onSubmit,
  open,
  partnerId,
  updatePartnerAttachment,
  variant,
}: PartnerEditDialogProps) {
  const classes = useStyles()
  const layoutClasses = useLayoutStyles()
  const [formEnabled, setFormEnabled] = React.useState(false)
  const [activeTab, setActiveTab] = React.useState<'form' | 'documents'>('form')
  const [{ attachmentCount, data, isEditDisabled, loading, error }, dispatch] = React.useReducer(reducer, initialState)
  const cPromiseRef = useCancellablePromiseRef<PartnerDialogData>()

  // load partner details
  React.useEffect(() => {
    if (open) {
      dispatch({ type: 'request' })
      cPromiseRef.current = cancellablePromise(loadData(partnerId))

      cPromiseRef.current.promise
        .then(payload => {
          dispatch({ type: 'success', payload })
        })
        .catch(error => {
          const errorMessage = parseApiErrorMessage(error)
          if (errorMessage) {
            dispatch({ type: 'failure', payload: errorMessage })
          }
        })
    }
  }, [loadData, partnerId, open, cPromiseRef])

  const initialValues = React.useMemo(() => createInitialValues(data, variant), [data, variant])

  const memoizedArtifact = React.useMemo(() => {
    if (data) {
      const { last_invoice_url, last_income_url } = data
      if (variant === 'provider' && isExpenseVisibleForUser && last_invoice_url) {
        return last_invoice_url
      }
      if (variant === 'customer' && isIncomeVisibleForUser && last_income_url) {
        return last_income_url
      }
      if (!last_invoice_url && isIncomeVisibleForUser) {
        return last_income_url
      }
      if (!last_income_url && isExpenseVisibleForUser) {
        return last_invoice_url
      }
    }
    return null
  }, [data, variant, isExpenseVisibleForUser, isIncomeVisibleForUser])

  React.useEffect(() => {
    if (!open) {
      setFormEnabled(false)
      setActiveTab('form')
    }
  }, [open])

  const handleFormLockChange = React.useCallback(
    event => {
      setFormEnabled(event.target.checked)
    },
    [setFormEnabled]
  )

  const handleTabChange = (event: React.ChangeEvent<Record<string, never>>, newValue: 'form' | 'documents') => {
    setActiveTab(newValue)
  }

  const handleAttachmentChange = React.useCallback<(eventType: ChangeEventTypes) => void>(
    eventType => {
      if (eventType === ChangeEventTypes.CREATE_SUCCESS) {
        if (data && attachmentCount === 0) {
          updatePartnerAttachment({ id: data.id, has_attachment: true })
        }
        dispatch({ type: 'attachment-increase' })
      } else {
        if (data && attachmentCount === 1) {
          updatePartnerAttachment({ id: data.id, has_attachment: false })
        }
        dispatch({ type: 'attachment-decrease' })
      }
    },
    [attachmentCount, data, updatePartnerAttachment]
  )

  return (
    <StyledCustomDialog open={open} onClose={onClose} fullWidth>
      <StyledCustomDialogHeader title={getPartnerInfo(data)} align="left">
        <StyledDialogCloseButton onClick={onClose} />
      </StyledCustomDialogHeader>
      <StyledCustomDialogBody>
        {loading && <PartnerLoader />}
        {error && <PartnerError onClose={onClose}>{error}</PartnerError>}
        {data && (
          <PartnerEditorLayout artifact={memoizedArtifact}>
            <Paper className={layoutClasses.paper}>
              <AppBar position="static" color="default">
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="partner tabs" variant="fullWidth">
                  <Tab
                    value="form"
                    id="partner-editor-tab-form"
                    aria-controls="partner-editor-tabpanel-form"
                    label={<FormattedMessage id="partnerEditor.tabs.form" defaultMessage="Partner adatai" />}
                  />
                  <Tab
                    value="documents"
                    id="partner-editor-tab-documents"
                    aria-controls="partner-editor-tabpanel-documents"
                    label={
                      <TabBadge
                        badgeContent={attachmentCount}
                        color={activeTab === 'documents' ? 'secondary' : 'default'}
                        variant="standard"
                        invisible={!attachmentCount}
                      >
                        <FormattedMessage id="partnerEditor.tabs.documents" defaultMessage="Csatolmányok" />
                      </TabBadge>
                    }
                  />
                </Tabs>
              </AppBar>
              <TabPanel
                visible={activeTab === 'form'}
                id="partner-editor-tabpanel-form"
                aria-labelledby="partner-editor-tab-form"
                className={layoutClasses.viewport}
              >
                <div className={layoutClasses.containerWithDivider}>
                  <div>
                    <LockToggleButton checked={formEnabled} onChange={handleFormLockChange} />
                    <Typography component="span" className={classes.label}>
                      <FormattedMessage
                        id="partner.editor.toggleLabel"
                        defaultMessage="Az adatok szerkesztéséhez először oldd fel a zárat"
                      />
                    </Typography>
                  </div>
                  {isEditDisabled && (
                    <WarningText className={classes.warning}>
                      <FormattedMessage
                        id="partner.editor.integrationSourceWarning"
                        defaultMessage="Integrációból származó partner adatai részlegesen szerkeszthetőek."
                      />
                    </WarningText>
                  )}
                </div>

                <PartnerEditorForm
                  locked={!formEnabled}
                  initialValues={initialValues}
                  isEditDisabled={isEditDisabled}
                  onSubmit={onSubmit}
                  recommendations={{
                    taxNumber: (data?.recommendations?.tax_number ?? []).map(taxNumber =>
                      taxNumberFormatter.format(taxNumber)
                    ),
                    bankAccount: data?.recommendations?.bank_account ?? [],
                  }}
                  alternateNames={<PartnerAlternateNames data={data} />}
                  method="edit"
                />
              </TabPanel>
              <TabPanel
                visible={activeTab === 'documents'}
                id="partner-editor-tabpanel-documents"
                aria-labelledby="partner-editor-tab-documents"
                className={layoutClasses.viewport}
              >
                <AttachmentsContainer
                  active={activeTab === 'documents'}
                  documentType="partner"
                  documentId={data.id}
                  onChange={handleAttachmentChange}
                />
              </TabPanel>
            </Paper>
          </PartnerEditorLayout>
        )}
      </StyledCustomDialogBody>
    </StyledCustomDialog>
  )
}

PartnerEditDialog.propTypes = {
  variant: PropTypes.oneOf(['provider', 'customer']).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  partnerId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  updatePartnerAttachment: PropTypes.func.isRequired,
  isExpenseVisibleForUser: PropTypes.bool.isRequired,
  isIncomeVisibleForUser: PropTypes.bool.isRequired,
}
