import React from 'react'
import PropTypes from 'prop-types'

import { useField } from 'react-final-form'

import { USE_FIELD_VALUE_CONFIG } from '@constants'

import { LedgerNumberSelect, LedgerNumberSelectOwnProps, propTypes } from './LedgerNumberSelect'

interface AssignmentLedgerNumberSelectProps extends Omit<LedgerNumberSelectOwnProps, 'suggestion' | 'recommendations'> {
  fieldName: string
  options: ExpenseTypeData[] // TODO later it can be RevenueType too
}

export function AssignmentLedgerNumberSelect({ fieldName, options, ...rest }: AssignmentLedgerNumberSelectProps) {
  const {
    input: { value: assignmentTypeId },
  } = useField<number>(fieldName, USE_FIELD_VALUE_CONFIG)

  const recommendations = options.find(({ id }) => id === assignmentTypeId)?.ledger_numbers ?? []

  return <LedgerNumberSelect {...rest} recommendations={recommendations} />
}

AssignmentLedgerNumberSelect.propTypes = {
  ...propTypes,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      ledger_numbers: PropTypes.array.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}
