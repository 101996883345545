import React from 'react'

import { isGroupedOptionGuard } from './helpers'

export function useIsValidNewOption<Value, Option extends Record<string, Value>>(labelKey: string) {
  return React.useCallback(
    // inputValue: currently typed in value - what search against for
    // selectValue: currently selected field value - what see when click out and clear search
    // selectOptions: options list
    (inputValue: string, selectValue: Option[], selectOptions: ReactSelectOption<Value>[]) => {
      const compareOption = (value: string, option: Option) => {
        const candidate = String(value).toLowerCase()
        const labelString = String(option?.[labelKey] ?? '').toLowerCase()
        return labelString === candidate
      }
      const isGroupedOption = (inputValue: string, option: ReactSelectOption<Value>): boolean => {
        // grouped option structure is { label: String, options: Array}
        if (isGroupedOptionGuard(option)) {
          return option.options.some(function (innerOption) {
            return isGroupedOption(inputValue, innerOption)
          })
        } else {
          return compareOption(inputValue, option as Option)
        }
      }

      // inputValue is a valid new option when:
      // 1) not an empty text
      // 2) not exists in any selected options (selectValue)
      // 3) not exists in any selectable options (selectOptions)
      // need to negate it because compareOption is looking for "positive" match
      return !(
        !inputValue ||
        selectValue.some(function (option) {
          return compareOption(inputValue, option)
        }) ||
        selectOptions.some(function (option) {
          return isGroupedOption(inputValue, option)
        })
      )
    },
    [labelKey]
  )
}
