import React from 'react'

import { useIntl } from 'react-intl'

import { StrongTextValues } from '@helpers'

import { useCreatePayment } from '@contexts/PaymentProvider'

import { CoinsRegularIcon } from '@components/ui'
import { QuickIconButton } from '@oldComponents/ui/buttons'

import { PAYMENT_FLOW } from '@constants'

import { FeaturePermissons, PlanPermission } from '@permissions'

import { listPageMessages } from '@messages'

interface CreateExpressPaymentIconButtonProps {
  disabled: boolean
}

export function CreateExpressPaymentIconButton({ disabled }: CreateExpressPaymentIconButtonProps) {
  const [open, setOpen] = React.useState(false)
  const { formatMessage } = useIntl()
  const {
    startExpressPayment,
    state: { loading, flow },
  } = useCreatePayment()

  function handleClick() {
    setOpen(false)
    startExpressPayment()
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <PlanPermission
      perform={FeaturePermissons.PAYMENT}
      yes={() => (
        <QuickIconButton
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onClick={handleClick}
          disabled={disabled || loading}
          loading={loading && flow === PAYMENT_FLOW.express}
          icon={<CoinsRegularIcon />}
          size="medium"
          tooltipText={formatMessage(listPageMessages.createExpressPayment)}
        />
      )}
      no={() => (
        <QuickIconButton
          disabled
          icon={<CoinsRegularIcon />}
          size="medium"
          tooltipText={formatMessage(listPageMessages.createExpressPaymentUpsell, StrongTextValues)}
        />
      )}
    />
  )
}
