import React from 'react'

import { Field, FieldProps, FieldRenderProps, useField } from 'react-final-form'

import { USE_FIELD_VALUE_CONFIG } from '@constants'

interface ConditionalKulcssoftNoteFieldProps extends FieldProps<string, FieldRenderProps<string>> {
  renderWrapper: (children: JSX.Element) => JSX.Element
}

export function ConditionalKulcssoftNoteField({ renderWrapper, ...rest }: ConditionalKulcssoftNoteFieldProps) {
  const {
    input: { value: needsReview },
  } = useField<boolean>('needs_review', USE_FIELD_VALUE_CONFIG)

  return needsReview ? renderWrapper(<Field {...rest} />) : null
}
