import React from 'react'
import PropTypes from 'prop-types'

import __uniqueId from 'lodash/uniqueId'
import { FormattedMessage } from 'react-intl'

import { StrongTextValues } from '@helpers/translation'

import { PreviewProvider } from '@contexts'
import { useCreatePayment } from '@contexts/PaymentProvider'

import { CustomDialog, TabList, TabPanel, Tabs } from '@components/ui'

import { PAYMENT_FLOW, PAYMENT_TRANSACTION_TYPE, PaymentDialogState } from '@constants'

import {
  CreateManualOrExpressPaymentForm,
  CreateTransferPaymentForm,
  PaymentAttentionView,
  PaymentDialogHeader,
  PaymentDialogTab,
  PaymentDialogTabLabel,
  PaymentDialogTitleMessage,
  PaymentNoTransactionsView,
  PaymentResponseView,
  PaymentSummaryView,
} from './elements'
import { CreatePaymentDialogProps } from './types'

export function CreatePaymentDialog({
  onCallOfAttention,
  onClose,
  onSubmit,
  onSubmitSuccess,
  setFlow,
  transactionType,
}: CreatePaymentDialogProps) {
  const {
    state: { data, dialogState, flow, open, responseData },
  } = useCreatePayment()
  const ariaIdPrefix = __uniqueId('create-payment-dialog')
  const showInfoRow = Boolean(data?.paidExists) && dialogState !== PaymentDialogState.SUMMARY

  //* TABS control
  // tabs[0]: transfer, tabs[1]: express & manual
  function setSelectedTabIndex(index: number) {
    setFlow(index === 0 ? PAYMENT_FLOW.transfer : PAYMENT_FLOW.express)
  }

  function renderView() {
    if (!data || (data?.transactions ?? []).length === 0) {
      return <PaymentNoTransactionsView onClose={onClose} />
    }
    if (dialogState === PaymentDialogState.SUMMARY && responseData) {
      return (
        <PaymentSummaryView
          data={responseData as BackendPaymentsResult}
          flow={flow}
          onCallOfAttention={onCallOfAttention}
          onClose={onClose}
        />
      )
    }
    if (dialogState === PaymentDialogState.RESPONSE && responseData) {
      return <PaymentResponseView onClose={onClose} data={responseData} flow={flow} />
    }
    if (dialogState === PaymentDialogState.CALL_OF_ATTENTION) {
      return <PaymentAttentionView onClose={onClose} />
    }
    return (
      <PreviewProvider>
        <Tabs
          selectedIndex={flow === PAYMENT_FLOW.transfer ? 0 : 1}
          onSelect={setSelectedTabIndex}
          aria-label="payment-tabs"
        >
          <PaymentDialogHeader title={PaymentDialogTitleMessage}>
            <TabList>
              <PaymentDialogTab>
                <PaymentDialogTabLabel
                  label={
                    <FormattedMessage
                      tagName="span"
                      id="payment.dialog.tabs.bankTransferFile"
                      defaultMessage="Most fizetek"
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="payment.dialog.tabs.tooltip.bankTransferFile"
                      defaultMessage="Akkor válaszd ezt, ha most állítod össze az utalási csomagot. Ha QUiCK által támogatott bankszámlád van, akkor <b>egy utalási csomag is rendelkezésedre áll</b>, amelyet betölthetsz a netbankodba. (Ha nincs támogatott bankszámlád, akkor egyszerűen ki tudod másolni az adatokat a képernyőről az utaláshoz.)"
                      values={StrongTextValues}
                    />
                  }
                />
              </PaymentDialogTab>
              <PaymentDialogTab>
                <PaymentDialogTabLabel
                  label={
                    <FormattedMessage
                      tagName="span"
                      id="payment.dialog.tabs.recordPayment"
                      defaultMessage="Már kifizettem"
                    />
                  }
                  tooltip={
                    <FormattedMessage
                      id="payment.dialog.tabs.tooltip.recordPayment"
                      defaultMessage="Akkor válaszd ezt, ha már egy korábban kifizetett tranzakciódat szeretnéd rögzíteni."
                    />
                  }
                />
              </PaymentDialogTab>
            </TabList>
          </PaymentDialogHeader>
          <TabPanel>
            <CreateTransferPaymentForm
              data={data}
              flow={flow}
              onClose={onClose}
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              showInfoRow={showInfoRow}
              transactionType={transactionType}
            />
          </TabPanel>
          <TabPanel>
            <CreateManualOrExpressPaymentForm
              data={data}
              flow={flow}
              onClose={onClose}
              onSubmit={onSubmit}
              onSubmitSuccess={onSubmitSuccess}
              setFlow={setFlow}
              showInfoRow={showInfoRow}
              transactionType={transactionType}
            />
          </TabPanel>
        </Tabs>
      </PreviewProvider>
    )
  }

  return (
    <CustomDialog
      ariaIdPrefix={ariaIdPrefix}
      onClose={onClose}
      open={open}
      shouldCloseOnEsc={dialogState !== PaymentDialogState.FORM}
      shouldCloseOnOverlayClick={dialogState !== PaymentDialogState.FORM}
    >
      {renderView()}
    </CustomDialog>
  )
}

CreatePaymentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  setFlow: PropTypes.func.isRequired,
  transactionType: PropTypes.oneOf(Object.values(PAYMENT_TRANSACTION_TYPE)).isRequired,
}
