import { Decorator, getIn, setIn } from 'final-form'
import createDecorator, { FocusableInput } from 'final-form-focus'
import type { ReactNode } from 'react'
import { AnySchema as YupSchema, ValidationError as YupValidationError } from 'yup'

import { getMaximumFractionDigitsByCurrency, roundToDecimal } from './common'
/**
 * Helper function to put back empty values before sending form data to the server.
 *
 * Make sure that the type of form values is a `type` and not `interface`
 *
 * @param {Partial<FormValues>} submitValues - values gotten by react-final-form
 * @param {FormValues} initialValues - values given to the form initially
 * @returns {Required<FormValues>} - values to send to the server
 */
export function applyEmptyFormFields<FormValues extends Record<string, unknown>>(
  submitValues: Partial<FormValues>,
  initialValues: FormValues
): Required<FormValues> {
  const result = { ...submitValues } as Required<FormValues>
  Object.keys(initialValues).forEach((key: keyof FormValues) => {
    if (submitValues[key] === undefined) {
      result[key] = initialValues[key]
    }
  })
  return result
}

/**
 * Helper function to transform empty string values to `null` when sending form data to the server.
 *
 * Make sure that the type of form values is a `type` and not `interface`
 *
 * @param {FormValues} values - values gotten by react-final-form
 * @param {Array<keyof FormValues>} nullableFields - fields that should be null when empty
 * @returns {NullableEmptyStringObject<FormValues>} - values to send to the server
 */
export function transformEmptyStringFormValuesToNull<FormValues extends Record<string, unknown>>(
  values: FormValues,
  nullableFields: Array<keyof FormValues>
): NullableEmptyStringObject<FormValues> {
  const result = { ...values } as NullableEmptyStringObject<FormValues>

  nullableFields.forEach(key => {
    if (values[key] === '') {
      result[key] = null as FormValues[keyof FormValues]
    }
  })
  return result
}

/**
 * Helper function to apply empty form values and transform the required form fields' values to null in one go.
 *
 * Make sure that the type of form values is a `type` and not `interface`
 *
 * @param submitValues - values gotten by react-final-form
 * @param initialValues - values given to the form initially
 * @param nullableFields  - fields that should be null when empty
 * @returns - values to send to the server
 */
export function applyEmptyFormFieldsAndNullEmptyStrings<FormValues extends Record<string, unknown>>(
  submitValues: Partial<FormValues>,
  initialValues: FormValues,
  nullableFields: Array<keyof FormValues>
): NullableEmptyStringObject<Required<FormValues>> {
  return transformEmptyStringFormValuesToNull(applyEmptyFormFields(submitValues, initialValues), nullableFields)
}

//* YUP VALIDATION

type Translator = (errorObj: YupValidationError) => string | ReactNode

/**
 * Returns a validation error
 * Pass an optional Translator if you need to extend translations
 * Validation based on mui-rff - https://github.com/lookfirst/mui-rff/blob/master/src/Validation.ts
 *
 * @param {(YupValidationError)} error an error from Yup
 * @param {(Translator)} translator (optional)
 * @returns {ValidationError} validation error object
 */
function normalizeValidationError(error: YupValidationError, translator?: Translator): ValidationError {
  return error.inner.reduce((errors, innerError) => {
    const { path, message } = innerError
    const element: ReturnType<Translator> = translator ? translator(innerError) : message

    if (path && Object.prototype.hasOwnProperty.call(errors, path)) {
      // path can look like this, so lodash is preferred: `fieldArray[2].nestedField[0].endField`
      const prev = getIn(errors, path)
      prev.push(element)
      errors = setIn(errors, path, prev)
    } else if (path) {
      errors = setIn(errors, path, [element])
    }
    return errors
  }, {})
}

/**
 * Returns a promise returning a validation error message object for react-final-form to use
 * Pass an optional Translator if you need to extend translations
 * Validation based on mui-rff - https://github.com/lookfirst/mui-rff/blob/master/src/Validation.ts
 *
 * @param {Schema extends YupSchema} validator a yup schema
 * @param {Translator} translator (optional)
 * @returns {Promise<ValidationError>} Promise - validation error object
 */
export function makeValidate<Schema extends YupSchema>(
  validator: Schema,
  translator?: Translator
): <FormValues>(values: FormValues) => Promise<ValidationError> {
  return async values => {
    try {
      await validator.validate(values, { abortEarly: false })
      return {}
    } catch (err) {
      return normalizeValidationError(err as YupValidationError, translator)
    }
  }
}

//* RFF decorators
interface OurFocusableInput extends FocusableInput {
  id?: string
}

function findInput(inputs: OurFocusableInput[], errors: object) {
  return inputs.find(input => {
    return (input.id && getIn(errors, input.id)) || (input.name && getIn(errors, input.name))
  })
}

// cast <object, object> to avoit type issues in components
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const focusOnErrors: Decorator<any, any> = createDecorator(undefined, findInput)

//* Calculators in forms
/**
 * Calculate net and vat amount based on gross amount and percent
 * @param grossAmount - gross amount
 * @param percent - percent
 * @param currency - currency (optional, default: HUF)
 * @returns net and vat amount as Decimal
 */
export function calculateNetAndVatAmount(
  grossAmount: Decimal | number,
  percent: Nullable<number> | undefined,
  currency: number | string = 'HUF'
): { net_amount: Decimal; vat_amount: Decimal } {
  if (percent == null) {
    return {
      net_amount: '',
      vat_amount: '',
    }
  }

  const maximumFractionDigits = getMaximumFractionDigitsByCurrency(currency)
  const numericGrossAmount = Number(grossAmount) || 0
  const netAmount = percent == null ? numericGrossAmount : numericGrossAmount / (1 + percent / 100)

  return {
    net_amount: roundToDecimal(netAmount, { maximumFractionDigits, numeric: false }),
    vat_amount: roundToDecimal(numericGrossAmount - netAmount, { maximumFractionDigits, numeric: false }),
  }
}

/**
 * Calculate gross amount based on net amount and percent
 * @param netAmount - net amount
 * @param percent - percent
 * @param currency - currency (optional, default: HUF)
 * @returns gross and vat amount as Decimal
 */
export function calculateGrossAndVatAmount(
  netAmount: Decimal | number,
  percent: Nullable<number> | undefined,
  currency: number | string = 'HUF'
) {
  if (percent == null) {
    return {
      gross_amount: '',
      vat_amount: '',
    }
  }

  const maximumFractionDigits = getMaximumFractionDigitsByCurrency(currency)
  const numericNetAmount = Number(netAmount) || 0
  const gross_amount = calculateGrossAmount(numericNetAmount, percent)
  const vat_amount = roundToDecimal(Number(gross_amount) - numericNetAmount, { maximumFractionDigits, numeric: false })

  return {
    gross_amount,
    vat_amount,
  }
}

/**
 * Calculate gross amount based on net amount and percent
 * @param netAmount - net amount
 * @param percent - percent
 * @param currency - currency (optional, default: HUF)
 * @returns gross amount as Decimal
 */
export function calculateGrossAmount(
  netAmount: number,
  percent: Nullable<number> | undefined,
  currency: number | string = 'HUF'
) {
  if (percent == null) {
    return roundToDecimal(netAmount)
  }

  const maximumFractionDigits = getMaximumFractionDigitsByCurrency(currency)

  return roundToDecimal(netAmount * (1 + percent / 100), { maximumFractionDigits, numeric: false })
}

/**
 * Calculate gross amount based on net amount and percent
 * @param grossAmount - gross amount
 * @param netAmount - net amount
 * @returns vat amount as Decimal
 */
export function calculateVatAmount(grossAmount: Decimal | number, netAmount: Decimal | number) {
  const numericGrossAmount = Number(grossAmount) || 0
  const numericNetAmount = Number(netAmount) || 0
  return roundToDecimal(numericGrossAmount - numericNetAmount)
}
