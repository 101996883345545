import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import __uniqueId from 'lodash/uniqueId'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { ActionMeta } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { usePromptTextCreator } from '@hooks'

import { JobNumberOption } from './types'

import { selectFormStyles as customReactSelectStyles } from '@styles/select'
import { formSelectMessages } from '@messages'

function compareOption(inputValue: string, option: JobNumberOption) {
  const candidate = inputValue.toLowerCase()
  const valueString = option.value.toLowerCase()
  return valueString === candidate
}

function isValidNewOption(inputValue: string, selectValue: JobNumberOption[]) {
  // returns true when inputValue is not empty or selectValue does not match with entered inputValue
  return !(
    !inputValue ||
    selectValue.some(function (option) {
      return compareOption(inputValue, option)
    })
  )
}

function getNewOptionData(inputValue: string, optionLabel: React.ReactNode): JobNumberOption {
  return {
    value: inputValue,
    label: String(optionLabel),
  }
}

function getOptionLabel(option: JobNumberOption) {
  return option.label
}

function getOptionValue(option: JobNumberOption) {
  return option.value
}

interface JobNumberSelectProps extends Pick<FieldRenderProps<string, HTMLSelectElement>, 'input'> {
  disabled?: boolean
  hasError: boolean
  options: JobNumberOption[]
}

export function JobNumberSelect({
  hasError,
  disabled,
  input: { onBlur, onChange, value, ...inputProps },
  options,
}: JobNumberSelectProps) {
  const timerRef = React.useRef<number | undefined>(undefined)
  const [inputValue, setInputValue] = React.useState('')
  const promptTextCreator = usePromptTextCreator()
  const { formatMessage } = useIntl()

  React.useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  function handleInputChange(rawValue: string) {
    setInputValue(rawValue)
  }

  function handleBlur() {
    timerRef.current = window.setTimeout(() => onBlur(), 100)
  }

  function handleChange(option: Nullable<JobNumberOption>, { action }: ActionMeta<JobNumberOption>) {
    if (action === 'select-option' && option) {
      onChange(option.value) // set to form fields
    }
    if (action === 'clear') {
      onChange(null) // set to form fields
    }
  }

  function handleCreateOption(inputValue: string) {
    const value = inputValue.trim()
    onChange(value) // set to form fields
  }

  function noOptionsMessage() {
    if (!options.length) {
      return formatMessage(formSelectMessages.jobNumberEmptyCreateText)
    }
    return formatMessage(formSelectMessages.selectNoResultsText)
  }

  const selectValue = value ? { value, label: value } : null

  return (
    <CreatableSelect
      classNamePrefix="react-select"
      instanceId={__uniqueId('rs')}
      inputId={inputProps.name}
      value={selectValue}
      className={cx({ error: hasError })}
      isDisabled={disabled}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      onBlur={handleBlur}
      options={options}
      placeholder={formatMessage(formSelectMessages.selectPlaceholder)}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={noOptionsMessage}
      getNewOptionData={getNewOptionData}
      onCreateOption={handleCreateOption}
      formatCreateLabel={promptTextCreator}
      isValidNewOption={isValidNewOption as any} // need this cast because this callback "selectValue" type is wrong
      styles={customReactSelectStyles}
      isClearable
    />
  )
}

JobNumberSelect.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  input: PropTypes.object.isRequired as React.Validator<JobNumberSelectProps['input']>,
}
