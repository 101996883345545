import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Props as RndProps, Rnd } from 'react-rnd'
import styled from 'styled-components'

import { Button, EditIcon, IconButton, TrashRegularIcon, Typography } from '@components/ui'

import { CancelButtonMessage } from '@messages'

const PostItCard = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-shadow: ${({ theme }) => theme.shadows[80]};
  background-color: #ff97e2;
  border-radius: 2px;
`

const RnDPostItCardContainer = styled(Rnd)`
  &:hover,
  &.active {
    z-index: 300;
  }

  &:hover ${PostItCard}, &.active ${PostItCard} {
    background-color: #eecfe5;
  }
  &.editable ${PostItCard} {
    background-color: #d8e737;
  }
  &.editable:hover ${PostItCard}, &.editable.active ${PostItCard} {
    background-color: #eef0db;
  }
` as React.FC<RndProps>

const StyledIconButton = styled(IconButton)`
  width: 20px;
  height: 20px;
  padding: 2px;
`

const PostItCardHeader = styled.div`
  color: ${({ theme }) => theme.colors.gray[70]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[80]};
  padding: 2px 2px 4px 8px;
  font-size: 12px;
  line-height: 1.2;
  height: 42px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`

const OwnerSpan = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray[80]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-end;
`

const PostItCardBody = styled.div`
  flex: 1;
  padding-bottom: 4px;
  overflow: hidden;

  .post-it-input {
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 14px;
    line-height: 1.2;
    color: #000;
    white-space: pre-wrap;
    padding: 4px 8px;
    overflow: auto;
  }
  p.post-it-input {
    height: 100%;
  }
  textarea.post-it-input {
    resize: none;
    min-height: 100%;
    height: auto;
    overflow: visible;
  }

  .post-it-input:focus {
    outline: none;
  }
`

const PostItButton = styled(Button)`
  padding: 5px 10px;
`

const PostItPrimaryButton = styled(PostItButton)`
  background-color: #ff97e2;
  border-color: #ff97e2;

  &:focus,
  &:hover {
    background-color: #eecfe5;
    border-color: #eecfe5;
  }

  .editable & {
    background-color: #d8e737;
    border-color: #d8e737;

    &:focus,
    &:hover {
      background-color: #eef0db;
      border-color: #eef0db;
    }
  }
`

const PostItRemoveConfirmWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  border: 2px solid;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-color: #ff97e2;

  .editable & {
    border-color: #d8e737;
  }
`

const PostItRemoveConfirmButtonsContainer = styled.div`
  margin-top: 2em;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`

const MIN_WIDTH = 200
const MIN_HEIGHT = 120
const MAX_WIDTH = 340
const MAX_HEIGHT = 400

export interface Note {
  created: string
  editable: boolean
  height: number
  id: number
  owner: string
  page: number
  text: string
  width: number
  x: number
  y: number
}

interface PostItNoteProps {
  data: Note
  onDelete: (id: number) => void
  onEdit: (data: Note) => void
  scale: number
}

type PositionProps = {
  x: number
  y: number
}

type SizeProps = {
  width: number
  height: number
}

export function PostItNote({
  data: { id, text, editable, created, owner, x, y, width, height },
  data,
  onDelete,
  onEdit,
  scale,
}: PostItNoteProps) {
  const [value, setValue] = React.useState<string>(text)
  const [active, setActive] = React.useState<boolean>(false)
  const [confirmVisible, setConfirmVisible] = React.useState<boolean>(false)
  const [position, setPosition] = React.useState<PositionProps>({
    x: Math.round(scale * x),
    y: Math.round(scale * y),
  })
  const [size, setSize] = React.useState<SizeProps>({ width, height })

  React.useEffect(() => {
    let mounted = true
    if (mounted) {
      setPosition({
        x: Math.round(scale * x),
        y: Math.round(scale * y),
      })
    }
    return () => {
      mounted = false
    }
  }, [scale])

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setActive(true)
  }

  const handleDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setConfirmVisible(true)
  }

  const handleConfirm = () => {
    onDelete(id)
  }

  const handleCancel = () => {
    setConfirmVisible(false)
  }

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  const handleSave = () => {
    // sync state value with state note
    if (value !== text) {
      onEdit({ ...data, text: value })
    }
    setActive(false)
  }

  return (
    <RnDPostItCardContainer
      className={cx({ active, editable })}
      size={size}
      position={position}
      onDragStop={(_, d) => {
        if (d.x !== position.x || d.y !== position.y) {
          // save to state
          setPosition({ x: d.x, y: d.y })
          // save to backend
          if (editable) {
            onEdit({ ...data, x: Math.round(d.x / scale), y: Math.round(d.y / scale) })
          }
        }
      }}
      onResizeStop={(_, __, ref, ___, position) => {
        const rWidth = parseInt(ref.style.width, 10)
        const rHeight = parseInt(ref.style.height, 10)
        if (size.width !== rWidth || size.height !== rHeight) {
          // save to state
          setSize({ width: rWidth, height: rHeight })
          // save to backend
          if (editable) {
            onEdit({ ...data, x: Math.round(position.x), y: Math.round(position.y), width: rWidth, height: rHeight })
          }
        }
      }}
      minWidth={MIN_WIDTH}
      minHeight={MIN_HEIGHT}
      maxWidth={MAX_WIDTH}
      maxHeight={MAX_HEIGHT}
      bounds="parent"
      enableResizing={!confirmVisible}
      disableDragging={confirmVisible}
    >
      <PostItCard>
        <PostItCardHeader>
          <Header>
            <span>{created}</span>
            {editable && !active && (
              <ButtonsContainer>
                <StyledIconButton onClick={handleEdit} variant="secondaryText">
                  <EditIcon size={14} />
                </StyledIconButton>
                <StyledIconButton onClick={handleDelete}>
                  <TrashRegularIcon size={13} />
                </StyledIconButton>
              </ButtonsContainer>
            )}
          </Header>
          <OwnerSpan>{owner}</OwnerSpan>
        </PostItCardHeader>
        <PostItCardBody>
          {active ? (
            <textarea autoFocus className="post-it-input" onBlur={handleSave} onChange={handleChange} value={value} />
          ) : (
            <p className="post-it-input">{value}</p>
          )}
        </PostItCardBody>
        {confirmVisible && (
          <PostItRemoveConfirmWrapper>
            <Typography size="700-sm" color="gray-80">
              <FormattedMessage id="postIt.confirmTitleText" defaultMessage="Biztosan törlöd?" />
            </Typography>
            <PostItRemoveConfirmButtonsContainer>
              <PostItPrimaryButton size="small" onClick={handleConfirm}>
                <FormattedMessage id="postIt.buttons.confirmButtonText" defaultMessage="Igen, töröljük" />
              </PostItPrimaryButton>
              <PostItButton size="small" onClick={handleCancel} variant="secondaryContained">
                {CancelButtonMessage}
              </PostItButton>
            </PostItRemoveConfirmButtonsContainer>
          </PostItRemoveConfirmWrapper>
        )}
      </PostItCard>
    </RnDPostItCardContainer>
  )
}

PostItNote.propTypes = {
  data: PropTypes.shape({
    created: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    height: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    owner: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  scale: PropTypes.number.isRequired,
}
PostItNote.displayName = 'PostItNote'
