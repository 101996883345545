import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { incomeListWorkerRunner, parseFiltersFromUrlWorkerRunner } from '@webWorkers'

import {
  AMPLITUDE_EVENTS,
  downloadFileFromAPIHeaders,
  downloadFileWithURL,
  generateBackgroundProcessActionPayload,
  getActiveCompanyId,
  getCursorFromUrl,
  getErrorMessage,
  getErrorMessageFromBlob,
  getIncomeListFiltersFromStore,
  getIncomeTypes,
  getIncomeTypeText,
  getRFFFormErrors,
  getUrlFilterOptionsFromStore,
  sendAmplitudeData,
} from '@helpers'
import { INCOME_TYPE_INVOICE } from '@oldComponents/pages/DashboardIncomePage/helpers'

import { BackgroundProcessActions } from '@constants'

import { BackgroundDownloadEmailProcess, JOB_STATUS_FINISHED } from '../background/process'
import dashboardActions from '../dashboard/actions'
import { bulkTagging as bulkTaggingApi, fetchTags as fetchTagsApi } from '../dashboard/api'
import filtersActions from '../filters/actions'
import actions from './actions'
import * as api from './api'
import { DownloadBackgroundAction, ExportBackgroundAction } from './backgroundActions'

//* INCOMES

//* INCOME DETAILS
function* fetchIncomeDetailsSaga({ payload }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.fetchIncomeDetails, companyId, payload)
    // --amplitude tracking start
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.VIEW_INCOME)
    // --amplitude tracking end
    yield put(actions.fetchIncomeDetails.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchIncomeDetails.failure(errorMsg))
  }
}

function* updateIncomeSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.updateIncome, companyId, payload)
    // if partner is not exists in payload skip bulkInvoiceUpdateCheck
    // and return null az bulkResponseData
    let bulkResponseData = null
    if (payload.partner_id && payload.income_type === INCOME_TYPE_INVOICE) {
      const bulkResponse = yield call(api.bulkIncomeUpdateCheck, companyId, payload)
      bulkResponseData = bulkResponse.data
    }

    yield put(actions.updateIncome.success(response.data))
    yield call(resolve, {
      response: response.data,
      bulkResponse: bulkResponseData,
    })
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield put(actions.updateIncome.failure())
    yield call(reject, formErrors)
  }
}

// recommendation
function* updateIncomeWithRecommendationSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.updateIncomeWithRecommendation, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// create invoice by form
function* createIncomeSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    const { file, ...data } = payload
    // TODO create manual form part
    const createResponse = yield call(api.createIncome, company_id, data)
    let response = createResponse
    // TODO update with file when needed
    if (file) {
      const fileUploadResponse = yield call(api.uploadIncomeArtifact, company_id, { invoice: response.data.id, file })
      response = fileUploadResponse
    }
    // resolve
    yield put(actions.createIncome.success(response.data))
    yield call(resolve, { response: response.data })
    // --amplitude tracking start
    const incomeTypes = yield select(getIncomeTypes)
    const incomeType = getIncomeTypeText(data, incomeTypes)
    yield call(sendAmplitudeData, AMPLITUDE_EVENTS.NEW_INCOME_CREATED, {
      from_scan: false,
      type: incomeType,
    })
    // --amplitude tracking end
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield put(actions.createIncome.failure())
    yield call(reject, formErrors)
  }
}

// upload invoice artifact
function* uploadIncomeArtifactSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    const response = yield call(api.uploadIncomeArtifact, company_id, payload)
    yield put(actions.uploadIncomeArtifact.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.uploadIncomeArtifact.failure())
    yield call(reject, errorMsg)
  }
}

function* removeIncomeSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    yield call(api.removeIncome, company_id, payload)
    yield put(actions.removeIncome.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.removeIncome.failure())
    yield call(reject, errorMsg)
  }
}
//* v2
function* bulkDeleteV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getIncomeListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.DELETE_INCOMES, payload, filters)
    const response = yield call(api.incomeBackgroundAction, companyId, apiPayload)
    // every selected items are removed clear selection
    if (response.data.status === JOB_STATUS_FINISHED) {
      // only trigger selection clear when no rejected items
      yield put(actions.bulkDeleteV2.success())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// user viewed new invoice
function* userViewIncomeSaga({ payload }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    yield call(api.userViewIncome, company_id, payload)
  } catch (error) {
    // do nothing
  }
}

// DOWNLOAD
function* initDownloadV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getIncomeListFiltersFromStore)
    const response = yield call(api.initDownloadV2, companyId, { ...payload, ...filters })
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

//* v2
function* startBulkDownloadV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getIncomeListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(
      BackgroundProcessActions.DOWNLOAD_INCOMES,
      payload,
      filters
    )
    const response = yield call(DownloadBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkDownloadV2Saga() {
  yield call(DownloadBackgroundAction.stop)
}

function* startBulkExportV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getIncomeListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.EXPORT_INCOMES, payload, filters)
    const response = yield call(ExportBackgroundAction.start, companyId, apiPayload)
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* stopBulkExportV2Saga() {
  yield call(ExportBackgroundAction.stop)
}

//* ARTIFACTS
// download income artifact
function* downloadIncomeArtifactSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    const response = yield call(api.downloadIncomeArtifact, company_id, payload)
    let response2 = response
    if (payload.include_attachments) {
      response2 = yield call(BackgroundDownloadEmailProcess.start, {
        id: response.data.id,
        company_id,
      })
    }
    // download file when it not resolved by send-in-email cancel
    if (!response2.data.send_email) {
      yield call(downloadFileWithURL, response2) // download file
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* abortDownloadIncomeArtifact() {
  yield call(BackgroundDownloadEmailProcess.stop)
}

// remove invoice artifact
function* removeIncomeArtifactSaga({ payload, meta: { resolve, reject } }) {
  try {
    const company_id = yield select(getActiveCompanyId)
    yield call(api.removeIncomeArtifact, company_id, payload)
    yield put(actions.removeIncomeArtifact.success(payload))
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.removeIncomeArtifact.failure())
    yield call(reject, errorMsg)
  }
}

//* INCOME TYPES
// normal list
function* fetchRevenueTypesSaga({ payload }) {
  try {
    const response = yield call(api.fetchRevenueTypes, payload) // payload == company_id
    yield put(actions.fetchRevenueTypes.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchRevenueTypes.failure(errorMsg))
  }
}

function* createRevenueTypeSaga({ payload, meta: { resolve, reject } }) {
  try {
    const response = yield call(api.createRevenueType, payload) // company exists in payload
    yield put(actions.createRevenueType.success(response.data))
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// CATEGORIZATION
//* v2
function* bulkCategorizationV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(api.bulkCategorization, companyId, payload)
    yield put(actions.bulkCategorizationV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// BULK TAGGING
//* v2
function* bulkTaggingV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    yield call(bulkTaggingApi, companyId, payload)
    yield put(actions.bulkTaggingV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

// CUSTOM VALIDATOR
function* invoiceNumberCheckSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.invoiceNumberCheck, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* incomeRegisterDownloadSaga({ payload, meta: { resolve, reject } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const response = yield call(api.incomeRegisterDownload, companyId, payload)
    yield call(downloadFileFromAPIHeaders, response)
    yield call(resolve)
  } catch (error) {
    const errorMsg = yield getErrorMessageFromBlob(error)
    yield call(reject, errorMsg)
  }
}

function* initIncomeListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const [revenueTypesResponse, tagsResponse] = yield all([
      call(api.fetchRevenueTypes, companyId),
      call(fetchTagsApi, companyId),
    ])
    // gather revenue types and tags as we're going to need them for mapping purposes for the list
    yield put(actions.fetchRevenueTypes.success(revenueTypesResponse.data))
    yield put(dashboardActions.fetchTags.success(tagsResponse.data))

    const filterOptions = yield select(getUrlFilterOptionsFromStore, filtersStateKey)

    //* >> sync from url to store: call worker
    const { filters, params, validationLevel } = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initIncomeListFiltersFromUrl.request({ filters })),
      put(filtersActions.initIncomeListParamsFromUrl.request(params)),
    ])

    yield call(resolve, validationLevel)
    yield all([put(actions.fetchIncomeListV2.request({ navigate })), put(actions.fetchIncomeCharts.request())])
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
    yield put(actions.fetchIncomeListV2.failure(errorMsg))
  }
}

//* V2
function* fetchIncomeListV2Saga({ payload: { navigate } }) {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getIncomeListFiltersFromStore, { withCursor: true })
    const { tags, revenueTypes } = yield select(({ dashboard: { tags }, income: { revenueTypes } }) => ({
      tags: tags.data,
      revenueTypes: revenueTypes.data,
    }))
    // start fetching incomes
    let listResponse = yield call(api.fetchIncomeListV2, companyId, filters)

    // drop cursor and refetch list when actual cursor return zero results
    const isCursorDropped = filters.cursor && listResponse.data.results.length === 0
    let previousCursor = null
    if (isCursorDropped) {
      if (listResponse.data.previous) {
        // when previous list is exists
        previousCursor = getCursorFromUrl(listResponse.data.previous)
        listResponse = yield call(api.fetchIncomeListByPagingV2, listResponse.data.previous)
      } else {
        // clear cursor when no previous list
        const { cursor, ...newFilters } = filters
        listResponse = yield call(api.fetchIncomeListV2, companyId, newFilters)
      }
    }

    //* call worker
    const workerResults = yield call(incomeListWorkerRunner, {
      revenues: listResponse.data.results,
      tags,
      revenueTypes,
    })

    yield put(
      actions.fetchIncomeListV2.success({
        data: workerResults,
        isCursorDropped,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
        previousCursor,
      })
    )
    //* << sync from store to url
    const storedFilters = yield select(getIncomeListFiltersFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchIncomeListV2.failure(errorMsg))
  }
}

function* fetchIncomeListByPagingV2Saga({ payload: { url } }) {
  try {
    const { tags, revenueTypes } = yield select(({ dashboard: { tags }, income: { revenueTypes } }) => ({
      tags: tags.data,
      revenueTypes: revenueTypes.data,
    }))
    const listResponse = yield call(api.fetchIncomeListByPagingV2, url)

    //* call worker
    const workerResults = yield call(incomeListWorkerRunner, {
      revenues: listResponse.data.results,
      tags,
      revenueTypes,
    })

    yield put(
      actions.fetchIncomeListByPagingV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchIncomeListByPagingV2.failure(errorMsg))
  }
}

// handle paging on details with v2 data
function* fetchIncomeDetailsByPagingV2Saga({ payload, meta: { resolve, reject } }) {
  try {
    const listResponse = yield call(api.fetchIncomeListByPagingV2, payload.url)

    // skip worker because do not need to map these values here, do not show this list for user
    const results = yield call(
      incomes =>
        incomes.map(({ revenueTypeIds, tagIds, simpleTagIds, ...income }) => ({
          ...income,
          revenueTypes: [],
          simpleTags: [],
          tags: [],
        })),
      listResponse.data.results
    )

    yield put(
      actions.fetchIncomeListByPagingV2.success({
        data: results,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
    // return the id of the next income to fetch
    const nextId = payload.isNext ? results[0].id : results[results.length - 1].id
    yield call(resolve, nextId)
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    const errorResponseData = error?.response?.data || {}
    yield call(reject, { data: errorResponseData, msg: errorMsg })
  }
}

//* When we do not need to fetch revenue types and tags separately from the backend, only the list data (ie. when reordering)
//! Note: we need to think about how to handle multiple users' background changes, if a new tag/revenue type is created while the current user does not have it
function* fetchIncomeListV2SimpleSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getIncomeListFiltersFromStore)
    const { tags, revenueTypes } = yield select(({ dashboard: { tags }, income: { revenueTypes } }) => ({
      tags: tags.data,
      revenueTypes: revenueTypes.data,
    }))

    const listResponse = yield call(api.fetchIncomeListV2, companyId, filters)
    const workerResults = yield call(incomeListWorkerRunner, {
      revenues: listResponse.data.results,
      tags,
      revenueTypes,
    })

    yield put(
      actions.fetchIncomeListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchIncomeListV2.failure(errorMsg))
  }
}

function* fetchIncomeChartsSaga() {
  try {
    const companyId = yield select(getActiveCompanyId)
    const filters = yield select(getIncomeListFiltersFromStore)

    // start fetching expenses
    const listResponse = yield call(api.fetchIncomeCharts, companyId, filters)
    yield put(actions.fetchIncomeCharts.success(listResponse.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error)
    yield put(actions.fetchIncomeCharts.failure(errorMsg))
  }
}

// watcher Saga
export default function* commonSaga() {
  // list page controller init
  yield takeLatest(actions.initIncomeListPageLoad.REQUEST, initIncomeListPageLoadSaga)
  //* income types
  yield takeLatest(actions.fetchRevenueTypes.REQUEST, fetchRevenueTypesSaga)
  yield takeLatest(actions.createRevenueType.REQUEST, createRevenueTypeSaga)
  //* income details
  yield takeLatest(actions.fetchIncomeDetails.REQUEST, fetchIncomeDetailsSaga)
  yield takeLatest(actions.updateIncome.REQUEST, updateIncomeSaga)
  yield takeLatest(actions.uploadIncomeArtifact.REQUEST, uploadIncomeArtifactSaga)
  yield takeLatest(actions.createIncome.REQUEST, createIncomeSaga)
  yield takeLatest(actions.userViewIncome.REQUEST, userViewIncomeSaga)
  yield takeLatest(actions.removeIncome.REQUEST, removeIncomeSaga)
  yield takeLatest(actions.removeIncomeArtifact.REQUEST, removeIncomeArtifactSaga)
  // recommendation
  yield takeLatest(actions.updateIncomeWithRecommendation.REQUEST, updateIncomeWithRecommendationSaga)
  yield takeLatest(actions.downloadIncomeArtifact.REQUEST, downloadIncomeArtifactSaga)
  yield takeLatest(actions.abortDownloadIncomeArtifact.REQUEST, abortDownloadIncomeArtifact)
  // validation engine - custom validator
  yield takeLatest(actions.invoiceNumberCheck.REQUEST, invoiceNumberCheckSaga)
  yield takeLatest(actions.incomeRegisterDownload.REQUEST, incomeRegisterDownloadSaga)
  //* V2 service
  yield takeLatest(actions.fetchIncomeListV2.REQUEST, fetchIncomeListV2Saga)
  yield takeLatest(actions.fetchIncomeListByPagingV2.REQUEST, fetchIncomeListByPagingV2Saga)
  yield takeLatest(actions.fetchIncomeDetailsByPagingV2.REQUEST, fetchIncomeDetailsByPagingV2Saga)
  yield takeLatest(
    [
      actions.updateOrderV2.REQUEST,
      actions.updateRowsPerPageV2.REQUEST,
      filtersActions.toggleIncomeListDateFilter.REQUEST,
      filtersActions.updateIncomeListFilters.REQUEST,
      filtersActions.resetIncomeListFilters.REQUEST,
      actions.bulkTaggingV2.SUCCESS,
      actions.bulkCategorizationV2.SUCCESS,
      actions.bulkDeleteV2.SUCCESS,
      actions.triggerIncomeListUpdate.REQUEST,
      actions.resetPagination.REQUEST,
    ],
    fetchIncomeListV2SimpleSaga
  )
  yield takeLatest(
    [
      filtersActions.toggleIncomeListDateFilter.REQUEST,
      filtersActions.updateIncomeListFilters.REQUEST,
      filtersActions.resetIncomeListFilters.REQUEST,
      actions.fetchIncomeCharts.REQUEST,
      actions.bulkTaggingV2.SUCCESS,
      actions.bulkCategorizationV2.SUCCESS,
      actions.bulkDeleteV2.SUCCESS,
      actions.triggerIncomeListUpdate.REQUEST,
    ],
    fetchIncomeChartsSaga
  )
  // tagging v2
  yield takeLatest(actions.bulkTaggingV2.REQUEST, bulkTaggingV2Saga)
  // categorization v2
  yield takeLatest(actions.bulkCategorizationV2.REQUEST, bulkCategorizationV2Saga)
  // export v2
  yield takeLatest(actions.startBulkExportV2.REQUEST, startBulkExportV2Saga)
  yield takeLatest(actions.stopBulkExportV2.REQUEST, stopBulkExportV2Saga)
  // delete v2
  yield takeLatest(actions.bulkDeleteV2.REQUEST, bulkDeleteV2Saga)
  // download v2
  yield takeLatest(actions.initDownloadV2.REQUEST, initDownloadV2Saga)
  yield takeLatest(actions.startBulkDownloadV2.REQUEST, startBulkDownloadV2Saga)
  yield takeLatest(actions.stopBulkDownloadV2.REQUEST, stopBulkDownloadV2Saga)
}
