import Qs from 'qs'

import { callUrl } from '@services/common/api'

import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import { V2_API_TAX_BACKGROUND_ACTION_URL, V2_API_TAX_LIST_URL } from './urls'

export function fetchTaxListV2(companyId: number, params: Partial<TaxListStoreFilters>) {
  return Api.get<BackendPaginatedListResponse<BackendTaxListResult>>(
    injectCompanyToURL(V2_API_TAX_LIST_URL, companyId),
    {
      params,
      paramsSerializer: {
        serialize(params) {
          return Qs.stringify(params, { indices: false })
        },
      },
    }
  )
}

export function taxBackgroundAction(companyId: number, payload: unknown) {
  return Api.post<BackgroundActionResponse>(injectCompanyToURL(V2_API_TAX_BACKGROUND_ACTION_URL, companyId), payload)
}

export function fetchTaxListByPagingV2(url: string) {
  return callUrl({ method: 'get', url })
}
