import React from 'react'
import PropTypes from 'prop-types'

import { CompanyIntegrationProviders } from '@constants'

import acountoSrc from '@assets/img/acounto.svg'
import billingoSrc from '@assets/img/billingo.svg'
import billingoColoredSrc from '@assets/img/billingo_colored.svg'
import payeeColoredSrc from '@assets/img/payee.svg'
import payeeSrc from '@assets/img/payee_white.svg'
import szamlazzhuSrc from '@assets/img/szlahu.svg'
import szamlazzhuOrangeSrc from '@assets/img/szlahu_orange.svg'

function useIntegrationLogo(integration: CompanyIntegrationProviders, white?: boolean) {
  switch (integration) {
    case CompanyIntegrationProviders.szamlazz: {
      const name = 'SZÁMLÁZZ.HU'
      if (white) {
        return { name, src: szamlazzhuSrc }
      }
      return { name, src: szamlazzhuOrangeSrc }
    }
    case CompanyIntegrationProviders.billingo: {
      const name = 'Billingo'
      if (white) {
        return { name, src: billingoSrc }
      }
      return { name, src: billingoColoredSrc }
    }
    case CompanyIntegrationProviders.payee: {
      const name = 'Payee'
      if (white) {
        return { name, src: payeeSrc }
      }
      return { name, src: payeeColoredSrc }
    }
    case CompanyIntegrationProviders.acounto: {
      return { name: 'Acounto', src: acountoSrc }
    }
    // temp ignore vasfala
    default:
      return null
  }
}

interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  white?: boolean
  integration: CompanyIntegrationProviders
}

export function IntegrationLogo({ white = false, integration, ...rest }: LogoProps) {
  const logo = useIntegrationLogo(integration, white)

  if (!logo) {
    return null
  }

  return <img src={logo.src} alt={logo.name} {...rest} />
}

IntegrationLogo.propTypes = {
  integration: PropTypes.oneOf(Object.values(CompanyIntegrationProviders)).isRequired,
  white: PropTypes.bool,
}
