import React from 'react'
import PropTypes from 'prop-types'

import { FormHelperText, Typography } from '@material-ui/core'
import { useField } from 'react-final-form'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { liquidityActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { LiquidityDatepicker } from '@oldComponents/LiquidityDatepicker'

interface PaymentDatePickerProps {
  invoice: ItemIdType
  value: string
  callUpdateInvoice: AsyncFunction<{ id: ItemIdType; planned_payment_date: string }, { planned_payment_date: string }>
}

function PurePaymentDatePicker({ invoice, value: valueProp, callUpdateInvoice }: PaymentDatePickerProps) {
  const [{ value, error }, setState] = React.useState<{ error: Nullable<string>; value: string }>({
    error: null,
    value: valueProp,
  })

  function handleOpen() {
    setState(state => ({ ...state, error: null }))
  }

  async function handleChange(planned_payment_date: string) {
    try {
      // TODO cancelable promise
      const response = await callUpdateInvoice({ id: invoice, planned_payment_date })
      setState(state => ({ ...state, value: response.planned_payment_date }))
    } catch (error) {
      setState(state => ({ ...state, error: error as string }))
    }
  }

  const buttonContent = (
    <FormattedMessage
      id="invoiceDetailsForm.paymentDate.extraText"
      defaultMessage="Tervezett fizetés: {plannedDate}"
      values={{ plannedDate: <FormattedDate value={value} /> }}
    />
  )

  return (
    <>
      <LiquidityDatepicker
        value={value}
        onChange={handleChange}
        ButtonProps={{
          children: buttonContent,
          style: { fontSize: 'inherit' },
        }}
        onOpen={handleOpen}
      />
      {error && (
        <Typography color="error" variant="caption">
          {error}
        </Typography>
      )}
    </>
  )
}

PurePaymentDatePicker.propTypes = {
  invoice: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  value: PropTypes.string.isRequired,
  callUpdateInvoice: PropTypes.func.isRequired,
}

const PaymentDatePicker = connect(null, dispatch => ({
  callUpdateInvoice: bindActionToPromise(dispatch, liquidityActions.updateInvoicePlannedPaymentDate.request),
}))(PurePaymentDatePicker)

PaymentDatePicker.displayName = 'PaymentDatePicker'

interface PaymentDatePickerExtraTextProps {
  className?: string
  invoice: Pick<Partial<ExpenseDetailsFrontendValues>, 'id' | 'planned_payment_date'>
}

function PurePaymentDatePickerExtraText({
  className,
  invoice: { id, planned_payment_date },
}: PaymentDatePickerExtraTextProps) {
  const {
    input: { value: formDueAt },
  } = useField<string>('due_at', { subscription: { value: true } })

  if (!id || !planned_payment_date || formDueAt === planned_payment_date) {
    return null
  }

  return (
    <FormHelperText component="div" className={className}>
      <PaymentDatePicker invoice={id} value={planned_payment_date} />
    </FormHelperText>
  )
}

PurePaymentDatePickerExtraText.propTypes = {
  className: PropTypes.string,
  invoice: PropTypes.shape({
    id: PropTypes.number,
    planned_payment_date: PropTypes.string,
  }).isRequired as React.Validator<PaymentDatePickerExtraTextProps['invoice']>,
}

export const PaymentDatePickerExtraText = connect((state: Store) => ({
  invoice: state.expense.details.data,
}))(PurePaymentDatePickerExtraText)

PaymentDatePickerExtraText.displayName = 'PaymentDatePickerExtraText'
