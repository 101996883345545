import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { defineMessages, useIntl } from 'react-intl'

import { AsyncButtonWithIcon, Button, ButtonWithIconProps } from '@components/ui/Buttons'

import { DetailsResetMessage, FormResetConfirmDialog } from './elements'

import { ButtonsContainerDiv } from './styles'

const messages = defineMessages({
  saveButtonText: {
    id: 'form.button.save',
    defaultMessage: 'Mentés',
  },
  savedButtonText: {
    id: 'form.button.saved',
    defaultMessage: 'Elmentve',
  },
})

interface ReactHookFormSubmitButtonProps<InitialValues extends object>
  extends Omit<ButtonWithIconProps, 'children' | 'type' | 'variant'> {
  buttonText?: StringOrMessage
  confirmDialogMessage?: StringOrMessage
  hasDisabledState?: boolean
  initialValues?: InitialValues
  isCreateOnly?: boolean
  lastUpdated?: Nullable<string>
  needConfirmOnReset?: boolean
  variant?: 'primaryText' | 'primaryContained'
}

export function ReactHookFormSubmitButton<InitialValues extends object>({
  buttonText: customButtonText,
  className,
  confirmDialogMessage,
  disabled = false,
  hasDisabledState = false,
  initialValues,
  isCreateOnly = false,
  lastUpdated,
  needConfirmOnReset = false,
  variant = 'primaryContained',
  ...rest
}: ReactHookFormSubmitButtonProps<InitialValues>) {
  const [open, setOpen] = React.useState(false)
  const { formatMessage, formatDate, formatTime } = useIntl()
  const {
    reset,
    formState: { isDirty, isSubmitting },
  } = useFormContext()

  function handleResetRequest() {
    if (needConfirmOnReset) {
      setOpen(true)
    } else {
      handleReset()
    }
  }

  function handleClose() {
    setOpen(false)
  }

  function handleReset() {
    reset(initialValues)
    if (open) {
      setOpen(false)
    }
  }

  // default save
  let buttonText = formatMessage(messages.saveButtonText)
  if (!isCreateOnly && !isDirty) {
    // saved
    buttonText = formatMessage(messages.savedButtonText)
    if (lastUpdated) {
      // saved with date and time stamp
      buttonText = `${formatMessage(messages.savedButtonText)}: ${formatDate(lastUpdated)} ${formatTime(lastUpdated)}`
    }
  }

  return (
    <ButtonsContainerDiv className={className}>
      {!isCreateOnly && isDirty && initialValues && (
        <>
          <Button variant="primaryText" onClick={handleResetRequest}>
            {DetailsResetMessage}
          </Button>
          <FormResetConfirmDialog
            descriptionMessage={confirmDialogMessage}
            open={open}
            onClose={handleClose}
            onConfirm={handleReset}
          />
        </>
      )}
      <AsyncButtonWithIcon
        {...rest}
        disabled={disabled || isSubmitting || (hasDisabledState && !isDirty)}
        loading={isSubmitting}
        type="submit"
        variant={hasDisabledState ? (isDirty ? variant : 'secondaryContained') : variant}
      >
        {customButtonText || buttonText}
      </AsyncButtonWithIcon>
    </ButtonsContainerDiv>
  )
}

ReactHookFormSubmitButton.propTypes = {
  buttonText: PropTypes.node,
  className: PropTypes.string,
  confirmDialogMessage: PropTypes.node,
  disabled: PropTypes.bool,
  hasDisabledState: PropTypes.bool,
  initialValues: PropTypes.object,
  isCreateOnly: PropTypes.bool,
  lastUpdated: PropTypes.string,
  needConfirmOnReset: PropTypes.bool,
}
