import React from 'react'
import { number, oneOfType, string } from 'prop-types'

const QuickSourceIcon = ({ color, size }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M11.7768 17.5101C11.2516 17.6839 10.639 17.7708 10.0263 17.7708C7.48797 17.7708 6 16.2936 6 13.5998V5.17098C6.08753 2.47722 7.48797 1 10.1138 1C12.7396 1 14.14 2.47722 14.14 5.17098V13.5998C14.14 14.7295 13.8775 15.5984 13.4398 16.2936C13.6149 16.6412 13.965 16.6412 14.4026 16.6412H14.8403V18.9874H14.0525C13.0022 19.0743 12.1269 18.7267 11.7768 17.5101ZM11.4267 13.7736V4.99719C11.4267 3.78066 10.9015 3.34618 10.0263 3.34618C9.15099 3.34618 8.71335 3.78066 8.71335 4.99719V13.7736C8.71335 14.9902 9.23851 15.4247 10.1138 15.4247C10.9891 15.4247 11.4267 14.9902 11.4267 13.7736Z"
      fill={color}
    />
  </svg>
)

QuickSourceIcon.defaultProps = {
  color: '#4F4F4F',
  size: 18,
}

QuickSourceIcon.propTypes = {
  color: string,
  size: oneOfType([number, string]),
}
export default QuickSourceIcon
