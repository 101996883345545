import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Select, { ActionMeta, GroupBase, OnChangeValue, SelectInstance } from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { isFieldHighlighted } from '@helpers'

import { usePromptTextCreator } from '@hooks'

import { Typography } from '@components/ui/Typography'
import { WindowedMenuList } from '@oldComponents/ui'

import { CustomReactSelectOption, CustomReactSelectOptionProps } from './elements'
import { getSelectableOptions, orderSelectOptions } from './helpers'
import { ReactHookFormTextFieldProps } from './ReactHookFormTextField'
import { useIsValidNewOption } from './useIsValidNewOption'

import { formStyles, selectFormStyles } from '@styles'
import { formSelectMessages } from '@messages'

const useStyles = makeStyles(formStyles)

type DefaultOption = Record<string, any> & { isExist?: boolean }

type Keys = keyof DefaultOption
interface ReactHookFormSelectFieldProps<Option extends DefaultOption>
  extends Omit<ReactHookFormTextFieldProps, 'label'> {
  highlighted?: boolean
  isClearable?: boolean
  isLoading?: boolean
  isMulti?: boolean
  label?: React.ReactNode
  labelKey?: Keys
  menuShouldBlockScroll?: boolean
  onCreate?: (payload: Option, selectRef: SelectInstance<Option, boolean, GroupBase<Option>>) => void
  onEmptyCreateText?: string
  options: Option[]
  selectVariant?: 'blue' | 'purple'
  skipSorting?: boolean
  valueKey?: Keys
  OptionComponent?: (props: CustomReactSelectOptionProps<Option>) => JSX.Element
}

export function ReactHookFormSelectField<Option extends DefaultOption>({
  name,
  className,
  label,
  required,
  disabled = false,
  isClearable = false,
  isLoading = false,
  valueKey = 'id',
  labelKey = 'name',
  isMulti = false,
  options,
  onCreate,
  placeholder: placeholderProps,
  // recommendation, // TODO later need
  selectVariant = 'blue',
  skipSorting = false,
  highlighted = false,
  menuShouldBlockScroll = false,
  onEmptyCreateText,
  OptionComponent = CustomReactSelectOption,
}: ReactHookFormSelectFieldProps<Option>) {
  const { formatMessage } = useIntl()
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles()
  const selectRef = React.useRef<SelectInstance<Option, boolean, GroupBase<Option>>>(null)

  const promptTextCreator = usePromptTextCreator()

  const isValidNewOption = useIsValidNewOption(labelKey)

  const isCreatable = Boolean(onCreate)
  const isFieldDisabled = disabled || isSubmitting
  const SelectComponent: CreatableSelect | Select = isCreatable ? CreatableSelect : Select

  //* customize react-select components
  const componentOverridesProp: any = {
    components: {
      Option: OptionComponent,
    },
  }

  // use react-window when has more than 9 options
  if (options.length > 9) {
    componentOverridesProp.components = {
      ...componentOverridesProp.components,
      MenuList: WindowedMenuList,
    }
  }

  const selectOptions = React.useMemo(() => {
    // incasesensitive order
    const orderedOptions = orderSelectOptions(options, labelKey, skipSorting)

    // TODO later need
    // if (recommendation) {
    //   const { options: recommendations, texts } = recommendation

    //   if (recommendations.length > 0) {
    //     selectOptions = [
    //       {
    //         label: texts.recommendationsLabel,
    //         options: orderSelectOptions(
    //           recommendations.map(value => ({
    //             [valueKey]: value.isExist ? value[valueKey] : value[labelKey],
    //             [labelKey]: value[labelKey],
    //             isExist: value.isExist,
    //           })),
    //           labelKey
    //         ),
    //       },
    //       {
    //         label: texts.optionsLabel,
    //         options: orderedOptions,
    //       },
    //     ]

    //     componentOverridesProp.components = {
    //       ...componentOverridesProp.components,
    //       Option: withTexts(texts, MyOption),
    //     }
    //   }
    // }

    return orderedOptions
  }, [labelKey, options, skipSorting])

  //* react-select getters
  const noOptionsMessage = React.useCallback(() => {
    if (isCreatable && !selectOptions.length && onEmptyCreateText) {
      return (
        <Typography size="400-xs" color="inherit" tag="span">
          {onEmptyCreateText}
        </Typography>
      )
    }
    return formatMessage(formSelectMessages.selectNoResultsText)
  }, [formatMessage, isCreatable, onEmptyCreateText, selectOptions.length])

  const getOptionLabel = React.useCallback(option => option[labelKey], [labelKey])

  const getOptionValue = React.useCallback(option => option[valueKey], [valueKey])

  const getNewOptionData = React.useCallback(
    (inputValue, optionLabel) => ({
      [valueKey]: inputValue,
      [labelKey]: optionLabel,
    }),
    [labelKey, valueKey]
  )

  const getValue = React.useCallback(
    value => {
      const selectableOptions = getSelectableOptions(options)
      if (isMulti) {
        return orderSelectOptions(
          selectableOptions.filter(option => value.includes(option[valueKey])),
          labelKey,
          skipSorting
        )
      } else {
        return selectableOptions.filter(option => option[valueKey] === value)
      }
    },
    [isMulti, labelKey, options, skipSorting, valueKey]
  )

  //* custom functions
  const handleCreateOption = React.useCallback(
    newValue => {
      if (selectRef.current) {
        onCreate?.({ [labelKey]: newValue } as Option, selectRef.current)
      }
    },
    [labelKey, onCreate, selectRef]
  )

  const handleChange = React.useCallback(
    onChange =>
      (option: OnChangeValue<Option, typeof isMulti>, { action }: ActionMeta<Option>) => {
        if (isMulti) {
          switch (action) {
            case 'select-option':
            case 'remove-value':
              onChange((option as Option[]).map(el => el[valueKey]).filter(a => a))
              break
            case 'clear':
              onChange([])
              break
            default:
              break
          }
        } else {
          switch (action) {
            case 'select-option':
              // check isExist property of selected option:
              // true: new recommendation call onCreate
              // false - existing recommendation call onChange
              // simple options has no isExist property so it will return undefined
              if (option && (option as Option)['isExist'] === false) {
                // [true, false, undefined] === false
                handleCreateOption((option as Option)[valueKey])
              } else {
                onChange((option as Option)[valueKey] ?? null)
              }
              break
            case 'remove-value':
              onChange((option as Option)[valueKey] ?? null)
              break
            case 'clear':
              onChange(null)
              break
            default:
              break
          }
        }
      },
    [handleCreateOption, isMulti, valueKey]
  )

  const placeholder =
    placeholderProps ??
    (isCreatable
      ? formatMessage(formSelectMessages.creatableSelectPlaceholder)
      : formatMessage(formSelectMessages.selectPlaceholder))

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const hasError = !!error
        return (
          <FormControl
            fullWidth
            margin="normal"
            className={cx(className, classes.selectRoot, 'form-control', {
              'form-control-error': hasError,
              [classes.autoTagSelect]: selectVariant === 'purple',
            })}
            error={hasError}
            disabled={isFieldDisabled}
            required={required}
          >
            {label && (
              <InputLabel htmlFor={name} shrink className={classes.bootstrapFormLabel}>
                {label}
              </InputLabel>
            )}
            <div className={classes.selectInput}>
              <SelectComponent
                ref={selectRef}
                className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
                classNamePrefix="react-select"
                instanceId={`rs-${name}`}
                inputId={name}
                hasError={hasError}
                isClearable={isClearable}
                isDisabled={isFieldDisabled}
                value={getValue(value)}
                onBlur={onBlur}
                onChange={handleChange(onChange)}
                options={options}
                isMulti={isMulti}
                noOptionsMessage={noOptionsMessage}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                menuPortalTarget={document.body}
                menuShouldBlockScroll={menuShouldBlockScroll}
                placeholder={placeholder}
                // only for CreatableSelect
                getNewOptionData={getNewOptionData}
                onCreateOption={handleCreateOption}
                formatCreateLabel={promptTextCreator}
                isValidNewOption={isValidNewOption}
                isLoading={isLoading}
                {...componentOverridesProp}
                // custom style
                styles={selectFormStyles}
              />
            </div>
            {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}

ReactHookFormSelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  label: PropTypes.node,
  labelKey: PropTypes.string,
  menuShouldBlockScroll: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onCreate: PropTypes.func,
  onEmptyCreateText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selectVariant: PropTypes.oneOf(['blue', 'purple']),
  skipSorting: PropTypes.bool,
  valueKey: PropTypes.string,
}
