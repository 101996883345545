import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import { ArtifactViewer } from '@components/ArtifactViewer'

import { layoutStyles } from '../styles'

const useStyles = makeStyles(layoutStyles)

interface PartnerEditorLayoutProps {
  artifact: Nullable<string>
  children: React.ReactNode
}

export function PartnerEditorLayout({ artifact, children }: PartnerEditorLayoutProps) {
  const classes = useStyles()
  return (
    <Grid container className={classes.layoutRoot}>
      <Grid item className={classes.layoutContentContainer}>
        <Grid container spacing={4} className={classes.editorRoot}>
          <Grid item xs={6} className={classes.layoutColumn}>
            <Paper className={classes.paper}>
              <Typography className={classes.columnTitle} component="div">
                <FormattedMessage
                  id="partner.editor.lastInvoiceTitle"
                  defaultMessage="Partner utoljára befogadott számlája"
                />
              </Typography>
              <div
                className={cx(classes.viewport, {
                  [classes.previewContainer]: Boolean(artifact),
                })}
              >
                {artifact ? (
                  <ArtifactViewer fileUri={artifact} hideFileControls pdfVerticalScrollByDefault />
                ) : (
                  <Grid container className={classes.emptyDemo} justifyContent="center" alignItems="center">
                    <FormattedMessage
                      id="partnerEditor.emptyDemoText"
                      defaultMessage="Nincs megjeleníthető számlakép"
                    />
                  </Grid>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6} className={cx(classes.layoutColumn, classes.alignLeft)}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

PartnerEditorLayout.propTypes = {
  artifact: PropTypes.string,
  children: PropTypes.node.isRequired,
}
