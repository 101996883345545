import React from 'react'

import { Field, FieldProps, FieldRenderProps, useField } from 'react-final-form'

import { FakeInput } from '@oldComponents/ui'

import { USE_FIELD_VALUE_CONFIG } from '@constants'

import { FeaturePermissons } from '@permissions'
import { PlanPermission } from '@permissions/Permission'

// payment_method values where form has to display paid_through field
enum PaymentMethodOptions {
  CARD = 'card',
  CASH_ON_DELIVERY = 'cod',
  TRANSFER = 'transfer',
  CASH = 'cash',
}

type ConditionalPaidThroughFieldProps = FieldProps<number, FieldRenderProps<number>>

export function ConditionalPaidThroughField(props: ConditionalPaidThroughFieldProps) {
  const {
    input: { value: paymentMethod },
  } = useField<string>('payment_method', USE_FIELD_VALUE_CONFIG)

  return paymentMethod === PaymentMethodOptions.CARD ? (
    <PlanPermission
      perform={FeaturePermissons.PAYMENT}
      yes={() => <Field {...props} />}
      no={() => <FakeInput placeholder={props.placeholder} label={props.label} />}
    />
  ) : null
}
