import React from 'react'

import { InvoiceOriginIcon } from '@components/ui/InvoiceOriginIcon'

import { INVOICE_ORIGINS } from '@constants'

import {
  BillingoOriginMessage,
  EmailOriginMessage,
  SzamlazzNAVOriginMessage,
  SzamlazzOriginMessage,
  UploadOriginMessage,
  VasfalaNAVOriginMessage,
} from '@messages'

type OriginFilterConfig = Record<InvoiceOrigin, boolean>

const DEFAULT_ORIGIN_FILTER_CONFIG: OriginFilterConfig = {
  [INVOICE_ORIGINS.SZAMLAZZ]: true,
  [INVOICE_ORIGINS.NAV]: true,
  [INVOICE_ORIGINS.BILLINGO]: true,
  [INVOICE_ORIGINS.VASFALA]: true,
  [INVOICE_ORIGINS.EMAIL]: true,
  [INVOICE_ORIGINS.UPLOAD]: true,
}

export function getOriginFilters(config: Partial<OriginFilterConfig> = DEFAULT_ORIGIN_FILTER_CONFIG) {
  const options = []

  if (config[INVOICE_ORIGINS.SZAMLAZZ] ?? DEFAULT_ORIGIN_FILTER_CONFIG[INVOICE_ORIGINS.SZAMLAZZ]) {
    options.push({
      value: INVOICE_ORIGINS.SZAMLAZZ,
      label: SzamlazzOriginMessage,
      icon: <InvoiceOriginIcon origin={INVOICE_ORIGINS.SZAMLAZZ} />,
    })
  }

  if (config[INVOICE_ORIGINS.NAV] ?? DEFAULT_ORIGIN_FILTER_CONFIG[INVOICE_ORIGINS.NAV]) {
    options.push({
      value: INVOICE_ORIGINS.NAV,
      label: SzamlazzNAVOriginMessage,
      icon: <InvoiceOriginIcon origin={INVOICE_ORIGINS.NAV} />,
    })
  }

  if (config[INVOICE_ORIGINS.VASFALA] ?? DEFAULT_ORIGIN_FILTER_CONFIG) {
    options.push({
      value: INVOICE_ORIGINS.VASFALA,
      label: VasfalaNAVOriginMessage,
      icon: <InvoiceOriginIcon origin={INVOICE_ORIGINS.VASFALA} />,
    })
  }

  if (config[INVOICE_ORIGINS.BILLINGO] ?? DEFAULT_ORIGIN_FILTER_CONFIG[INVOICE_ORIGINS.BILLINGO]) {
    options.push({
      value: INVOICE_ORIGINS.BILLINGO,
      label: BillingoOriginMessage,
      icon: <InvoiceOriginIcon origin={INVOICE_ORIGINS.BILLINGO} />,
    })
  }

  if (config[INVOICE_ORIGINS.EMAIL] ?? DEFAULT_ORIGIN_FILTER_CONFIG[INVOICE_ORIGINS.EMAIL]) {
    options.push({
      value: INVOICE_ORIGINS.EMAIL,
      label: EmailOriginMessage,
      icon: <InvoiceOriginIcon origin={INVOICE_ORIGINS.EMAIL} />,
    })
  }

  if (config[INVOICE_ORIGINS.UPLOAD] ?? DEFAULT_ORIGIN_FILTER_CONFIG[INVOICE_ORIGINS.UPLOAD]) {
    options.push({
      value: INVOICE_ORIGINS.UPLOAD,
      label: UploadOriginMessage,
      icon: <InvoiceOriginIcon origin={INVOICE_ORIGINS.UPLOAD} />,
    })
  }
  return options
}
