import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Typography } from '@components/ui'

import { getSourceName } from './helper'
import { NavHeader } from './NavHeader'

import { ReasonBlockWrapperDiv } from './styles'

interface NavPreviewBlockProps {
  origin: InvoiceOrigin
  hasArtifact: boolean
}

export function NavPreviewBlock({ origin, hasArtifact }: NavPreviewBlockProps) {
  return (
    <ReasonBlockWrapperDiv>
      <NavHeader origin={origin} />
      <Typography align="center">
        {hasArtifact ? (
          <FormattedMessage
            id="invoice.nav.heading.withArtifact"
            defaultMessage="A számla adatait a {source} importálta a NAV rendszerén keresztül."
            values={{ source: getSourceName(origin) }}
          />
        ) : (
          <FormattedMessage
            id="invoice.nav.heading.noArtifact"
            defaultMessage="A számla adatait a {source} importálta a NAV rendszerén keresztül. Hogy kényelmesebb legyen az adatok olvasása, számlaképet generáltunk hozzá. Ha szeretnéd a tényleges számlát csatolni helyette, a bal oldalon megteheted."
            values={{ source: getSourceName(origin) }}
          />
        )}
      </Typography>
    </ReasonBlockWrapperDiv>
  )
}
