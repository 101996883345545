import React from 'react'

import { useIntl } from 'react-intl'

import { StrongTextValues } from '@helpers'

import { useCreatePayment } from '@contexts/PaymentProvider'

import { CreditCardRegularIcon } from '@components/ui'
import { QuickIconButton } from '@oldComponents/ui/buttons'

import { PAYMENT_FLOW } from '@constants'

import { FeaturePermissons, PlanPermission } from '@permissions'

import { listPageMessages } from '@messages'

interface CreateTransferPaymentIconButtonProps {
  disabled: boolean
}

export function CreateTransferPaymentIconButton({ disabled }: CreateTransferPaymentIconButtonProps) {
  const [open, setOpen] = React.useState(false)
  const { formatMessage } = useIntl()
  const {
    startTransferPayment,
    state: { loading, flow },
  } = useCreatePayment()

  function handleClick() {
    setOpen(false)
    startTransferPayment()
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <PlanPermission
      perform={FeaturePermissons.PAYMENT}
      yes={() => (
        <QuickIconButton
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          onClick={handleClick}
          disabled={disabled || loading}
          loading={loading && flow === PAYMENT_FLOW.transfer}
          icon={<CreditCardRegularIcon size={24} />}
          size="medium"
          tooltipText={formatMessage(listPageMessages.createPayment)}
        />
      )}
      no={() => (
        <QuickIconButton
          disabled
          icon={<CreditCardRegularIcon size={24} />}
          size="medium"
          tooltipText={formatMessage(listPageMessages.createPaymentUpsell, StrongTextValues)}
        />
      )}
    />
  )
}
