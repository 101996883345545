import React from 'react'
import PropTypes from 'prop-types'

import { InvoiceType } from '@constants'

import { VAT_SUGGESTIONS } from './constants'
import { LedgerNumberSelect, LedgerNumberSelectOwnProps, propTypes } from './LedgerNumberSelect'

interface VatLedgerNumberSelectProps extends Omit<LedgerNumberSelectOwnProps, 'suggestion' | 'recommendations'> {
  invoiceType: InvoiceType
}

export function VatLedgerNumberSelect({ invoiceType, ...rest }: VatLedgerNumberSelectProps) {
  return <LedgerNumberSelect {...rest} suggestion={VAT_SUGGESTIONS[invoiceType]} />
}

VatLedgerNumberSelect.propTypes = {
  ...propTypes,
  invoiceType: PropTypes.oneOf(Object.values(InvoiceType)).isRequired as React.Validator<InvoiceType>,
}
