import React from 'react'
import PropTypes from 'prop-types'

import { yupResolver } from '@hookform/resolvers/yup'
import { DefaultValues, FieldValues, FormProvider, Path, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useBlocker } from 'react-router-dom'
import { AnyObjectSchema } from 'yup'

import { useReactHookFormExternalSubmit } from '@hooks'

import { SimpleDialog } from '@components/ui'

import { DIALOG_CLASS_NAMES } from '@constants'

/**
 * Typescript helper to check errors schema
 * @param errors Backend form errors schema: { field: string[]; fieldArray: [{ field: string[] }]; Error: ['non field error'] }
 * @returns boolean: True when it is a valid fieldArray schema
 */
function isFieldArray(errors: string | object[]): errors is object[] {
  return Array.isArray(errors) && typeof errors[0] !== 'string'
}

interface ReactHookFormProps<FormValues> extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  children: React.ReactNode | React.ReactNode[]
  formRef?: React.RefObject<HTMLFormElement>
  initialValues: DefaultValues<FormValues>
  onSubmit: AsyncFunction<any, any>
  onSubmitFail?: (payload: any) => void
  onSubmitSuccess?: (payload: any) => void
  preventSubmitSuccess?: VoidFunction
  skipResetAfterSuccessfulSubmit?: boolean
  skipUnsavedChanges?: boolean
  validationSchema?: AnyObjectSchema
}

export function ReactHookForm<FormValues extends FieldValues>({
  children,
  formRef,
  initialValues,
  onSubmit,
  onSubmitFail,
  onSubmitSuccess,
  preventSubmitSuccess,
  skipResetAfterSuccessfulSubmit = false,
  skipUnsavedChanges,
  validationSchema,
  ...rest
}: ReactHookFormProps<FormValues>) {
  const { formRef: hookFormRef, triggerSubmit } = useReactHookFormExternalSubmit(formRef)
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: initialValues,
    ...(validationSchema ? { resolver: yupResolver(validationSchema) } : {}),
  })

  const {
    formState: { isSubmitSuccessful, isDirty, isSubmitting },
    reset,
    trigger,
  } = methods

  // unsaved changes prompt dialog when try to navigate away
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !skipUnsavedChanges && isDirty && !isSubmitting && currentLocation.pathname !== nextLocation.pathname
  )
  const showPrompt = blocker.state === 'blocked'

  function confirmNavigation() {
    blocker.proceed?.()
  }

  function cancelNavigation() {
    blocker.reset?.()
  }

  //* NOTE reset must be in useEffect - see docs: https://react-hook-form.com/api/useform/reset
  React.useEffect(() => {
    if (!skipResetAfterSuccessfulSubmit && isSubmitSuccessful) {
      // reset form state with new initialValues
      reset(initialValues)
    }
  }, [skipResetAfterSuccessfulSubmit, isSubmitSuccessful, reset, initialValues])

  async function handleConfirmWithFormSubmit() {
    const result = await trigger()
    if (result) {
      preventSubmitSuccess?.() // TODO later remove it - when external "handleSubmitSuccess" connected to form
      triggerSubmit()
    } else {
      cancelNavigation()
    }
  }

  const formSubmitHandler = methods.handleSubmit(async values => {
    try {
      const response = await onSubmit(values)
      if (showPrompt) {
        confirmNavigation()
      } else {
        onSubmitSuccess?.(response)
      }
    } catch (errors) {
      onSubmitFail?.(errors)
      cancelNavigation()
      if (errors instanceof Error) {
        // unexpected error: error is an exception not a saga reject response
        methods.setError('_error' as Path<FormValues>, {
          type: 'unexpectedError',
          message: errors.message,
        })
      } else if (typeof errors === 'string') {
        // saga reject response as string
        methods.setError('_error' as Path<FormValues>, {
          type: 'submissionError',
          message: errors,
        })
      } else {
        // saga reject response as Record (return type of getFormErrors)
        Object.entries(errors as Record<string, string | Array<Record<string, string>>>).forEach(([field, message]) => {
          if (isFieldArray(message)) {
            message.forEach((innerField, index) => {
              Object.entries(innerField).forEach(([innerKey, innerMessage]) => {
                methods.setError(`${field}[${index}].${innerKey}` as Path<FormValues>, {
                  type: 'submissionError',
                  message: innerMessage,
                })
              })
            })
          } else {
            methods.setError(field as Path<FormValues>, {
              type: 'submissionError',
              message: message,
            })
          }
        })
      }
    }
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={formSubmitHandler} ref={hookFormRef} noValidate autoComplete="off" {...rest}>
        {children}
      </form>
      <SimpleDialog
        className={DIALOG_CLASS_NAMES.prompt} // TEMP: set minHeight of the dialog
        open={showPrompt}
        onClose={cancelNavigation}
        title={<FormattedMessage id="unsavedChanges.dialog.title" defaultMessage="Nem mentett módosítás" />}
        description={
          <FormattedMessage
            id="unsavedChanges.dialog.description"
            defaultMessage="Még nem mentetted el a módosításaidat. Mit szeretnél tenni?"
          />
        }
        onPrimaryAction={handleConfirmWithFormSubmit}
        onSecondaryAction={confirmNavigation}
        primaryActionText={
          <FormattedMessage id="unsavedChanges.dialog.primaryActionText" defaultMessage="Mentés és tovább" />
        }
        secondaryActionText={
          <FormattedMessage id="unsavedChanges.dialog.secondaryActionText" defaultMessage="Nincs mentés" />
        }
      />
    </FormProvider>
  )
}

ReactHookForm.propTypes = {
  children: PropTypes.node.isRequired,
  formRef: PropTypes.oneOfType([
    // Either a function
    PropTypes.func.isRequired,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  ]),
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  skipResetAfterSuccessfulSubmit: PropTypes.bool,
  skipUnsavedChanges: PropTypes.bool,
  validationSchema: PropTypes.object,
}
