import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { CreatePaymentFormData, CreatePaymentPayload } from '@contexts/PaymentProvider'

import {
  Button,
  ReactHookForm,
  ReactHookFormDatePickerField,
  ReactHookFormError,
  ReactHookFormSelectField,
  ReactHookFormSubmitButton,
} from '@components/ui'

import { PAYMENT_FLOW } from '@constants'

import { CreateManualOrExpressPaymentFormProps, ManualOrExpensePaymentFormValues } from '../types'
import { BalanceAllTransactionsButton } from './BalanceAllTransactionsButton'
import { ExpressPaymentSwitch } from './ExpressPaymentSwitch'
import { getToday } from './helpers'
import { FAKE_OPTION_ID } from './PaidThroughBalance'
import { PaymentHeader } from './PaymentFormElements'
import { PaymentInformationalMessage } from './PaymentInformationalMessage'
import { PaymentTransactionsFieldArrayTable } from './PaymentTransactionsFieldArrayTable'
import { useExpressValidationSchema, useManualValidationSchema } from './validators'

import { CancelButtonMessage, globalMessages, PaidThroughLabelMessage } from '@messages'
import { FormDialogBody } from '@components/FormDialog/styles'
import { DatePickerLabelMessage } from './messages'
import {
  ExpressPaymentFieldset,
  PaymentDialogActions,
  PaymentDialogBodyWrapper,
  SameSizeButtonsContainer,
  StyledTransactionsTotalAmount,
} from './styles'

function useInitialValues(
  transactions: PaymentSetupResponseData['transactions'],
  companyDefaultPaidThroughId: Nullable<number>,
  flow: PaymentFlowType,
  paidThroughOptions: PaidThroughOption[]
) {
  return React.useMemo<ManualOrExpensePaymentFormValues>(() => {
    let paidThroughId = null
    if (flow !== PAYMENT_FLOW.express) {
      if (companyDefaultPaidThroughId) {
        paidThroughId = companyDefaultPaidThroughId
      } else if (paidThroughOptions.length === 1) {
        paidThroughId = paidThroughOptions[0].id
      }
    }

    return {
      date: getToday(),
      paidThroughId: paidThroughId,
      transactions: transactions.map(({ amount, remaining, transactionId }) => ({
        amount: flow === PAYMENT_FLOW.express ? remaining : amount || '',
        transactionId,
      })),
    }
  }, [flow, transactions, companyDefaultPaidThroughId, paidThroughOptions])
}
/**
 * Create a virtual "házipénztár" option and filter out all "cash" type options
 * @param paidThroughOptions - paid through options from backend
 * @returns new options list
 */
function useManualFormPaidThroughOptions(paidThroughOptions: PaidThroughOption[]) {
  const { formatMessage } = useIntl()

  return React.useMemo(() => {
    const filteredOptions = paidThroughOptions.filter(({ paidthrough_type }) => paidthrough_type !== 1)

    const virtualManualOption: PaidThroughOption = {
      id: FAKE_OPTION_ID, // fake id
      name: formatMessage(globalMessages.pettyCash),
      paidthrough_type: 1, // cash
      provider: null,
      provider_name: null,
    }
    return [...filteredOptions, virtualManualOption]
  }, [formatMessage, paidThroughOptions])
}

export function PureCreateManualOrExpressPaymentForm({
  companyDefaultPaidThrough,
  data: { transactions },
  flow,
  setFlow,
  onClose,
  onSubmit,
  onSubmitSuccess,
  paidThroughOptions,
  showInfoRow,
  transactionType,
}: CreateManualOrExpressPaymentFormProps) {
  const isExpressPayment = flow === PAYMENT_FLOW.express
  const expressValidationSchema = useExpressValidationSchema()
  const manualValidationSchema = useManualValidationSchema()
  const initialValues = useInitialValues(transactions, companyDefaultPaidThrough, flow, paidThroughOptions)

  const manualFormlPaidThroughOptions = useManualFormPaidThroughOptions(paidThroughOptions)

  function handleFlowChange() {
    setFlow(flow === PAYMENT_FLOW.express ? PAYMENT_FLOW.manual : PAYMENT_FLOW.express)
  }

  function onSubmitHandler({ date, transactions, paidThroughId }: CreatePaymentFormData) {
    const payload: CreatePaymentPayload = {
      date,
      transactions,
      paidThroughId,
      transactionType,
      flow,
      paidThroughType: null,
    }

    if (flow === PAYMENT_FLOW.manual) {
      const paidThroughType = manualFormlPaidThroughOptions.find(({ id }) => id === paidThroughId)?.paidthrough_type
      if (!paidThroughType) {
        throw Error('Unexpected error - missing paidThroughType')
      }
      payload.paidThroughType = paidThroughType
      if (paidThroughType === 1) {
        payload.paidThroughId = null
      }
    }

    return onSubmit(payload)
  }

  const submitButtonText = isExpressPayment ? (
    <FormattedMessage id="payment.dialog.buttons.pay" defaultMessage="Fizetés" />
  ) : (
    <FormattedMessage id="payment.dialog.buttons.record" defaultMessage="Rögzítés" />
  )

  return (
    <ReactHookForm
      initialValues={initialValues}
      onSubmit={onSubmitHandler}
      onSubmitSuccess={onSubmitSuccess}
      validationSchema={isExpressPayment ? expressValidationSchema : manualValidationSchema}
    >
      <FormDialogBody>
        <PaymentDialogBodyWrapper>
          {showInfoRow && <PaymentInformationalMessage />}
          <ExpressPaymentSwitch checked={isExpressPayment} initialValues={initialValues} onChange={handleFlowChange} />
          <ExpressPaymentFieldset disabled={isExpressPayment}>
            <PaymentHeader
              button={<BalanceAllTransactionsButton transactions={transactions} />}
              datepicker={
                <ReactHookFormDatePickerField
                  name="date"
                  label={DatePickerLabelMessage}
                  required
                  disabled={isExpressPayment}
                />
              }
              paidThroughSelect={
                <ReactHookFormSelectField
                  name="paidThroughId"
                  options={manualFormlPaidThroughOptions}
                  label={PaidThroughLabelMessage}
                  valueKey="id"
                  labelKey="name"
                  required
                  isClearable={false}
                  disabled={isExpressPayment}
                  {...(isExpressPayment && { placeholder: 'Express' })}
                />
              }
            />
            <PaymentTransactionsFieldArrayTable
              disabled={isExpressPayment}
              flow={flow}
              transactions={transactions}
              transactionType={transactionType}
            />
            <StyledTransactionsTotalAmount transactions={transactions} />
          </ExpressPaymentFieldset>
        </PaymentDialogBodyWrapper>
        <PaymentDialogActions borderless>
          <ReactHookFormError />
          <SameSizeButtonsContainer>
            <Button onClick={onClose} variant="secondaryContained" type="button">
              {CancelButtonMessage}
            </Button>
            <ReactHookFormSubmitButton buttonText={submitButtonText} isCreateOnly />
          </SameSizeButtonsContainer>
        </PaymentDialogActions>
      </FormDialogBody>
    </ReactHookForm>
  )
}

export const CreateManualOrExpressPaymentForm = connect((state: Store) => ({
  companyDefaultPaidThrough: state.auth.company.data.default_paid_through,
  paidThroughOptions: state.payment.paidThroughOptions,
}))(PureCreateManualOrExpressPaymentForm)

CreateManualOrExpressPaymentForm.displayName = 'CreateManualOrExpressPaymentForm'
