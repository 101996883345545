import React from 'react'

import { Field, FieldProps, FieldRenderProps, useField } from 'react-final-form'

import { USE_FIELD_VALUE_CONFIG } from '@constants'

export function ConditionalPlannedPaymentDateField(props: FieldProps<string, FieldRenderProps<string>>) {
  const {
    input: { value: isRequired },
  } = useField<boolean>('reschedule_payment', USE_FIELD_VALUE_CONFIG)

  return isRequired ? <Field {...props} /> : null
}
