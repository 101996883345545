import React from 'react'
import PropTypes from 'prop-types'

import { get, useFormContext, useWatch } from 'react-hook-form'
import styled, { css, keyframes } from 'styled-components'

import { WarningText } from '@components/ui/WarningText'

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const StyledWarningText = styled(WarningText)<{ $withFade?: boolean }>`
  ${({ $withFade }) =>
    $withFade &&
    css`
      animation: ${fadeInAnimation} 100ms ease-in-out;
      opacity: 1;
    `}
`

interface ReactHookFormErrorProps {
  className?: string
  withFade?: boolean
  hideIcon?: boolean
}

export function ReactHookFormError({ className, withFade = false, hideIcon = false }: ReactHookFormErrorProps) {
  const {
    formState: { errors },
    clearErrors,
  } = useFormContext()
  const values = useWatch()
  const isErrorVisibleRef = React.useRef(false)
  // handle both v1 and v2 error key
  const error = get(errors, '_error') || get(errors, 'Error') // have to use getFormErrors parser in saga

  React.useEffect(() => {
    if (error) {
      isErrorVisibleRef.current = true
    }
  }, [error])

  // clear error when values change
  React.useEffect(() => {
    if (isErrorVisibleRef.current) {
      isErrorVisibleRef.current = false
      clearErrors('_error')
      clearErrors('Error')
    }
  }, [clearErrors, values])

  if (!error) {
    return null
  }

  return (
    <StyledWarningText $withFade={withFade} className={className} hideIcon={hideIcon}>
      {error.message}
    </StyledWarningText>
  )
}

ReactHookFormError.propTypes = {
  className: PropTypes.string,
  withFade: PropTypes.bool,
  hideIcon: PropTypes.bool,
}
