import React from 'react'

import { FormattedDate } from 'react-intl'
import { connect } from 'react-redux'

import { getRouteUrl } from '@helpers'

import { PreviewVariant } from '@contexts'

import { InvoiceArtifactPreviewOpener, InvoicePreviewOpener } from '@components/DocViewer'
import { SimpleTable } from '@components/tables'
import { CopyAndPasteButton, Currency, OverflowText, TooLongText } from '@components/ui'
import { ReactHookFieldArray, ReactHookFormAmountTableField } from '@components/ui/FormElements'

import { PAYMENT_FLOW, PAYMENT_TRANSACTION_TYPE, RouteKeys } from '@constants'

import { AccountNumberWarning } from './AccountNumberWarning'
import { BalanceTransactionButton } from './BalanceTransactionButton'
import { TableAccountNumberField } from './fields'
import { CreateBankPaymentTableAccessors, CreateBankPaymentTableRow, getTableColumns } from './PaymentTableElements'

function getRowId(row: CreateBankPaymentTableRow) {
  return row.rowData.transactionId
}

interface PaymentTransactionsFieldArrayTableProps {
  companyId: number
  disabled?: boolean
  flow: PaymentFlowType
  transactions: PaymentTransaction[]
  transactionType: PaymentTransactionType
}

function PurePaymentTransactionsFieldArrayTable({
  companyId,
  disabled = false,
  flow,
  transactions,
  transactionType,
}: PaymentTransactionsFieldArrayTableProps) {
  const isSecondaryIdVisible = React.useMemo(
    () => transactionType === PAYMENT_TRANSACTION_TYPE.expense && transactions.some(({ secondaryId }) => !!secondaryId),
    [transactionType, transactions]
  )

  const tableColumns = React.useMemo(() => {
    return getTableColumns({
      isSecondaryIdVisible,
      isSummary: false,
      isTransferFlow: flow === PAYMENT_FLOW.transfer,
      transactionType,
    })
  }, [flow, isSecondaryIdVisible, transactionType])

  const useTableProps = React.useMemo(() => {
    const hiddenColumns = [CreateBankPaymentTableAccessors.ROW_DATA]

    if (PAYMENT_FLOW.transfer !== flow) {
      hiddenColumns.push(CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER)
    }
    if (!isSecondaryIdVisible) {
      hiddenColumns.push(CreateBankPaymentTableAccessors.SECONDARY_ID)
    }

    return {
      initialState: {
        hiddenColumns,
      },
      getRowId,
    }
  }, [flow, isSecondaryIdVisible])

  return (
    <ReactHookFieldArray
      name="transactions"
      render={({ fields }) => (
        <SimpleTable
          columns={tableColumns}
          data={fields.map((field, index) => {
            const transaction = transactions[index]
            return {
              [CreateBankPaymentTableAccessors.ROW_DATA]: transaction,
              [CreateBankPaymentTableAccessors.PREVIEW]: (
                <>
                  {transaction.artifact && (
                    <InvoiceArtifactPreviewOpener
                      detailsUrl={`${getRouteUrl(RouteKeys.COST, companyId)}/${transaction.transactionId}`}
                      fileName={transaction.artifact.filename}
                      path={transaction.artifact.path}
                      previewVariant={PreviewVariant.INVOICE}
                    />
                  )}
                  {!transaction.artifact && transaction.links.preview && (
                    <InvoicePreviewOpener
                      detailsUrl={`${getRouteUrl(RouteKeys.COST, companyId)}/${transaction.transactionId}`}
                      invoiceId={transaction.transactionId}
                      invoiceVariant="expense"
                      previewUrl={transaction.links.preview}
                      previewVariant={PreviewVariant.INVOICE}
                    />
                  )}
                </>
              ),
              [CreateBankPaymentTableAccessors.PARTNER]: <OverflowText>{transaction.partnerName}</OverflowText>,
              [CreateBankPaymentTableAccessors.TITLE]:
                transactionType === PAYMENT_TRANSACTION_TYPE.expense ? (
                  <CopyAndPasteButton text={transaction.title} disabled={disabled}>
                    <TooLongText value={transaction.title} disablePortal maxLength={15} />
                  </CopyAndPasteButton>
                ) : (
                  <FormattedDate value={transaction.title} year="numeric" month="long" />
                ),
              [CreateBankPaymentTableAccessors.SECONDARY_ID]: transaction.secondaryId && (
                <CopyAndPasteButton text={transaction.secondaryId} disabled={disabled}>
                  <TooLongText value={transaction.secondaryId} disablePortal maxLength={15} />
                </CopyAndPasteButton>
              ),
              [CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER]: (
                <AccountNumberWarning fieldIndex={index} partnerAccountNumber={transaction.partnerAccountNumber}>
                  <TableAccountNumberField
                    key={`account_number-${field.id}`}
                    name={`transactions[${index}].accountNumber`}
                  />
                </AccountNumberWarning>
              ),
              [CreateBankPaymentTableAccessors.DUE_AT]: <FormattedDate value={transaction.dueAt} />,
              [CreateBankPaymentTableAccessors.GROSS_AMOUNT]: (
                <Currency value={Number(transaction.grossAmount)} currency={transaction.currencyName} />
              ),
              [CreateBankPaymentTableAccessors.REMAINING]: (
                <Currency value={Number(transaction.remaining)} currency={transaction.currencyName} />
              ),
              [CreateBankPaymentTableAccessors.AMOUNT]: (
                <>
                  <ReactHookFormAmountTableField
                    key={`amount-${field.id}`}
                    currency={transaction.currencyName}
                    name={`transactions[${index}].amount`}
                  />
                  <BalanceTransactionButton
                    fieldName={`transactions[${index}].amount`}
                    amount={(Number(transaction.amount) || 0) + Number(transaction.remaining)}
                  />
                </>
              ),
            }
          })}
          layout="fixed"
          useTableProps={useTableProps}
        />
      )}
    />
  )
}

export const PaymentTransactionsFieldArrayTable = connect((state: Store) => ({
  companyId: state.auth.company.data.id,
}))(PurePaymentTransactionsFieldArrayTable)

PaymentTransactionsFieldArrayTable.displayName = 'PaymentTransactionsFieldArrayTable'
