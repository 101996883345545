import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import { Button, CustomDialog, Typography } from '@components/ui'

import { CancelButtonMessage } from '@messages'
import { ListActionsDialogActions, ListActionsDialogBody, ListActionsDialogHeader } from '../styles'

interface DownloadConfirmDialogProps {
  contentMessage?: StringOrMessage
  onClose: VoidFunction
  onDownloadWithAttachments: VoidFunction
  onDownloadWithoutAttachments: VoidFunction
  open: boolean
}

export function InvoiceDownloadDialog({
  contentMessage,
  onClose,
  onDownloadWithAttachments,
  onDownloadWithoutAttachments,
  open,
}: DownloadConfirmDialogProps) {
  return (
    <CustomDialog open={open} onClose={onClose}>
      <ListActionsDialogHeader
        borderless
        title={<FormattedMessage id="dialogs.download.title" defaultMessage="Csatolmányok letöltése" />}
      />
      <ListActionsDialogBody>
        <Typography color="gray-50" align="center" size="400-md">
          {contentMessage ?? (
            <FormattedMessage
              id="dialogs.download.description"
              defaultMessage="Szeretnéd a kiválasztott számlákhoz tartozó csatolmány pdf-eket is letölteni egybefűzve a számlaképekkel?"
            />
          )}
        </Typography>
      </ListActionsDialogBody>
      <ListActionsDialogActions borderless>
        <Button variant="primaryContained" onClick={onDownloadWithAttachments}>
          <FormattedMessage id="dialogs.download.buttons.withAttachment" defaultMessage="Letöltés csatolmányokkal" />
        </Button>
        <Button onClick={onDownloadWithoutAttachments} variant="primaryText">
          <FormattedMessage id="dialogs.download.buttons.normal" defaultMessage="Letöltés csatolmányok nélkül" />
        </Button>
        <Button variant="secondaryText" onClick={onClose}>
          {CancelButtonMessage}
        </Button>
      </ListActionsDialogActions>
    </CustomDialog>
  )
}

InvoiceDownloadDialog.propTypes = {
  contentMessage: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onDownloadWithAttachments: PropTypes.func.isRequired,
  onDownloadWithoutAttachments: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
