import React from 'react'
import PropTypes from 'prop-types'

import __uniqueId from 'lodash/uniqueId'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'

import { expenseActions, quarantineActions } from '@services'

import { bindActionToPromise, cancellablePromise, getRouteUrl, parseApiErrorMessage } from '@helpers'

import { useAssertContext, useCancellablePromiseRef, useTimerRef } from '@hooks'

import { IntercomLauncherButton } from '@components/Intercom'
import { Button, CustomDialog, InfoText, ProgressButton, Typography } from '@components/ui'
import { LightTooltip, MessageText } from '@oldComponents/ui'

import { RouteKeys } from '@constants'

import {
  DeleteDuplicateExpenseResponseMessage,
  DeleteDuplicationResponseMessage,
  DeleteNavDuplicationResponseMessage,
  DeleteOriginalExpenseResponseMessage,
  DialogContent,
  DialogTitleMessage,
  DuplicationDescriptionDefaultMessage,
  DuplicationDescriptionDuplicateDeletableMessage,
  DuplicationDescriptionNotDeletableMessage,
  DuplicationDescriptionOriginalDeletableMessage,
  InitFailedResponseMessage,
  MergeWithoutUpdateResponseMessage,
  MergeWithUpdateResponseMessage,
  NavDuplicationDescriptionMessage,
} from './elements'
import { ActionTypes, DialogActions, initialState, reducer } from './reducer'
import { DuplicateExpenseContextProps, DuplicateResults } from './types'

import { CloseButtonMessage, SupportButtonMessage } from '@messages'
import {
  CloseActionContainer,
  DialogActionsInnerContainer,
  DuplicateDialogActions,
  DuplicateDialogActionsContainer,
  DuplicateDialogBody,
  DuplicateDialogHeader,
} from './styles'

const StyledInfoText = styled(InfoText)`
  align-items: center;
  max-width: 400px;
`

const DuplicateExpenseContext = React.createContext<DuplicateExpenseContextProps | undefined>(undefined)

DuplicateExpenseContext.displayName = 'DuplicateExpenseContext'

const CLOSE_TIMEOUT = 2000

const NotDeletableInfoMessage = (
  <FormattedMessage
    tagName={StyledInfoText}
    id="dialogs.expenseDuplication.buttons.oldInvoiceNotDeletableTooltip"
    defaultMessage="Ez a számla nem törölhető, mert a könyvelőd már feldolgozta vagy mert már részben vagy teljes egészében kifizetésre került."
  />
)

function getDefaultRedirectUrl({
  companyId,
  detailsViewType,
}: {
  companyId: number
  detailsViewType?: 'expense' | 'quarantine'
}) {
  switch (detailsViewType) {
    case 'quarantine':
      return getRouteUrl(RouteKeys.QUARANTINE_LIST, companyId)
    case 'expense':
      return getRouteUrl(RouteKeys.EXPENSE_LIST, companyId)
    default:
      return getRouteUrl(RouteKeys.ROOT, companyId)
  }
}

interface PureDuplicateExpenseProviderProps {
  children: React.ReactNode
  companyId: number
  deleteExpenseDuplication: AsyncFunction<{ id: number; invoice: number }>
  deleteExpenseFromList: RemoveExpenseFunction
  detailsViewType?: 'expense' | 'quarantine'
  init: AsyncFunction<{ expenseId: ItemIdType; partnerId?: number }, DuplicateResults>
  mergeInvoices: AsyncFunction<
    {
      direction: 'to_nav' | 'from_nav'
      from_id: number
      to_id: number
      update: boolean
      needToUpdateList?: 'expense' | 'quarantine'
    },
    void
  >
  needToUpdateList?: 'expense' | 'quarantine'
}

function PureDuplicateExpenseProvider({
  children,
  companyId,
  deleteExpenseDuplication,
  deleteExpenseFromList,
  init,
  detailsViewType,
  mergeInvoices,
  needToUpdateList,
}: PureDuplicateExpenseProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const timeoutRef = useTimerRef()
  const cPromiseRef = useCancellablePromiseRef()

  // ensure to have the same aria attributes and pass down to dialog body
  const ariaIdPrefix = __uniqueId('duplicate-dialog')

  const isResponseStateActive = Boolean(
    state.responseState && !state.responseState?.isError && state.responseState?.action !== DialogActions.INIT
  )

  const defaultRedirectUrl = getDefaultRedirectUrl({
    companyId,
    detailsViewType,
  })

  React.useEffect(() => {
    if (state.willRedirect) {
      timeoutRef.current = window.setTimeout(() => {
        dispatch({ type: ActionTypes.CLOSE_AND_REDIRECT })
      }, CLOSE_TIMEOUT)
    }
  }, [state.willRedirect, timeoutRef])

  //* OPEN
  const open = React.useCallback<DuplicateExpenseContextProps['open']>(
    async expenseId => {
      dispatch({ type: ActionTypes.OPEN_REQUEST })
      try {
        cPromiseRef.current = cancellablePromise(init({ expenseId }))
        const results = await cPromiseRef.current.promise

        dispatch({ type: ActionTypes.OPEN_SUCCESS, payload: { expenseId, results } })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          dispatch({
            type: ActionTypes.OPEN_FAILURE,
            payload: InitFailedResponseMessage,
          })
        }
      }
    },
    [cPromiseRef, init]
  )

  const openWithData = React.useCallback<DuplicateExpenseContextProps['openWithData']>(
    ({ results, expenseId }, clearFormInvoiceNumberField) => {
      dispatch({ type: ActionTypes.OPEN_SUCCESS, payload: { clearFormInvoiceNumberField, expenseId, results } })
    },
    []
  )

  //* CLOSE
  function close() {
    if (!state.willRedirect) {
      // only close the modal if it is not about to be redirecting the user
      dispatch({ type: ActionTypes.CLOSE })
    } else {
      // if the user wishes to leave early, trigger the redirect early
      dispatch({ type: ActionTypes.CLOSE_AND_REDIRECT })
    }
  }

  /**
   * Remove duplication record - clear invoice number on duplicated expense
   * @param payload
   * @param {number} payload.invoice - current expense ID
   * @param {number} payload.id - current duplicate_of object ID
   */
  async function callDeleteExpenseDuplication() {
    if (state.expenseId && state.data?.id) {
      const payload = {
        id: state.data.id,
        invoice: state.expenseId as number,
      }
      dispatch({ type: ActionTypes.ACTION_REQUEST, payload: DialogActions.DELETE_DUPLICATION })
      try {
        cPromiseRef.current = cancellablePromise(deleteExpenseDuplication(payload))
        await cPromiseRef.current.promise
        // enter success state:duplication and close modal automatically
        dispatch({
          type: ActionTypes.ACTION_RESPONSE,
          payload: {
            action: DialogActions.DELETE_DUPLICATION,
            isError: false,
            message: state.data.navMergeDirection
              ? DeleteNavDuplicationResponseMessage
              : DeleteDuplicationResponseMessage,
            state: {
              redirectUrl: defaultRedirectUrl,
              willRedirect: Boolean(detailsViewType) && state.expenseId === state.data?.duplicateId,
            },
          },
        })
        // delayedClose(CLOSE_TIMEOUT)
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          dispatch({
            type: ActionTypes.ACTION_RESPONSE,
            payload: {
              action: DialogActions.DELETE_DUPLICATION,
              isError: true,
              message: errorMessage,
            },
          })
        }
      }
    } else {
      // expense details onblur duplication check
      state.clearFormInvoiceNumberField?.() // it comes from expense details (openWithData) - just clear invoice_number form field
      close()
    }
  }

  /**
   * Remove expense with duplicateId and clear duplication
   */
  async function removeExpenseWithDuplicateId() {
    dispatch({ type: ActionTypes.ACTION_REQUEST, payload: DialogActions.DELETE_DUPLICATE_EXPENSE })
    try {
      cPromiseRef.current = cancellablePromise(
        deleteExpenseFromList({
          expenseId: (state.data as DuplicateResults).duplicateId,
          needToUpdateList,
          source: 'delete_duplicate',
        })
      )
      await cPromiseRef.current.promise
      // enter success state:expense and redirect
      dispatch({
        type: ActionTypes.ACTION_RESPONSE,
        payload: {
          action: DialogActions.DELETE_DUPLICATE_EXPENSE,
          isError: false,
          message: DeleteDuplicateExpenseResponseMessage,
          state: {
            redirectUrl: defaultRedirectUrl,
            willRedirect: Boolean(detailsViewType) && state.expenseId === state.data?.duplicateId,
          },
        },
      })
      // delayedClose(CLOSE_TIMEOUT)
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        dispatch({
          type: ActionTypes.ACTION_RESPONSE,
          payload: {
            action: DialogActions.DELETE_DUPLICATE_EXPENSE,
            isError: true,
            message: errorMessage,
          },
        })
      }
    }
  }

  /**
   * Remove expense with originalId and clear duplication
   */
  async function removeExpenseWithOriginalId() {
    dispatch({ type: ActionTypes.ACTION_REQUEST, payload: DialogActions.DELETE_ORIGINAL_EXPENSE })
    try {
      cPromiseRef.current = cancellablePromise(
        deleteExpenseFromList({
          expenseId: (state.data as DuplicateResults).originalId,
          needToUpdateList,
          source: 'delete_original',
        })
      )
      await cPromiseRef.current.promise
      // enter success state:expense and redirect
      dispatch({
        type: ActionTypes.ACTION_RESPONSE,
        payload: {
          action: DialogActions.DELETE_ORIGINAL_EXPENSE,
          isError: false,
          message: DeleteOriginalExpenseResponseMessage,
          state: {
            redirectUrl: defaultRedirectUrl,
            willRedirect: Boolean(detailsViewType) && state.expenseId === state.data?.originalId,
          },
        },
      })
      // delayedClose(CLOSE_TIMEOUT)
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        dispatch({
          type: ActionTypes.ACTION_RESPONSE,
          payload: {
            action: DialogActions.DELETE_ORIGINAL_EXPENSE,
            isError: true,
            message: errorMessage,
          },
        })
      }
    }
  }

  function onSelect(selectedId: ItemIdType) {
    dispatch({ type: ActionTypes.NAV_MERGE_SELECT, payload: selectedId })
  }

  /**
   * It will call mergeExpenses API with payload
   * ({ direction: "from_nav" | "to_nav", from_id: number, to_id: number, update: boolean })
   *
   * There are 3 cases:
   * * NAV is original - connect with selected expense and no update on original expense's data
   * * NAV is duplication WITH UPDATE - connect with selected expense and update original expense's data with NAV data
   * * NAV is duplication WITHOUT UPDATE - connect with selected expense but no update on original expense's data with NAV data
   * @param {boolean} update - merge with or without update
   * @return onClickHandler function
   */
  function callMergeInvoices(update: boolean) {
    const dialogAction = update ? DialogActions.MERGE_EXPENSES_UPDATE : DialogActions.MERGE_EXPENSES
    const responseMessage = update ? MergeWithUpdateResponseMessage : MergeWithoutUpdateResponseMessage

    return async function onClickHandler() {
      dispatch({ type: ActionTypes.ACTION_REQUEST, payload: dialogAction })
      try {
        const isNavOriginal = state.data?.navMergeDirection === 'to_nav'
        const payload = {
          direction: state.data?.navMergeDirection as NonNullable<DuplicateResults['navMergeDirection']>,
          from_id: isNavOriginal ? (state.data as DuplicateResults)?.duplicateId : (state.selectedExpenseId as number),
          to_id: isNavOriginal ? (state.selectedExpenseId as number) : (state.data as DuplicateResults)?.duplicateId,
          update: isNavOriginal ? false : update,
          needToUpdateList, // control to call success action in saga - on details page won't need this update
        }

        cPromiseRef.current = cancellablePromise(mergeInvoices(payload))
        await cPromiseRef.current.promise
        // enter success state:merge
        dispatch({
          type: ActionTypes.ACTION_RESPONSE,
          payload: {
            action: dialogAction,
            message: responseMessage,
            isError: false,
            state: {
              willRedirect: Boolean(detailsViewType),
              redirectUrl: `${getRouteUrl(RouteKeys.COST, companyId)}/${payload.to_id}`,
            },
          },
        })
        // delayedClose(CLOSE_TIMEOUT)
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          dispatch({
            type: ActionTypes.ACTION_RESPONSE,
            payload: {
              action: dialogAction,
              message: errorMessage,
              isError: true,
            },
          })
        }
      }
    }
  }

  const contextValue = React.useMemo<DuplicateExpenseContextProps>(
    () => ({
      open,
      openWithData,
      isOpen: state.isOpen,
      willRedirect: state.willRedirect,
    }),
    [open, openWithData, state.isOpen, state.willRedirect]
  )

  function renderHeaderMessage() {
    if (state.data?.navMergeDirection) {
      return NavDuplicationDescriptionMessage
    }
    if (state.data?.originalDeletable) {
      if (state.data?.duplicateDeletable) {
        return DuplicationDescriptionDefaultMessage
      }
      return DuplicationDescriptionOriginalDeletableMessage
    }
    if (state.data?.duplicateDeletable) {
      return DuplicationDescriptionDuplicateDeletableMessage
    }
    // None of the invoices are deletable
    return DuplicationDescriptionNotDeletableMessage
  }

  function renderButtons() {
    if (state.data?.navMergeDirection) {
      // NAV merge 2 or 3 CTA buttons
      return (
        <>
          <ProgressButton
            onClick={callDeleteExpenseDuplication}
            variant="primaryText"
            disabled={Boolean(state.activeAction)}
            loading={state.activeAction === DialogActions.DELETE_DUPLICATION}
            succeed={state.responseState?.action === DialogActions.DELETE_DUPLICATION && !state.responseState?.isError}
            failed={state.responseState?.action === DialogActions.DELETE_DUPLICATION && state.responseState?.isError}
          >
            <FormattedMessage
              id="dialogs.expenseDuplication.buttons.noMerge"
              defaultMessage="Nem kell összekapcsolni"
            />
          </ProgressButton>
          {state.data?.navMergeDirection === 'to_nav' ? (
            <ProgressButton
              onClick={callMergeInvoices(false)}
              variant="primaryContained"
              disabled={Boolean(state.activeAction)}
              loading={state.activeAction === DialogActions.MERGE_EXPENSES}
              succeed={state.responseState?.action === DialogActions.MERGE_EXPENSES && !state.responseState?.isError}
              failed={state.responseState?.action === DialogActions.MERGE_EXPENSES && state.responseState?.isError}
            >
              <FormattedMessage
                id="dialogs.expenseDuplication.buttons.merge"
                defaultMessage="Összekapcsolás a kijelölt számlával"
              />
            </ProgressButton>
          ) : (
            <LightTooltip
              title={
                <FormattedMessage
                  id="dialogs.expenseDuplication.buttons.mergeWithUpdateTooltip"
                  defaultMessage="Ebben az esetben a két számla összekapcsolásra kerül, és a régi számlát frissítjük a NAV-tól kapott számla adataival."
                />
              }
              placement="top"
              PopperProps={{ disablePortal: true }}
            >
              <div>
                <ProgressButton
                  onClick={callMergeInvoices(true)}
                  variant="primaryContained"
                  disabled={Boolean(state.activeAction)}
                  loading={state.activeAction === DialogActions.MERGE_EXPENSES_UPDATE}
                  succeed={
                    state.responseState?.action === DialogActions.MERGE_EXPENSES_UPDATE && !state.responseState?.isError
                  }
                  failed={
                    state.responseState?.action === DialogActions.MERGE_EXPENSES_UPDATE && state.responseState?.isError
                  }
                >
                  <FormattedMessage
                    id="dialogs.expenseDuplication.buttons.mergeWithUpdate"
                    defaultMessage="Összekapcsolás (frissítéssel)"
                  />
                </ProgressButton>
              </div>
            </LightTooltip>
          )}
          {state.data?.navMergeDirection === 'from_nav' && (
            <LightTooltip
              title={
                <FormattedMessage
                  id="dialogs.expenseDuplication.buttons.mergeWithoutUpdateTooltip"
                  defaultMessage="Ebben az esetben a két számla összekapcsolásra kerül, de a számlát nem frissítjük a NAV-tól kapott számla adataival."
                />
              }
              placement="top"
              PopperProps={{ disablePortal: true }}
            >
              <div>
                <ProgressButton
                  onClick={callMergeInvoices(false)}
                  variant="primaryText"
                  disabled={Boolean(state.activeAction)}
                  loading={state.activeAction === DialogActions.MERGE_EXPENSES}
                  succeed={
                    state.responseState?.action === DialogActions.MERGE_EXPENSES && !state.responseState?.isError
                  }
                  failed={state.responseState?.action === DialogActions.MERGE_EXPENSES && state.responseState?.isError}
                >
                  <FormattedMessage
                    id="dialogs.expenseDuplication.buttons.mergeWithoutUpdate"
                    defaultMessage="Összekapcsolás (frissítés nélkül)"
                  />
                </ProgressButton>
              </div>
            </LightTooltip>
          )}
        </>
      )
    }

    // None of the invoices are deletable
    if (!state.data?.originalDeletable && !state.data?.duplicateDeletable) {
      return (
        <>
          <FormattedMessage
            tagName={StyledInfoText}
            id="dialogs.expenseDuplication.canNotResolveText"
            defaultMessage="A duplikáció nem feloldható."
          />
          <IntercomLauncherButton variant="primaryContained">{SupportButtonMessage}</IntercomLauncherButton>
        </>
      )
    }

    return (
      <>
        {state.data?.originalDeletable ? (
          <ProgressButton
            onClick={removeExpenseWithOriginalId}
            variant="primaryText"
            disabled={Boolean(state.activeAction)}
            loading={state.activeAction === DialogActions.DELETE_ORIGINAL_EXPENSE}
            succeed={
              state.responseState?.action === DialogActions.DELETE_ORIGINAL_EXPENSE && !state.responseState?.isError
            }
            failed={
              state.responseState?.action === DialogActions.DELETE_ORIGINAL_EXPENSE && state.responseState?.isError
            }
          >
            <FormattedMessage
              id="dialogs.expenseDuplication.buttons.deleteOldInvoice"
              defaultMessage="Korábbi számla törlése"
            />
          </ProgressButton>
        ) : (
          NotDeletableInfoMessage
        )}
        <LightTooltip
          title={
            <FormattedMessage
              id="dialogs.expenseDuplication.buttons.saveNewInvoiceTooltip"
              defaultMessage="Ebben az esetben az új számláról kitöröljük a felismert számla sorszámot."
            />
          }
          placement="top"
          PopperProps={{ disablePortal: true }}
        >
          <div>
            <ProgressButton
              onClick={callDeleteExpenseDuplication}
              variant="primaryContained"
              disabled={Boolean(state.activeAction)}
              loading={state.activeAction === DialogActions.DELETE_DUPLICATION}
              succeed={
                state.responseState?.action === DialogActions.DELETE_DUPLICATION && !state.responseState?.isError
              }
              failed={state.responseState?.action === DialogActions.DELETE_DUPLICATION && state.responseState?.isError}
            >
              <FormattedMessage
                id="dialogs.expenseDuplication.buttons.saveNewInvoice"
                defaultMessage="Megtartom mindkettőt"
              />
            </ProgressButton>
          </div>
        </LightTooltip>
        {state.data?.duplicateDeletable ? (
          <ProgressButton
            onClick={removeExpenseWithDuplicateId}
            variant="primaryText"
            disabled={Boolean(state.activeAction)}
            loading={state.activeAction === DialogActions.DELETE_DUPLICATE_EXPENSE}
            succeed={
              state.responseState?.action === DialogActions.DELETE_DUPLICATE_EXPENSE && !state.responseState?.isError
            }
            failed={
              state.responseState?.action === DialogActions.DELETE_DUPLICATE_EXPENSE && state.responseState?.isError
            }
          >
            <FormattedMessage
              id="dialogs.expenseDuplication.buttons.deleteNewInvoice"
              defaultMessage="Új számla törlése"
            />
          </ProgressButton>
        ) : (
          NotDeletableInfoMessage
        )}
      </>
    )
  }

  return (
    <DuplicateExpenseContext.Provider value={contextValue}>
      <>
        {children}
        {/* If there's any bug and redirectUrl is not passed, we fail back to the dashboard */}
        {state.canRedirect && <Navigate to={state.redirectUrl ?? defaultRedirectUrl} />}

        <CustomDialog
          ariaIdPrefix={ariaIdPrefix}
          open={state.isOpen}
          onClose={close}
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
        >
          <DuplicateDialogHeader title={DialogTitleMessage} borderless={isResponseStateActive}>
            {!isResponseStateActive && (
              <Typography size="400-xs" color="gray-80">
                {renderHeaderMessage()}
              </Typography>
            )}
          </DuplicateDialogHeader>
          <DuplicateDialogBody $isResponseState={isResponseStateActive}>
            <DialogContent
              activeAction={state.activeAction}
              data={state.data}
              responseState={state.responseState}
              selectedExpenseId={state.selectedExpenseId}
              onSelect={onSelect}
            />
          </DuplicateDialogBody>
          <DuplicateDialogActions>
            {isResponseStateActive ? (
              <CloseActionContainer>
                <Button variant="primaryContained" onClick={close}>
                  {CloseButtonMessage}
                </Button>
              </CloseActionContainer>
            ) : (
              <>
                {state.responseState?.action !== DialogActions.INIT && state.responseState?.isError && (
                  <MessageText color="error" align="right" value={state.responseState?.message} />
                )}
                <DuplicateDialogActionsContainer>
                  <Button variant="secondaryContained" onClick={close}>
                    <FormattedMessage id="dialogs.expenseDuplication.buttons.cancel" defaultMessage="Mégse" />
                  </Button>
                  {state.data && <DialogActionsInnerContainer>{renderButtons()}</DialogActionsInnerContainer>}
                </DuplicateDialogActionsContainer>
              </>
            )}
          </DuplicateDialogActions>
        </CustomDialog>
      </>
    </DuplicateExpenseContext.Provider>
  )
}

PureDuplicateExpenseProvider.propTypes = {
  children: PropTypes.node.isRequired,
  companyId: PropTypes.number.isRequired,
  deleteExpenseDuplication: PropTypes.func.isRequired,
  deleteExpenseFromList: PropTypes.func.isRequired,
  detailsViewType: PropTypes.oneOf(['expense', 'quarantine']) as React.Validator<'expense' | 'quarantine' | undefined>,
  init: PropTypes.func.isRequired,
  mergeInvoices: PropTypes.func.isRequired,
  needToUpdateList: PropTypes.oneOf(['expense', 'quarantine']) as React.Validator<'expense' | 'quarantine' | undefined>,
}

export const DuplicateExpenseProvider = connect(
  (state: Store) => ({
    companyId: state.auth.company.data.id,
  }),
  dispatch => ({
    init: bindActionToPromise(dispatch, expenseActions.initDuplication.request),
    deleteExpenseDuplication: bindActionToPromise(dispatch, expenseActions.deleteExpenseDuplication.request),
    deleteExpenseFromList: bindActionToPromise(dispatch, expenseActions.deleteExpenseFromList.request),
    mergeInvoices: bindActionToPromise(dispatch, quarantineActions.mergeInvoicesV2.request),
  })
)(PureDuplicateExpenseProvider)

DuplicateExpenseProvider.displayName = 'DuplicateExpenseProvider'

export function useDuplicateExpense() {
  return useAssertContext(DuplicateExpenseContext)
}
