import { INVOICE_ORIGINS } from '@constants'

export function getSourceName(origin?: InvoiceOrigin) {
  switch (origin) {
    case INVOICE_ORIGINS.VASFALA:
      return 'QUiCK'
    case INVOICE_ORIGINS.NAV:
    case INVOICE_ORIGINS.SZAMLAZZ:
      return 'Számlázz.hu'
    case INVOICE_ORIGINS.BILLINGO:
      return 'Billingo'

    default:
      return null
  }
}

export function getProcessedSourceName(origin?: InvoiceOrigin) {
  switch (origin) {
    case INVOICE_ORIGINS.VASFALA:
      return 'QUiCK NAV'
    case INVOICE_ORIGINS.NAV:
      return 'Számlázz.hu NAV'

    default:
      return getSourceName(origin)
  }
}
