import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!ABORT_DOWNLOAD_INCOME_ARTIFACT',
  '!DOWNLOAD_INCOME_ARTIFACT',
  '!FETCH_INCOME_DETAILS_BY_PAGING_V2',
  '!INCOME_REGISTER_DOWNLOAD',
  '!INIT_DOWNLOAD_V2',
  '!INIT_INCOME_LIST_PAGE_LOAD',
  '!INVOICE_NUMBER_CHECK',
  '!RESET_PAGINATION',
  '!START_BULK_DOWNLOAD_V2',
  '!START_BULK_EXPORT_V2',
  '!STOP_BULK_DOWNLOAD_V2',
  '!STOP_BULK_EXPORT_V2',
  '!TRIGGER_INCOME_LIST_UPDATE',
  '!UPDATE_INCOME_WITH_RECOMMENDATION',
  '!UPDATE_PARTNER_CALCULATION_BASE',
  '!USER_VIEW_INCOME',
  'BULK_CATEGORIZATION_V2',
  'BULK_DELETE_V2',
  'BULK_TAGGING_V2',
  'CLEAR_INCOME_DETAILS',
  'CREATE_INCOME',
  'CREATE_REVENUE_TYPE',
  'FETCH_INCOME_CHARTS',
  'FETCH_INCOME_DETAILS',
  'FETCH_INCOME_LIST_BY_PAGING_V2',
  'FETCH_INCOME_LIST_V2',
  'FETCH_REVENUE_TYPES',
  'REMOVE_INCOME_ARTIFACT',
  'REMOVE_INCOME',
  'UPDATE_INCOME',
  'UPDATE_ORDER_V2',
  'UPDATE_ROWS_PER_PAGE_V2',
  'UPLOAD_INCOME_ARTIFACT',
] as const

export default serviceActionsGenerator<typeof actionTypes>('income', actionTypes)
