import React from 'react'
import PropTypes from 'prop-types'

import { useField } from 'react-final-form'

import { InvoiceType, USE_FIELD_VALUE_CONFIG } from '@constants'

import { MAIN_SUGGESTIONS } from './constants'
import { LedgerNumberSelect, LedgerNumberSelectOwnProps, propTypes } from './LedgerNumberSelect'

interface MainLedgerNumberSelectProps extends Omit<LedgerNumberSelectOwnProps, 'suggestion' | 'recommendations'> {
  invoiceType: InvoiceType
}

export function MainLedgerNumberSelect({ invoiceType, ...rest }: MainLedgerNumberSelectProps) {
  const {
    input: { value: vatArea },
  } = useField('vat_area', USE_FIELD_VALUE_CONFIG)

  return <LedgerNumberSelect {...rest} suggestion={MAIN_SUGGESTIONS[invoiceType][vatArea]} />
}

MainLedgerNumberSelect.propTypes = {
  ...propTypes,
  invoiceType: PropTypes.oneOf(Object.values(InvoiceType)).isRequired as React.Validator<InvoiceType>,
}
