// BE constants
export enum EventLogTypes {
  ARTIFACT_CREATED = 'artifact_created',
  ARTIFACT_DELETED = 'artifact_deleted',
  ARTIFACT_DOWNLOADED = 'artifact_downloaded',
  BASE_INFO_EDITED = 'base_info_edited',
  BULK_TAGGING = 'bulk_tagging',
  CREATE_INTEGRATION_PAYMENT = 'create_integration_payment',
  CREATE_PAYMENT = 'create_payment',
  DELETE_INTEGRATION_PAYMENT = 'delete_integration_payment',
  DELETE_PAYMENT = 'delete_payment',
  DOKUMENT_ATTACH = 'document_attach',
  DOKUMENT_UPDATE = 'document_update',
  EXPENSE_APPROVED_STATUS_CREATED = 'expense_status_created',
  EXPENSE_APPROVED_STATUS_DELETED = 'expense_status_deleted',
  EXPENSE_BULK_CATEGORIZED = 'expense_bulk_categorized',
  INVOICE_ACCEPTED = 'invoice_accepted',
  INVOICE_CREATED = 'invoice_created',
  INVOICE_EXPORT = 'invoice_export',
  INVOICE_FILED = 'invoice_filed',
  INVOICE_NAV_MERGED = 'invoice_nav_merged',
  INVOICE_NAV_STATUS_CHANGE = 'nav_status_change',
  INVOICE_POST_IT_CREATED = 'invoice_post_it_created',
  INVOICE_POST_IT_DELETED = 'invoice_post_it_deleted',
  INVOICE_POST_IT_UPDATED = 'invoice_post_it_updated',
  INVOICE_STATUS_CHANGE = 'status_change',
  INVOICE_USER_SAW = 'user_saw',
  REVENUE_BULK_CATEGORIZED = 'revenue_bulk_categorized',
  SET_PLANNED_PAYMENT_DATE = 'set_planned_payment_date',
}

//! EVENT_INVOICE_CREATED: event details invoice_source
// INVOICE_CREATE_SOURCE_ARTIFACT = "artifact upload"
// INVOICE_CREATE_SOURCE_SZAMLAZZ = "szamlazz integration"
// INVOICE_CREATE_SOURCE_BILLINGO = "billingo integration"
// INVOICE_CREATE_SOURCE_MANDRILL = "email upload"
// INVOICE_CREATE_SOURCE_UPLOAD = "data upload"
