import React from 'react'

import { FormattedDate, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { accountNumberFormatter, formatNumberToCopy, getRouteUrl } from '@helpers'

import { PreviewVariant } from '@contexts'

import { InvoiceArtifactPreviewOpener, InvoicePreviewOpener } from '@components/DocViewer'
import { SimpleTable } from '@components/tables'
import { AccountNumber, CopyAndPasteButton, Currency, OverflowText, TooLongText } from '@components/ui'

import { PAYMENT_FLOW, PAYMENT_TRANSACTION_TYPE, RouteKeys } from '@constants'

import { CreateBankPaymentTableAccessors, getTableColumns } from './PaymentTableElements'

interface PaymentSummaryTableProps {
  companyId: number
  flow: PaymentFlowType
  transactions: PaymentTransaction[]
  transactionType: PaymentTransactionType
}

function PurePaymentSummaryTable({ companyId, flow, transactions, transactionType }: PaymentSummaryTableProps) {
  const { formatDate, formatNumber } = useIntl()

  const isSecondaryIdVisible = React.useMemo(
    () => transactionType === PAYMENT_TRANSACTION_TYPE.expense && transactions.some(({ secondaryId }) => !!secondaryId),
    [transactionType, transactions]
  )

  const useTableProps = React.useMemo(() => {
    const hiddenColumns = [CreateBankPaymentTableAccessors.ROW_DATA]

    if (flow !== PAYMENT_FLOW.transfer) {
      hiddenColumns.push(CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER)
    }
    if (!isSecondaryIdVisible) {
      hiddenColumns.push(CreateBankPaymentTableAccessors.SECONDARY_ID)
    }

    return {
      initialState: {
        hiddenColumns,
      },
    }
  }, [flow, isSecondaryIdVisible])

  const tableColumns = React.useMemo(() => {
    return getTableColumns({
      isSecondaryIdVisible,
      isSummary: true,
      isTransferFlow: flow === PAYMENT_FLOW.transfer,
      transactionType,
    })
  }, [flow, isSecondaryIdVisible, transactionType])

  const tableData = React.useMemo(() => {
    return transactions.map(transaction => ({
      [CreateBankPaymentTableAccessors.ROW_DATA]: transaction,
      [CreateBankPaymentTableAccessors.PREVIEW]: (
        <>
          {transaction.artifact && (
            <InvoiceArtifactPreviewOpener
              detailsUrl={`${getRouteUrl(RouteKeys.COST, companyId)}/${transaction.transactionId}`}
              fileName={transaction.artifact.filename}
              path={transaction.artifact.path}
              previewVariant={PreviewVariant.INVOICE}
            />
          )}
          {!transaction.artifact && transaction.links.preview && (
            <InvoicePreviewOpener
              detailsUrl={`${getRouteUrl(RouteKeys.COST, companyId)}/${transaction.transactionId}`}
              invoiceId={transaction.transactionId}
              invoiceVariant="expense"
              previewUrl={transaction.links.preview}
              previewVariant={PreviewVariant.INVOICE}
            />
          )}
        </>
      ),
      [CreateBankPaymentTableAccessors.PARTNER]: (
        <CopyAndPasteButton text={transaction.partnerName}>
          <OverflowText>{transaction.partnerName}</OverflowText>
        </CopyAndPasteButton>
      ),
      [CreateBankPaymentTableAccessors.TITLE]: (
        <>
          {transactionType === PAYMENT_TRANSACTION_TYPE.expense ? (
            <CopyAndPasteButton text={transaction.title}>
              <TooLongText value={transaction.title} disablePortal />
            </CopyAndPasteButton>
          ) : (
            <FormattedDate value={transaction.title} year="numeric" month="long" />
          )}
        </>
      ),
      [CreateBankPaymentTableAccessors.SECONDARY_ID]: transaction.secondaryId && (
        <CopyAndPasteButton text={transaction.secondaryId}>
          <TooLongText value={transaction.secondaryId} disablePortal />
        </CopyAndPasteButton>
      ),
      [CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER]: transaction.beneficiaryAccountNumber && (
        <CopyAndPasteButton text={accountNumberFormatter.format(transaction.beneficiaryAccountNumber)}>
          <AccountNumber value={transaction.beneficiaryAccountNumber} />
        </CopyAndPasteButton>
      ),
      [CreateBankPaymentTableAccessors.DUE_AT]: (
        <CopyAndPasteButton text={formatDate(transaction.dueAt)}>
          <FormattedDate value={transaction.dueAt} />
        </CopyAndPasteButton>
      ),
      [CreateBankPaymentTableAccessors.GROSS_AMOUNT]: (
        <CopyAndPasteButton text={formatNumberToCopy(Number(transaction.grossAmount), formatNumber)}>
          <Currency value={Number(transaction.grossAmount)} currency={transaction.currencyName} />
        </CopyAndPasteButton>
      ),
      [CreateBankPaymentTableAccessors.REMAINING]: (
        <CopyAndPasteButton text={formatNumberToCopy(Number(transaction.remaining), formatNumber)}>
          <Currency value={Number(transaction.remaining)} currency={transaction.currencyName} />
        </CopyAndPasteButton>
      ),
      [CreateBankPaymentTableAccessors.AMOUNT]: (
        <CopyAndPasteButton text={formatNumberToCopy(Number(transaction.amount), formatNumber)}>
          <Currency value={Number(transaction.amount || 0)} currency={transaction.currencyName} />
        </CopyAndPasteButton>
      ),
    }))
  }, [companyId, formatDate, formatNumber, transactionType, transactions])

  return <SimpleTable columns={tableColumns} data={tableData} layout="fixed" useTableProps={useTableProps} />
}

export const PaymentSummaryTable = connect((state: Store) => ({
  companyId: state.auth.company.data.id,
}))(PurePaymentSummaryTable)

PaymentSummaryTable.displayName = 'PaymentSummaryTable'
