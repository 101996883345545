import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import cx from 'classnames'

import Select from './select'

//!NOTE deprecated - use @components/ui/form - RenderFormFieldSelect instead
export function RenderOldSelect({
  classes,
  label,
  labelClassName,
  meta: { touched, error },
  selectComponent: SelectComponent,
  inputClassName,
  required,
  ...props
}) {
  const hasError = touched && Boolean(error)

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(classes.selectRoot, 'form-control', { 'form-control-error': hasError })}
      error={hasError}
      disabled={props.disabled}
      required={required}
    >
      {label && (
        <InputLabel htmlFor={props.input.name} shrink className={cx(classes.bootstrapFormLabel, labelClassName)}>
          {label}
        </InputLabel>
      )}
      <div className={cx(classes.selectInput, inputClassName)}>
        <SelectComponent hasError={hasError} {...props} />
      </div>
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

RenderOldSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  input: PropTypes.object.isRequired,
  inputClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  label: PropTypes.node.isRequired,
  labelClassName: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.string }),
  onCreate: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  recommendation: PropTypes.object,
  selectComponent: PropTypes.elementType,
  valueKey: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

RenderOldSelect.defaultProps = {
  disabled: false,
  inputClassName: '',
  labelClassName: '',
  labelKey: 'name',
  selectComponent: Select,
  valueKey: 'id',
  required: false,
}
