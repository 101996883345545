import { AxiosError } from 'axios'

import { BackgroundProcessActions } from '@constants/backgroundActions'
import { FiltersStateKey } from '@constants/store'

// LOCAL HELPERS
export function getOrderingParam({ order, orderBy }: { order: OrderOptions; orderBy: string }): string {
  const prefix = order === 'asc' ? '' : '-'
  return prefix + orderBy
}

interface GetOnlyActiveFiltersOptions {
  isDateFilterDisabled?: boolean
}

/**
 * prepare filters for api payload, clean inactive filters
 * @param {NullableObject<Partial<AllTabFilters>>} filters - build query params from these filters for BE request
 * @param {boolean|undefined} isDateFilterDisabled - when TRUE date filters are not sent to BE
 */
function getOnlyActiveFilters(
  {
    dateField,
    expiredDays,
    expiringDays,
    fromDate,
    isExpired,
    isExpiring,
    isPaid,
    toDate,
    searchFields,
    search,
    ...restGenericFilters
  }: NullableObject<Partial<AllTabFilters>>,
  { isDateFilterDisabled = false }: GetOnlyActiveFiltersOptions = {}
) {
  const filters = {} as ApiPageParams

  if (search) {
    filters.search = search
    if (searchFields?.length) {
      filters.searchFields = searchFields
    }
  }

  if (dateField) {
    filters.dateField = dateField
  }

  if (!isDateFilterDisabled && fromDate != null && toDate != null) {
    filters.fromDate = fromDate
    filters.toDate = toDate
  }

  //* PAID STATUS FILTER
  if (isPaid === true) {
    filters.isPaid = true
  }

  if (isExpiring === true && expiringDays != null) {
    filters.isExpiring = true
    filters.expiringDays = expiringDays
  }

  if (isExpired === true && expiredDays != null) {
    filters.isExpired = true
    filters.expiredDays = expiredDays
  }

  //* for any generic id and name or boolean type filters
  Object.keys(restGenericFilters).forEach(key => {
    const value = restGenericFilters[key as keyof typeof restGenericFilters]
    if (value != null) {
      if (typeof value === 'boolean' || typeof value === 'string') {
        filters[key as keyof typeof restGenericFilters] = value
      } else if (Array.isArray(value)) {
        // CommonIdAndNameType[] | string[]
        if (value.length) {
          filters[key as keyof typeof restGenericFilters] = value.map(filterValue => {
            if (typeof filterValue === 'string') {
              // string[]
              return filterValue
            } else {
              // CommonIdAndNameType[]
              return filterValue.id
            }
          })
        }
      } else {
        // CommonIdAndNameType
        filters[key as keyof typeof restGenericFilters] = value.id
      }
    }
  })

  return filters
}

// SERVICE HELPERS
export function getDokumentsFiltersFromStore({
  filters: { dokumentList, fromDate, toDate, isGlobalDateFilterDisabled },
}: Store) {
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...dokumentList,
    },
    { isDateFilterDisabled: isGlobalDateFilterDisabled }
  )

  return filters
}

export function getDokumentsParamsFromStore(
  state: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
): ApiPageParams {
  const filters = getDokumentsFiltersFromStore(state)
  const { cursor, pageSize } = state.dokuments.list
  const ordering = getOrderingParam(state.dokuments.list)

  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      ordering,
      pageSize,
    }
  }

  return {
    ...filters,
    ordering,
    pageSize,
  }
}

export function generateAPIPayload<TParams>(
  payload: { selected: ItemIdType[]; isAllSelected: boolean },
  params: TParams
): ApiPayload<TParams> {
  const { selected, isAllSelected } = payload
  // only send filters when isAllSelected is true
  if (isAllSelected) {
    return { params }
  }
  return { data: { ids: selected } }
}

//* PAYMENT
/**
 * Get back the name of the provider from its id
 *
 * @param {(number | null | undefined)} provider
 * @param {BankProvider[]} providers
 */
export function getPaidThroughProviderName(providerId: number | null | undefined, providers: BankProvider[]): string {
  if (!providerId || !providers.length) {
    return ''
  }

  return providers.find(provider => providerId === provider.id)?.name ?? ''
}

/**
 * Helper to gather active filters to query the backend endpoint with
 *
 * @param {(any)} state - redux store state
 */
export function getPaidThroughParamsFromStore(
  {
    filters: { paidThroughs },
    payment: {
      paidThroughs: { cursor, pageSize },
    },
  }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const filters = getOnlyActiveFilters(paidThroughs, { isDateFilterDisabled: true })

  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      pageSize,
    }
  }
  return {
    ...filters,
    pageSize,
  }
}

/**
 * Helper to gather active filters to query the backend endpoint with
 *
 * @param {(any)} state - redux store state
 */
export function getPaymentParamsFromStore(
  {
    filters: { paymentOrders, fromDate, toDate, isGlobalDateFilterDisabled },
    payment: {
      paymentOrders: { cursor, pageSize },
    },
  }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...paymentOrders,
    },
    { isDateFilterDisabled: isGlobalDateFilterDisabled }
  )

  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      pageSize,
    }
  }
  return {
    ...filters,
    pageSize,
  }
}

/**
 * Helper to gather active filters to query the backend endpoint with
 *
 * @param {Store} state - redux store state
 */
export function getBankTransactionParamsFromStore(
  {
    filters: { bankTransactions, fromDate, toDate, isGlobalDateFilterDisabled },
    payment: {
      bankTransactions: { cursor, pageSize },
    },
  }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...bankTransactions,
    },
    { isDateFilterDisabled: isGlobalDateFilterDisabled }
  )

  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      pageSize,
    }
  }
  return {
    ...filters,
    pageSize,
  }
}

/**
 * Helper to gather active filters to query the backend endpoint with
 *
 * @param {Store} state - redux store state
 */
export function getPaymentTransactionParamsFromStore(
  {
    filters: { fromDate, isGlobalDateFilterDisabled, paymentTransactions, toDate },
    payment: {
      paymentTransactions: { cursor, pageSize },
    },
  }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...paymentTransactions,
    },
    { isDateFilterDisabled: isGlobalDateFilterDisabled }
  )

  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      pageSize,
    }
  }
  return {
    ...filters,
    pageSize,
  }
}

/**
 * Helper to gather active filters to query the backend expense V2 endpoint with
 *
 * @param {Store} state - redux store state
 */
export function getExpenseListFiltersFromStore(
  { filters: { expenseList, fromDate, toDate, isGlobalDateFilterDisabled }, expense: { list } }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const { cursor, pageSize } = list
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...expenseList,
    },
    { isDateFilterDisabled: isGlobalDateFilterDisabled }
  )
  const ordering = getOrderingParam(list)

  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      ordering,
      pageSize,
    }
  }

  return {
    ...filters,
    ordering,
    pageSize,
  }
}

/**
 * Helper to gather active filters to query the backend expense V2 endpoint with
 *
 * @param {Store} state - redux store state
 */
export function getIncomeListFiltersFromStore(
  { filters: { incomeList, fromDate, toDate, isGlobalDateFilterDisabled }, income: { list } }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const { cursor, pageSize } = list
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...incomeList,
    },
    { isDateFilterDisabled: isGlobalDateFilterDisabled }
  )
  const ordering = getOrderingParam(list)
  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      ordering,
      pageSize,
    }
  }
  return {
    ...filters,
    ordering,
    pageSize,
  }
}

/**
 * Helper to build API payload from selected action, request payload and active filters
 *
 * @param {BackgroundProcessActions} action - requested backend expense action
 * @param {BackgroundActionRequestPayload} payload - request payload data to send via API request
 * @param {object} filters - active expense list filters from store
 */
export function generateBackgroundProcessActionPayload<Filters extends object>(
  action: BackgroundProcessActions,
  { ids, isAllSelected, params }: BackgroundActionRequestPayload,
  filters: Filters
) {
  if (isAllSelected) {
    return {
      action,
      params,
      ...filters,
    }
  }

  return {
    action,
    params,
    ids,
  }
}

/**
 * Helper to gather active filters to query the backend quarantine V2 endpoint with
 *
 * @param {Store} state - redux store state
 */
export function getQuarantineListFiltersFromStore(
  {
    filters: {
      quarantineList: { isDateFilterDisabled, ...listFilters },
      fromDate,
      toDate,
    },
    quarantine: { list },
  }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const { cursor, pageSize } = list
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...listFilters,
    },
    { isDateFilterDisabled }
  )
  const ordering = getOrderingParam(list)

  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      ordering,
      pageSize,
    }
  }
  return {
    ...filters,
    ordering,
    pageSize,
  }
}

/**
 * Helper to gather active filters to query the backend quarantine V2 endpoint with
 *
 * @param {Store} state - redux store state
 */
export function getTaxListFiltersFromStore(
  { filters: { taxList, fromDate, toDate, isGlobalDateFilterDisabled }, tax: { list } }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const { cursor, pageSize } = list
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...taxList,
    },
    { isDateFilterDisabled: isGlobalDateFilterDisabled }
  )
  const ordering = getOrderingParam(list)
  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      ordering,
      pageSize,
    }
  }
  return {
    ...filters,
    ordering,
    pageSize,
  }
}

/**
 * Helper to gather active filters to query the backend quarantine V2 endpoint with
 *
 * @param {Store} state - redux store state
 */
export function getSalaryListFiltersFromStore(
  { filters: { salaryList, fromDate, toDate, isGlobalDateFilterDisabled }, salary: { list } }: Store,
  { withCursor = false }: { withCursor?: boolean } = {}
) {
  const { cursor, pageSize } = list
  const filters = getOnlyActiveFilters(
    {
      fromDate,
      toDate,
      ...salaryList,
    },
    { isDateFilterDisabled: isGlobalDateFilterDisabled }
  )
  const ordering = getOrderingParam(list)
  if (withCursor && cursor) {
    return {
      ...filters,
      cursor,
      ordering,
      pageSize,
    }
  }
  return {
    ...filters,
    ordering,
    pageSize,
  }
}

/**
 * Helper to remove "new" badge from V2 monthly-salary or monthly-tax list item where listItem.id === data.id when edit listItem or view its details
 *
 * @param {SalaryListData[] | TaxListData[]} list list of v2 data
 * @param {ItemIdType} id ID of the currently visited list item
 * @returns updated list of v2 data
 */
export function userSawMonthlySalaryOrTax<ListData extends { id: ItemIdType; badges: InvoiceBadgeValue[] }>(
  list: ListData[],
  id: number
) {
  return list.map(listItem => {
    if (listItem.id === id) {
      return {
        ...listItem,
        badges: listItem.badges.filter(badge => badge !== 'new'),
      }
    }
    return listItem
  })
}

/**
 * Helper to gather active filters to query the backend monthlyStatement V2 endpoint with
 *
 * @param {Store} state - redux store state
 */
export function getMonthlyStatementFiltersFromStore({ filters: { fromDate, toDate, monthlyStatement } }: Store) {
  return getOnlyActiveFilters({
    fromDate,
    toDate,
    ...monthlyStatement,
  })
}

/**
 * Helper to gather filter options from store to validate URL search params' values
 *
 * @param {Store} state - redux store state
 * @param {FiltersStateKey} filtersStateKey - filters state key for active page
 * @returns currency, categoryType, tag, paymentMethod and vatArea options
 */
export function getUrlFilterOptionsFromStore(
  {
    dashboard: {
      common: { currencies, payment_methods, vat_areas },
      tags,
    },
    dokuments: {
      dokumentTypes: { data: documentTypeOptions },
    },
    expense: { expenseTypes },
    income: { revenueTypes },
    payment: { paidThroughOptions },
  }: Store,
  filtersStateKey: FiltersStateKey
) {
  return {
    categoryTypeOptions: (filtersStateKey === FiltersStateKey.INCOME_LIST ? revenueTypes : expenseTypes).data,
    currencyOptions: currencies,
    documentTypeOptions,
    paidThroughOptions,
    paymentMethodOptions: payment_methods,
    tagOptions: tags.data,
    vatAreaOptions: vat_areas,
  }
}

//* Error with exception
type ErrorResponseDataWithConsent = AxiosError<
  {
    Error: string[]
  } & Record<string, boolean>
>

/**
 * Get error message with consent flag
 * @param payload - Error response data or Error object
 * @param consentKey - key to check if consent is allowed
 * @returns error message and isAllowedOrNull flag
 */
export function getErrorMessageWithConsent(
  payload: ErrorResponseDataWithConsent | Error,
  consentKey: string
): ErrorWithConsent {
  if (payload instanceof AxiosError) {
    return {
      message: payload.response?.data?.Error ?? null,
      isAllowedOrNull: payload.response?.data?.[consentKey] ?? null,
    }
  }
  return {
    message: payload.message,
    isAllowedOrNull: null,
  }
}
