import React from 'react'
import PropTypes from 'prop-types'

import __debounce from 'lodash/debounce'
import { connect } from 'react-redux'

import { commonActions, CommonAxiosPayload } from '@services'

import { parseApiErrorMessage } from '@helpers/api'
import { bindActionToPromise } from '@helpers/index'

import { useAlertDispatch } from '@contexts'

import { PAID_INSTALLMENT, PAID_YES } from '@constants'

import { PaidStatusHistory, PaidStatusIndicator, PaidStatusIndicatorProps } from './elements'

export interface PureAsyncPaidStatusProps extends Pick<PaidStatusIndicatorProps, 'placedInModal' | 'value'> {
  callUrl: AsyncFunction<CommonAxiosPayload<void>, { history: BackendInvoicePaymentHistoryResults }>
  historyUrl: string
  invoiceCurrencyId: number
}

export function PureAsyncPaidStatus({
  callUrl,
  historyUrl,
  invoiceCurrencyId,
  value,
  ...rest
}: PureAsyncPaidStatusProps) {
  const [{ fetched, results }, setState] = React.useState({
    fetched: false,
    results: [] as BackendInvoicePaymentHistoryResults,
  })
  const { setErrorAlert } = useAlertDispatch()

  const debouncedLoadHistoryRef = React.useRef(
    __debounce(() => {
      callUrl({ url: historyUrl, method: 'get' })
        .then(response => {
          setState({ fetched: true, results: response.history })
        })
        .catch(error => {
          const errorMessage = parseApiErrorMessage(error)
          if (errorMessage) {
            setErrorAlert(errorMessage)
          }
        })
    }, 400)
  )

  React.useEffect(
    () => () => {
      debouncedLoadHistoryRef.current.cancel()
    },
    []
  )

  function handleOpen() {
    if (!fetched && [PAID_YES, PAID_INSTALLMENT].includes(value)) {
      debouncedLoadHistoryRef.current()
    }
  }

  function handleClose() {
    debouncedLoadHistoryRef.current.cancel()
  }

  return (
    <PaidStatusIndicator
      {...rest}
      onClose={handleClose}
      onOpen={handleOpen}
      title={<PaidStatusHistory fetched={fetched} data={results} invoiceCurrencyId={invoiceCurrencyId} value={value} />}
      value={value}
    />
  )
}

PureAsyncPaidStatus.propTypes = {
  callUrl: PropTypes.func.isRequired,
  historyUrl: PropTypes.string.isRequired,
  invoiceCurrencyId: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired as React.Validator<InvoicePaidStatusValue>,
}

export const AsyncPaidStatus = connect(null, dispatch => ({
  callUrl: bindActionToPromise(dispatch, commonActions.callUrl.request),
}))(PureAsyncPaidStatus)

AsyncPaidStatus.displayName = 'AsyncPaidStatus'
