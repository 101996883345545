export const INVOICE_ORIGINS = {
  SZAMLAZZ: 'szamlazz',
  BILLINGO: 'billingo',
  EMAIL: 'email',
  NAV: 'nav',
  UPLOAD: 'upload',
  VASFALA: 'vasfala',
} as const

//! these values synced from backend
export const INVOICE_SOURCE_VALUES = {
  SZAMLA_SOURCE_QUICK: 26,
  SZAMLA_SOURCE_SMARTBOOKS: 28,
  SZAMLA_SOURCE_NAV: 34,
  SZAMLA_SOURCE_BILLINGO: 99,
}

export const PARTNER_SOURCES = {
  SOURCE_SZAMLAZZ: 'szamlazz',
  SOURCE_BILLINGO: 'billingo',
  SOURCE_QUICK: 'quick_auto_generated',
}
