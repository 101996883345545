import { getDefaultPageSize, transformObjectToArray } from '@helpers'

import authActions from '../auth/actions'
import actions from './actions'

const defaultPageSize = getDefaultPageSize()

function serializeCountries(countries: Record<string, string>): Country[] {
  return Object.keys(countries).map(key => ({ id: key, name: countries[key] }))
}

function serializeDashboardCounts({
  new: _new,
  expiring,
  expired,
  is_updated,
}: {
  new?: number
  expiring?: number
  expired?: number
  is_updated?: number
}) {
  return {
    is_new: _new || 0,
    is_expire: expiring || 0,
    is_expired: expired || 0,
    is_updated: is_updated || 0,
  }
}

const initialCommonState: DashboardCommon = {
  initializing: false,
  company_types: [],
  currencies: [],
  industries: [],
  bank_providers: [],
  payment_methods: [],
  vat_areas: [],
  countries: [],
  income_types: [],
  artifact_max_size: 0,
  document_max_size: 0,
  allowed_document_types: [],
  taxation: {
    atalany_cost_percentages: [],
    atalany_employment_types: [],
    savos_ipa_types: [],
    tax_forms: [],
  },
}

export const initialState: DashboardStore = {
  counts: {
    ...serializeDashboardCounts({}),
    processing: 0,
    show_szamlazz_sync_ribbon: false,
  },
  common: initialCommonState,
  tags: {
    data: [],
    loading: false,
    error: null,
    fetched: false,
  },
  salaryGroups: {
    order: 'desc',
    orderBy: 'title',
    page: 0,
    pageSize: defaultPageSize,
    data: [],
    count: 0,
    loading: false,
    fetched: false,
    error: null,
  },
  xmlUploading: false,
  taxGroups: {
    order: 'desc',
    orderBy: 'title',
    page: 0,
    pageSize: defaultPageSize,
    data: [],
    count: 0,
    loading: false,
    fetched: false,
    error: null,
  },
  taxCodes: {
    data: [],
    loading: false,
    error: null,
    fetched: false,
  },
  ledgerNumbers: [],
}

// TODO later type should depend on generated actions' types [xxx]_[REQUEST|SUCCESS|FAILURE]
type ReducerAction = { type: string; payload: any }

function reducer(state: DashboardStore = initialState, action: ReducerAction) {
  switch (action.type) {
    case actions.initialize.REQUEST:
      return {
        ...state,
        common: {
          ...state.common,
          initializing: true,
        },
      }
    case actions.initialize.SUCCESS: {
      const { payment_methods, vat_areas, countries, income_types, banks, ...rest } = action.payload
      return {
        ...state,
        common: {
          ...state.common,
          initializing: false,
          ...rest,
          bank_providers: banks,
          payment_methods: transformObjectToArray(payment_methods),
          vat_areas: transformObjectToArray(vat_areas),
          countries: serializeCountries(countries),
          income_types: transformObjectToArray(income_types, true),
        },
      }
    }
    case actions.initialize.FAILURE:
      return {
        ...state,
        common: {
          ...state.common,
          initializing: false,
        },
      }

    // Expenses
    case actions.dashboardStatusCheck.SUCCESS: {
      const { processing, show_szamlazz_sync_ribbon } = action.payload
      if (
        processing !== state.counts.processing ||
        show_szamlazz_sync_ribbon !== state.counts.show_szamlazz_sync_ribbon
      ) {
        return {
          ...state,
          counts: {
            ...state.counts,
            processing,
            show_szamlazz_sync_ribbon,
          },
        }
      } else {
        return state
      }
    }

    // TAGS
    case actions.fetchTags.REQUEST:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: true,
          fetched: false,
        },
      }
    case actions.fetchTags.SUCCESS:
      return {
        ...state,
        tags: {
          data: action.payload,
          loading: false,
          fetched: true,
          error: null,
        },
      }
    case actions.fetchTags.FAILURE:
      return {
        ...state,
        tags: {
          ...state.tags,
          loading: false,
          error: action.payload,
        },
      }
    case actions.createTag.SUCCESS:
      return {
        ...state,
        tags: {
          ...state.tags,
          data: [...state.tags.data, action.payload],
        },
      }
    //* reset data when change company
    case authActions.selectCompany.SUCCESS:
      return {
        ...state,
        tags: {
          ...initialState.tags,
        },
        ledgerNumbers: [],
      }

    // SALARY
    case actions.uploadSalaryAndTaxV2.REQUEST:
      return {
        ...state,
        xmlUploading: true,
      }
    case actions.uploadSalaryAndTaxV2.SUCCESS:
    case actions.uploadSalaryAndTaxV2.FAILURE:
      return {
        ...state,
        xmlUploading: false,
      }

    // TAX CODES
    case actions.fetchTaxCodes.REQUEST:
      return {
        ...state,
        taxCodes: {
          ...state.taxCodes,
          loading: true,
          fetched: false,
        },
      }
    case actions.fetchTaxCodes.SUCCESS:
      return {
        ...state,
        taxCodes: {
          data: action.payload,
          loading: false,
          fetched: true,
          error: null,
        },
      }
    case actions.fetchTaxCodes.FAILURE:
      return {
        ...state,
        taxCodes: {
          ...state.taxCodes,
          loading: false,
          error: action.payload,
        },
      }

    case actions.fetchLedgerNumbers.SUCCESS:
      return {
        ...state,
        ledgerNumbers: action.payload,
      }

    case actions.createLedgerNumber.SUCCESS:
      return {
        ...state,
        ledgerNumbers: [...state.ledgerNumbers, action.payload],
      }

    default:
      return state
  }
}

export default reducer
