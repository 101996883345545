import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, IconButton, InputAdornment } from '@material-ui/core'
import cx from 'classnames'
import __debounce from 'lodash/debounce'
import __noop from 'lodash/noop'
import { Field, useField } from 'react-final-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { date, required } from 'redux-form-validators'

import { commonActions } from '@services'

import {
  bindActionToPromise,
  cancellablePromise,
  composeFieldValidators,
  formatDate,
  getCurrency,
  getDecimal,
  parseApiErrorMessage,
} from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { DateInput, RenderTextField } from '@oldComponents/ui/form'
import { UpdateIcon } from '@oldComponents/ui/SvgIcons'

import { EXCHANGE_RATE_DECIMAL_PLACES, FIELD_SUBSCRIPTION } from '@constants'

import { exchangeRateDateMessage, formErrorMessages, globalMessages } from '@messages'
import messages from '../messages'

interface ExchangeRateProps {
  classes: Record<string, string>
  companyDefaultCurrency: number
  currencyOptions: CommonIdAndNameType[]
  disabled: boolean
  fetchExchangeRate: AsyncFunction<FetchExchangeRatePayload, FetchExchangeRateResponse>
}

//! NOTE: this componenet is rendered only when curreny is not match with the companyDefaultCurrency
//! controlled by useExchangeRateProps hook's displayExchangeRateField value
function PureExchangeRate({
  classes,
  companyDefaultCurrency,
  currencyOptions,
  disabled,
  fetchExchangeRate,
}: ExchangeRateProps) {
  const { formatMessage } = useIntl()
  const { setErrorAlert } = useAlertDispatch()

  const {
    meta: { active: isExchangeDateActive, valid: isExchangeDateValid, initial: initialExchangeDate },
    input: { value: exchangeDate, onChange },
  } = useField<string>('exchange_date', {
    subscription: { active: true, value: true, initial: true, valid: true },
  })
  const {
    meta: { valid: isFulfilledAtValid },
    input: { value: fulfilledAtValue },
  } = useField<string>('fulfilled_at', { subscription: { value: true, valid: true } })
  const {
    meta: { initial: initialCurrency },
    input: { value: currency },
  } = useField<number>('currency', { subscription: { value: true, initial: true } })
  const {
    meta: { initial: initialExchangeRate },
    input: { value: exchangeRate, onChange: onChangeExchangeRate },
  } = useField<Decimal>('exchange_rate', { subscription: { value: true, initial: true } })

  const [loading, setLoading] = React.useState(false)
  const mountedRef = React.useRef(false)
  const isFormResetRef = React.useRef(true)
  const cPromiseRef = useCancellablePromiseRef<FetchExchangeRateResponse>()
  const updateRef = React.useRef(__noop)
  const initExchangeDateRef = React.useRef(__noop)

  const companyDefaultCurrencyName = React.useMemo(
    () => getCurrency(companyDefaultCurrency, currencyOptions),
    [companyDefaultCurrency, currencyOptions]
  )

  // debounced api call
  const debouncedFetch = React.useRef(
    __debounce((payload: FetchExchangeRatePayload) => {
      cPromiseRef.current = cancellablePromise(fetchExchangeRate(payload))
      cPromiseRef.current.promise
        .then(response => {
          setLoading(false)
          const decimalValue =
            response.exchange_rate.value === null
              ? ''
              : getDecimal(response.exchange_rate.value, {
                  maximumFractionDigits: EXCHANGE_RATE_DECIMAL_PLACES,
                  minimumFractionDigits: 0,
                })
          onChangeExchangeRate(decimalValue)
        })
        .catch(error => {
          const errorMessage = parseApiErrorMessage(error)
          if (errorMessage) {
            setErrorAlert(errorMessage)
            setLoading(false)
          }
        })
    }, 1000)
  )

  isFormResetRef.current =
    currency === initialCurrency && exchangeDate === initialExchangeDate && exchangeRate === initialExchangeRate

  updateRef.current = () => {
    if (mountedRef.current) {
      setLoading(true)
    }
    const currentCurrencyName = getCurrency(currency, currencyOptions)
    debouncedFetch.current({
      exchange_date: exchangeDate,
      from_currency: currentCurrencyName,
      to_currency: companyDefaultCurrencyName,
    })
  }

  initExchangeDateRef.current = () => {
    let date = exchangeDate
    if (!date) {
      if (fulfilledAtValue && isFulfilledAtValid) {
        date = fulfilledAtValue
      }
      if (!date) {
        // current date
        date = formatDate()
      }
      onChange(date)
    }
  }

  //* ON MOUNT
  React.useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  // do not update any field when invoice is not editable: disable=True (comes from integration or already accounted)

  //* INIT exchange_date
  React.useEffect(() => {
    if (disabled || !isExchangeDateActive || exchangeDate) {
      return
    }

    // do not update exchange_date until user interact with the field
    initExchangeDateRef.current()
  }, [currency, disabled, exchangeDate, isExchangeDateActive])

  //* ON UPDATE: curreny or exchangeDate
  React.useEffect(() => {
    if (disabled || isFormResetRef.current) {
      return
    }

    // update
    if (mountedRef.current && currency && exchangeDate && isExchangeDateValid) {
      updateRef.current()
    }

    // mount
    mountedRef.current = true
  }, [currency, disabled, exchangeDate, initialCurrency, initialExchangeDate, initialExchangeRate, isExchangeDateValid])

  const endAdornment = React.useMemo(() => {
    if (disabled) {
      return null
    }
    return (
      <InputAdornment position="end">
        {loading ? (
          <CircularProgress color="secondary" size={16} />
        ) : (
          <IconButton
            onClick={event => {
              event.preventDefault()
              updateRef.current()
            }}
            className={classes.refreshButton}
            title={formatMessage(exchangeRateDateMessage)}
          >
            <UpdateIcon size={16} />
          </IconButton>
        )}
      </InputAdornment>
    )
  }, [disabled, loading, classes.refreshButton, formatMessage])

  return (
    <Field
      subscription={FIELD_SUBSCRIPTION}
      component={RenderTextField}
      disabled={disabled}
      name="exchange_date"
      label={formatMessage(messages.exchangeDateLabel)}
      InputProps={{
        classes: {
          root: cx(classes.bootstrapRoot, classes.refreshRoot, {
            [classes.refreshDisabled]: disabled,
          }),
          input: cx(classes.bootstrapInput, classes.refreshInput),
          focused: classes.refreshFocused,
          error: classes.bootstrapError,
        },
        endAdornment: disabled ? null : endAdornment,
        inputComponent: DateInput,
        placeholder: formatMessage(globalMessages.dateFieldPlaceholder),
      }}
      required
      validate={composeFieldValidators(
        required({ message: formatMessage(formErrorMessages.required) }),
        date({
          format: 'yyyy-mm-dd',
          msg: formatMessage(formErrorMessages.invalidDate),
        })
      )}
    />
  )
}

PureExchangeRate.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  companyDefaultCurrency: PropTypes.number.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  fetchExchangeRate: PropTypes.func.isRequired,
}

export const ExchangeRate = connect(
  (state: Store) => ({
    companyDefaultCurrency: state.auth.company.data.default_currency,
    currencyOptions: state.dashboard.common.currencies,
  }),
  dispatch => ({
    fetchExchangeRate: bindActionToPromise(dispatch, commonActions.fetchExchangeRate.request),
  })
)(PureExchangeRate)

ExchangeRate.displayName = 'ExchangeRate'
