import React from 'react'

import { connect } from 'react-redux'

import { authActions } from '@services'

import { bindActionToPromise, cancellablePromise, companyHasIntegration, parseApiErrorMessage } from '@helpers'

import { useAssertContext, useCancellablePromiseRef } from '@hooks'

import { CompanyIntegrationProviders } from '@constants'

import { INITIAL_STATE, reducer } from './reducer'
import { IntegrationAction, IntegrationProvider, IntegrationsBlockState } from './types'

interface CompanyIntegrationsContextValue extends Pick<IntegrationsBlockState, 'companyIntegrations'> {
  updateCompanyIntegration: (payload: IntegrationProvider) => void
}

const CompanyIntegrationsContext = React.createContext<Nullable<CompanyIntegrationsContextValue>>(null)

CompanyIntegrationsContext.displayName = 'CompanyIntegrationsContext'

interface CompanyIntegrationsProviderProps {
  children: (renderProps: { error: Nullable<string>; loading: boolean }) => JSX.Element
  hasIntegration: boolean
  loadIntegrationProviders: AsyncFunction<void, IntegrationProvider[]>
}

function PureCompanyIntegrationsProvider({
  children,
  hasIntegration,
  loadIntegrationProviders,
}: CompanyIntegrationsProviderProps) {
  const cPromiseRef = useCancellablePromiseRef<IntegrationProvider[]>()
  const [{ companyIntegrations, error, loading }, dispatch] = React.useReducer(reducer, INITIAL_STATE)

  React.useEffect(() => {
    async function load() {
      dispatch({ type: IntegrationAction.INIT })
      try {
        cPromiseRef.current = cancellablePromise(loadIntegrationProviders())
        const results = await cPromiseRef.current.promise
        const payload = results.reduce(
          (obj, integrationProvider) => ({ ...obj, [integrationProvider.integration]: integrationProvider }),
          {}
        )
        dispatch({ type: IntegrationAction.SUCCESS, payload })
      } catch (error) {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          dispatch({ type: IntegrationAction.FAILURE, payload: errorMsg })
        }
      }
    }
    if (hasIntegration) {
      load()
    }
  }, [cPromiseRef, hasIntegration, loadIntegrationProviders])

  const updateCompanyIntegration = React.useCallback((payload: IntegrationProvider) => {
    dispatch({ type: IntegrationAction.UPDATE, payload })
  }, [])

  const contextValue = React.useMemo(
    () => ({
      companyIntegrations,
      updateCompanyIntegration,
    }),
    [companyIntegrations, updateCompanyIntegration]
  )

  return (
    <CompanyIntegrationsContext.Provider value={contextValue}>
      {children({ error, loading })}
    </CompanyIntegrationsContext.Provider>
  )
}

export const CompanyIntegrationsProvider = connect(
  (state: Store) => ({
    hasIntegration: companyHasIntegration(state),
  }),
  dispatch => ({
    loadIntegrationProviders: bindActionToPromise(dispatch, authActions.loadIntegrationProviders.request),
  })
)(PureCompanyIntegrationsProvider)

CompanyIntegrationsProvider.displayName = 'CompanyIntegrationsProvider'

export function useAcountoCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegration } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.acounto as const
    const data = companyIntegrations[integration]

    return {
      data,
      hasIntegration: Boolean(data),
      integration,
      updateCompanyIntegration,
    }
  }, [companyIntegrations, updateCompanyIntegration])
}

export function useBillingoCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegration } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.billingo as const
    const data = companyIntegrations[integration]

    return {
      data,
      hasIntegration: Boolean(data),
      integration,
      updateCompanyIntegration,
    }
  }, [companyIntegrations, updateCompanyIntegration])
}

export function usePayeeCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegration } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.payee as const
    const data = companyIntegrations[integration]

    return {
      data,
      hasIntegration: Boolean(data),
      integration,
      updateCompanyIntegration,
    }
  }, [companyIntegrations, updateCompanyIntegration])
}

export function useSzamlazzCompanyIntegration() {
  const { companyIntegrations, updateCompanyIntegration } = useAssertContext(CompanyIntegrationsContext)

  return React.useMemo(() => {
    const integration = CompanyIntegrationProviders.szamlazz as const
    const data = companyIntegrations[integration]

    return {
      data,
      hasIntegration: Boolean(data),
      integration,
      updateCompanyIntegration,
    }
  }, [companyIntegrations, updateCompanyIntegration])
}
