import React from 'react'

import __filter from 'lodash/filter'
import __find from 'lodash/find'
import { Field, FieldProps, FieldRenderProps, useField, useForm } from 'react-final-form'

import { usePrevious } from '@hooks/usePrevious'

import { InvoiceType, USE_FIELD_VALUE_CONFIG } from '@constants'

const INVOICE_CLASS_FILTER_MAP = {
  [InvoiceType.EXPENSE]: 0,
  [InvoiceType.INCOME]: 1,
}

interface CompanyVatCategoryFieldProps extends FieldProps<number, FieldRenderProps<number>> {
  fieldNamePrefix: string
  options: CompanyVatCategory[]
  invoiceType: InvoiceType
  vatOptions: CompanyVatType[]
}

export function CompanyVatCategoryField({
  fieldNamePrefix,
  options,
  invoiceType,
  vatOptions,
  ...rest
}: CompanyVatCategoryFieldProps) {
  const {
    input: { value: assignmentVatId },
    meta: { initial: initialAssignmentVatId },
  } = useField<number>(`${fieldNamePrefix}.vat`, { subscription: { value: true, initial: true } })
  const prevAssignmentVatId = usePrevious(assignmentVatId)
  const prevInitialAssignmentVatId = usePrevious(initialAssignmentVatId)
  const {
    input: { value: vatArea },
  } = useField<string>('vat_area', USE_FIELD_VALUE_CONFIG)
  const {
    input: { value: companyVatCategory },
  } = useField<string>(rest.name, USE_FIELD_VALUE_CONFIG)
  const { change } = useForm()

  // reset current field value when it is already set and vat changed
  React.useEffect(() => {
    if (
      companyVatCategory &&
      prevAssignmentVatId != null &&
      assignmentVatId !== prevAssignmentVatId &&
      prevInitialAssignmentVatId === initialAssignmentVatId // not reinitialize
    ) {
      change(rest.name, null)
    }
  }, [
    assignmentVatId,
    change,
    companyVatCategory,
    initialAssignmentVatId,
    prevAssignmentVatId,
    prevInitialAssignmentVatId,
    rest.name,
  ])

  const filteredOptions = React.useMemo(() => {
    const percentFilter = __find(vatOptions, { id: assignmentVatId })?.percent ?? null
    const invoiceClassFilter = INVOICE_CLASS_FILTER_MAP[invoiceType]
    return __filter(options, { percent: percentFilter, invoice_class: invoiceClassFilter, vat_area: vatArea })
  }, [assignmentVatId, invoiceType, options, vatArea, vatOptions])

  return <Field valueKey="id" labelKey="name" options={filteredOptions} {...rest} />
}
