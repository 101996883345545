// always sync with ExpenseDetailsStatus in invoice.d.ts
export enum InvoiceStatus {
  INITIALIZE = 'INITIALIZE',
  CREATE_ARTIFACT = 'CREATE_ARTIFACT',
  CLASSIFY = 'CLASSIFY',
  ASSIGN = 'ASSIGN',
  CLASSIFIED = 'CLASSIFIED',
  UPDATE = 'UPDATE',
  CHECKED = 'CHECKED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  EXPORTED = 'EXPORTED',
}
