import { MutableState, Tools } from 'final-form'

export function setFieldError<FormValues extends object>(
  args: [string, string],
  state: MutableState<FormValues>,
  tools: Tools<FormValues>
) {
  const [name, error] = args
  const field = state.fields[name]
  if (field) {
    field.touched = true
    field.data = tools.setIn(field.data, 'error', error) || {}
  }
}
