import React from 'react'
import PropTypes from 'prop-types'

import { useAssertContext } from '@hooks/useAssertContext'

interface ExpenseDetailsControlsDispatchProps {
  setAccountingInProgress: (payload: boolean) => void
  setActionError: (payload: Nullable<string>) => void
  setApproveInProgress: (payload: boolean) => void
  setDeleteInProgress: (payload: boolean) => void
  setExcludeFromAccountingInProgress: (payload: boolean) => void
  setFormDirty: (payload: boolean) => void
  setFormSubmittingInProgress: (payload: boolean) => void
  setUnsavedChangesBlocked: (payload: boolean) => void
}

interface ExpenseDetailsControlsStateProps {
  actionError: Nullable<string>
  isAccountingInProgress: boolean
  isActionInProgress: boolean
  isApproveInProgress: boolean
  isDeleteInProgress: boolean
  isExcludeFromAccountingInProgress: boolean
  isFormDirty: boolean
  isUnsavedChangesBlocked: boolean
}

const ExpenseDetailsControlsDispatchContext = React.createContext<ExpenseDetailsControlsDispatchProps | undefined>(
  undefined
)
const ExpenseDetailsControlsStateContext = React.createContext<ExpenseDetailsControlsStateProps | undefined>(undefined)

enum ExpenseDetailsActions {
  ACCOUNTING = 'accounting',
  APPROVE = 'approve',
  DELETE = 'delete',
  EXCLUDE_FROM_ACCOUNTING = 'excludeFromAccounting',
  FORM_SUBMIT = 'submit',
}

interface ExpenseDetailsControlsProviderProps {
  children: React.ReactNode
}

export function ExpenseDetailsControlsProvider({ children }: ExpenseDetailsControlsProviderProps) {
  const [actionInProgress, setActionInProgress] = React.useState<Nullable<ExpenseDetailsActions>>(null)
  const [actionError, setActionError] = React.useState<ExpenseDetailsControlsStateProps['actionError']>(null)
  const [isUnsavedChangesBlocked, setUnsavedChangesBlocked] = React.useState(false)
  const [isFormDirty, setFormDirty] = React.useState(false)

  const stateValue = React.useMemo(
    () => ({
      actionError: actionError,
      isAccountingInProgress: actionInProgress === ExpenseDetailsActions.ACCOUNTING,
      isActionInProgress: Boolean(actionInProgress),
      isApproveInProgress: actionInProgress === ExpenseDetailsActions.APPROVE,
      isDeleteInProgress: actionInProgress === ExpenseDetailsActions.DELETE,
      isExcludeFromAccountingInProgress: actionInProgress === ExpenseDetailsActions.EXCLUDE_FROM_ACCOUNTING,
      isUnsavedChangesBlocked,
      isFormDirty,
    }),
    [actionError, actionInProgress, isUnsavedChangesBlocked, isFormDirty]
  )

  const dispatchValue = React.useMemo(
    () => ({
      setAccountingInProgress: (payload: boolean) =>
        setActionInProgress(payload ? ExpenseDetailsActions.ACCOUNTING : null),
      setApproveInProgress: (payload: boolean) => setActionInProgress(payload ? ExpenseDetailsActions.APPROVE : null),
      setDeleteInProgress: (payload: boolean) => setActionInProgress(payload ? ExpenseDetailsActions.DELETE : null),
      setExcludeFromAccountingInProgress: (payload: boolean) =>
        setActionInProgress(payload ? ExpenseDetailsActions.EXCLUDE_FROM_ACCOUNTING : null),
      setFormDirty,
      setFormSubmittingInProgress: (payload: boolean) =>
        setActionInProgress(payload ? ExpenseDetailsActions.FORM_SUBMIT : null),
      setUnsavedChangesBlocked,
      setActionError,
    }),
    []
  )

  return (
    <ExpenseDetailsControlsDispatchContext.Provider value={dispatchValue}>
      <ExpenseDetailsControlsStateContext.Provider value={stateValue}>
        {children}
      </ExpenseDetailsControlsStateContext.Provider>
    </ExpenseDetailsControlsDispatchContext.Provider>
  )
}

ExpenseDetailsControlsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useExpenseDetailsControlsState() {
  return useAssertContext(ExpenseDetailsControlsStateContext)
}

export function useExpenseDetailsControlsDispatch() {
  return useAssertContext(ExpenseDetailsControlsDispatchContext)
}
