import React from 'react'

import __groupBy from 'lodash/groupBy'
import { useForm } from 'react-final-form'

import { getMaximumFractionDigitsByCurrency, roundToDecimal } from '@helpers'

//* local helper
function sumByField(items: InvoiceDetailsItem[], key: keyof InvoiceDetailsItem) {
  return items.reduce((sum, item) => (sum += Number(item[key]) ?? 0), 0)
}

interface AssignmentsBreakdownFromNAVProps extends Record<string, unknown> {
  hasIntegration: boolean
  isEditDisabled: boolean
  isNavInvoice: boolean
  items: InvoiceDetailsItem[]
  companyVats: CompanyVatType[]
  emptyValues: ExpenseDetailsAssignment | IncomeDetailsAssignment
}

export function useAssignmentsBreakdownFromNAV({
  hasIntegration,
  isEditDisabled,
  isNavInvoice,
  items,
  companyVats,
  emptyValues,
}: AssignmentsBreakdownFromNAVProps) {
  const { change, getFieldState } = useForm()
  const isEnabled = (isNavInvoice || hasIntegration) && !isEditDisabled

  const vatMap = React.useMemo(
    () =>
      companyVats
        .filter(({ percent }) => percent !== null) // filter out mixed VAT option (percent: null)
        .reduce((vMap, vat) => ({ ...vMap, [vat.percent as number]: vat.id }), {} as Record<number, number>),
    [companyVats]
  )

  const handleBreakdown = React.useCallback(() => {
    // when no items push at least one assignment
    let assignments = [emptyValues]

    if (items.length) {
      assignments = items.map(item => ({
        ...emptyValues,
        vat: item.vat_percent == null ? null : vatMap[item.vat_percent] ?? null,
        net_amount: item.net_amount,
        gross_amount: item.gross_amount,
        vat_amount: item.vat_amount,
      }))
    }

    change('assignments', assignments)
  }, [change, emptyValues, items, vatMap])

  const handleBreakdownByVAT = React.useCallback(() => {
    const currency = getFieldState('currency')?.value
    const maximumFractionDigits = getMaximumFractionDigitsByCurrency(currency)
    // when no items push at least one assignment
    let assignments = [emptyValues]

    if (items.length) {
      const groups = __groupBy(items, 'vat_percent')

      assignments = Object.entries(groups).map(([vatKey, vatItems]) => ({
        ...emptyValues,
        vat: vatMap[Number(vatKey)] ?? null,
        net_amount: roundToDecimal(sumByField(vatItems, 'net_amount'), { maximumFractionDigits, numeric: false }),
        gross_amount: roundToDecimal(sumByField(vatItems, 'gross_amount'), { maximumFractionDigits, numeric: false }),
        vat_amount: roundToDecimal(sumByField(vatItems, 'vat_amount'), { maximumFractionDigits, numeric: false }),
      }))
    }

    change('assignments', assignments)
  }, [change, emptyValues, getFieldState, items, vatMap])

  return { isEnabled, handleBreakdown, handleBreakdownByVAT }
}
