import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { Field, useField, useFormState } from 'react-final-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import {
  // addValidator,
  numericality,
  required,
} from 'redux-form-validators'

import {
  composeFieldValidators,
  customEngineValidator,
  renderRecommendationTextField,
  userHasEditorPermission,
} from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useAssignmentsBreakdownFromNAV, usePromptTextCreator } from '@hooks'

import { ChangeFieldListener } from '@components/forms'
import {
  AddCircleIcon,
  AmountInput,
  ButtonWithIcon,
  CopyRegularIcon,
  IconButton,
  TrashRegularIcon,
} from '@components/ui'
import { RenderFormFieldSelect, RenderTextField } from '@oldComponents/ui/form'

import {
  EMPTY_EXPENSE_ASSIGNMENT,
  EMPTY_INCOME_ASSIGNMENT,
  FIELD_SUBSCRIPTION,
  INVOICE_AMOUNT_INPUT_DECIMAL_PLACES,
  InvoiceType,
} from '@constants'

import {
  onChangeAssignmentGrossAmount,
  onChangeAssignmentNetAmount,
  onChangeAssignmentVatAmount,
  onChangeCalculationBaseHandler,
  onCreateExpenseOrRevenueTypeHandler,
  onCreateTagHandler,
} from '../helpers'
import AssignmentsBreakdownButton from './AssignmentsBreakdownButton'
import CalculationInputAdornment from './CalculationInputAdornment'
import { CompanyVatCategoryField } from './CompanyVatCategoryField'
import { ContentWithHighlightBlock } from './ContentWithHighlightBlock'
import { copyAssignment, getAssignmentFields } from './helpers'
import { JobNumberSelect } from './JobNumberSelect'
import { AssignmentLedgerNumberSelect, VatLedgerNumberSelect } from './LedgerNumberSelects'
import { InvoiceAssignmentsFields, InvoiceAssignmentsProps } from './types'

import { formErrorMessages, formSelectMessages } from '@messages'
import messages from '../messages'
import styles, { SPACING } from '../styles'

const useStyles = makeStyles(styles)

const GROSS_AMOUNT_CHANGE_LISTENER_SUBSCRIPTION = { initial: true, value: true }

function PureInvoiceAssignments({
  // manually passed down props
  assignmentNotesRecommendations = [], // IncomeDetailsForm not use it yet
  calculationBase,
  company,
  companyVatCategoryOptions = [], // can be optional later
  createNewTag,
  createNewType,
  creatingNewTag,
  hasIntegration,
  invoiceIntegrationItems,
  invoiceType,
  isAdvancedAccountingAvailableForUser = false, // IncomeDetailsForm not use it yet
  isEditDisabled,
  isNavInvoice,
  jobNumberOptions = [], // IncomeDetailsForm not use it yet
  readOnly,
  setNewTag,
  vatOptions,
  // connected props
  clearCustomValidationError,
  currencyOptions,
  expenseTypeOptions,
  expenseUploading,
  fields,
  hasEditorPermission,
  incomeUploading,
  isMissing,
  isMissingForAccounting,
  isTagsDisabledForUser,
  revenueTypeOptions,
  tagOptions,
  updatePartnerCalculationBase,
}: InvoiceAssignmentsProps) {
  const classes = useStyles()
  const { setErrorAlert } = useAlertDispatch()
  const { formatMessage } = useIntl()
  const {
    submitting,
    values,
    values: { assignments, currency, gross_amount },
  } = useFormState({ subscription: { submitting: true, values: true } })

  const {
    isEnabled: isAssignmentsBreakdownEnabled,
    handleBreakdown: handleAssignmentsBreakdown,
    handleBreakdownByVAT: handleAssignmentsBreakdownByVAT,
  } = useAssignmentsBreakdownFromNAV({
    hasIntegration,
    isEditDisabled: readOnly || isEditDisabled,
    isNavInvoice,
    items: invoiceIntegrationItems,
    companyVats: vatOptions,
    emptyValues: invoiceType === InvoiceType.EXPENSE ? EMPTY_EXPENSE_ASSIGNMENT : EMPTY_INCOME_ASSIGNMENT,
  })
  const [creatingNewType, setNewType] = React.useState<Record<string, boolean>>({})
  const grossTotal = gross_amount || 0

  // only need to check assignments filled status in expense details
  const needHighlight =
    isMissing && invoiceType === InvoiceType.EXPENSE
      ? // valid when all empty or all filled, mixed is invalid
        assignments.some((assignmentValue: ExpenseDetailsAssignment) => Boolean(assignmentValue.expense_type)) &&
        !assignments.every((assignmentValue: ExpenseDetailsAssignment) => Boolean(assignmentValue.expense_type))
      : false

  // Create new expense or income type fieldset
  function addAssignment(fields: InvoiceAssignmentsFields) {
    return function handler() {
      const assignmentFields = getAssignmentFields({
        currency,
        fields,
        grossTotal,
        vatOptions,
      })

      fields.push({
        ...(invoiceType === InvoiceType.EXPENSE ? EMPTY_EXPENSE_ASSIGNMENT : EMPTY_INCOME_ASSIGNMENT),
        ...assignmentFields,
      })
    }
  }

  // display controllers
  const isUploading = incomeUploading || expenseUploading
  const isFieldDisabled = submitting || isUploading
  const isAllFieldDisabled = readOnly || isEditDisabled || isFieldDisabled
  const isProcessedInvoice = !readOnly && isEditDisabled

  const partnerFieldValue = useField('partner_id').input.value
  const amountBasedToggleDisabled = !(hasEditorPermission && partnerFieldValue)

  // helper for messages
  const assignmentType = formatMessage(
    messages[invoiceType === InvoiceType.INCOME ? 'revenueAssignmentType' : 'expenseAssignmentType']
  )

  // helper for fieldoptions
  const selectFieldOptions = invoiceType === InvoiceType.INCOME ? revenueTypeOptions : expenseTypeOptions
  const promptTextCreator = usePromptTextCreator()

  const tagOnCreateHandler = React.useCallback(
    (newOption, selectRef) =>
      onCreateTagHandler({
        company,
        createNewTag,
        setErrorAlert,
        setNewTag,
        newOption,
        selectRef,
      }),
    [company, createNewTag, setErrorAlert, setNewTag]
  )

  const grossAmountInputProps = React.useMemo(
    () => ({
      endAdornment: (
        <CalculationInputAdornment
          name="gross_amount"
          current={calculationBase}
          toggle={calculation_base =>
            onChangeCalculationBaseHandler({
              company,
              updatePartnerCalculationBase,
              calculationBase,
              partner_id: values.partner_id,
              invoiceType,
              calculation_base,
            })
          }
          toggleDisabled={amountBasedToggleDisabled}
          title={formatMessage(messages.calculationTooltipGrossAmount)}
        />
      ),
      inputComponent: AmountInput,
      inputProps: { maximumFractionDigits: INVOICE_AMOUNT_INPUT_DECIMAL_PLACES },
    }),
    [
      amountBasedToggleDisabled,
      calculationBase,
      company,
      formatMessage,
      invoiceType,
      updatePartnerCalculationBase,
      values.partner_id,
    ]
  )

  const netAmountInputProps = React.useMemo(
    () => ({
      endAdornment: (
        <CalculationInputAdornment
          name="net_amount"
          current={calculationBase}
          toggle={calculation_base =>
            onChangeCalculationBaseHandler({
              company,
              updatePartnerCalculationBase,
              calculationBase,
              partner_id: values.partner_id,
              invoiceType,
              calculation_base,
            })
          }
          toggleDisabled={amountBasedToggleDisabled}
          title={formatMessage(messages.calculationTooltipNetAmount)}
        />
      ),
      inputComponent: AmountInput,
      inputProps: { maximumFractionDigits: INVOICE_AMOUNT_INPUT_DECIMAL_PLACES },
    }),
    [
      amountBasedToggleDisabled,
      calculationBase,
      company,
      formatMessage,
      invoiceType,
      updatePartnerCalculationBase,
      values.partner_id,
    ]
  )

  const vatAmountInputProps = {
    inputComponent: AmountInput,
    inputProps: { maximumFractionDigits: INVOICE_AMOUNT_INPUT_DECIMAL_PLACES },
  }

  return (
    <div data-testid="invoice-assignments">
      {isAssignmentsBreakdownEnabled && (
        <Grid container>
          <Grid item xs={12} className={classes.blockButtonsContainer}>
            <AssignmentsBreakdownButton onClick={handleAssignmentsBreakdownByVAT}>
              <FormattedMessage
                id="assignmentsBreakdown.buttons.vat"
                defaultMessage="ÁFA kulcsok szerinti tételbontás"
              />
            </AssignmentsBreakdownButton>
            <AssignmentsBreakdownButton onClick={handleAssignmentsBreakdown}>
              <FormattedMessage id="assignmentsBreakdown.buttons.default" defaultMessage="Automatikus tételbontás" />
            </AssignmentsBreakdownButton>
          </Grid>
        </Grid>
      )}
      {fields.map((field, index) => (
        <div
          key={`assignment-${index}`}
          className={cx(classes.asssignmentBlock, {
            disabled: isEditDisabled,
            highlighted: hasIntegration,
          })}
        >
          <Grid container className={classes.assignmentBlockTitle}>
            <Grid item xs={6}>
              <h4
                className={cx({
                  [classes.isEditDisabled]: !readOnly && isEditDisabled,
                })}
              >
                <FormattedMessage
                  id="form.assignmentBlock.title"
                  defaultMessage="{type} {value}"
                  values={{
                    value: fields.length && fields.length > 1 ? index + 1 : '',
                    type: assignmentType,
                  }}
                />
              </h4>
            </Grid>
            {!readOnly && !isEditDisabled && (
              <Grid item xs={6} className={classes.blockDeleteContainer}>
                <IconButton
                  title={formatMessage(messages.itemCopyButtonTitle, {
                    type: assignmentType,
                  })}
                  variant="primaryText"
                  size="small"
                  onClick={copyAssignment(fields, index, grossTotal, vatOptions, currency)}
                  disabled={isAllFieldDisabled}
                  data-testid="copy-assignment"
                >
                  <CopyRegularIcon />
                </IconButton>
                {fields.length && fields.length > 1 && (
                  <IconButton
                    onClick={() => {
                      fields.remove(index)
                    }}
                    title={formatMessage(messages.itemRemoveButtonTitle, {
                      type: assignmentType,
                    })}
                    variant="primaryText"
                    size="small"
                    className={classes.clearButton}
                    disabled={isAllFieldDisabled}
                    data-testid="remove-assignment"
                  >
                    <TrashRegularIcon />
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>

          <ContentWithHighlightBlock needHighlight={isProcessedInvoice}>
            <Grid container spacing={SPACING}>
              <Grid item xs={6}>
                <Field
                  required={needHighlight}
                  highlighted={needHighlight}
                  subscription={FIELD_SUBSCRIPTION}
                  classes={classes}
                  component={RenderFormFieldSelect}
                  options={selectFieldOptions}
                  label={formatMessage(messages.itemCostTypeLabel)}
                  labelClassName={cx({
                    [classes.highlightedLabel]: !readOnly && (isProcessedInvoice || hasIntegration),
                  })}
                  name={`${field}.${invoiceType}_type`}
                  disabled={readOnly || isFieldDisabled}
                  onCreate={(newOption: { name: string }, selectRef: HTMLElement) =>
                    onCreateExpenseOrRevenueTypeHandler({
                      company,
                      createNewType,
                      creatingNewType,
                      key: `field_${index}_${invoiceType}_type`,
                      setNewType,
                      setErrorAlert,
                      newOption,
                      selectRef,
                    })
                  }
                  isLoading={creatingNewType?.[`field_${index}_${invoiceType}_type`] ?? false}
                  placeholder={formatMessage(
                    fields.length && fields.length < 2
                      ? messages.emptyAssignmentLabel
                      : formSelectMessages.creatableSelectPlaceholder
                  )}
                  promptTextCreator={promptTextCreator}
                  noResultsText={formatMessage(formSelectMessages.selectNoResultsText)}
                  onEmptyCreateText={formatMessage(formSelectMessages.typeEmptyCreateText, {
                    type: assignmentType.toLowerCase(),
                  })}
                  skipSorting
                  labelKey="name"
                  valueKey="id"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  subscription={FIELD_SUBSCRIPTION}
                  classes={classes}
                  inputClassName={classes.autoTagSelect}
                  component={RenderFormFieldSelect}
                  options={tagOptions}
                  label={formatMessage(messages.itemTagsLabel)}
                  labelClassName={cx({
                    [classes.highlightedLabel]: !readOnly && (isProcessedInvoice || hasIntegration),
                  })}
                  name={`${field}.tags`}
                  disabled={isTagsDisabledForUser || readOnly || isFieldDisabled}
                  onCreate={tagOnCreateHandler}
                  isLoading={creatingNewTag}
                  multi
                  placeholder={formatMessage(formSelectMessages.creatableSelectPlaceholder)}
                  promptTextCreator={promptTextCreator}
                  noResultsText={formatMessage(formSelectMessages.selectNoResultsText)}
                  onEmptyCreateText={formatMessage(formSelectMessages.tagsEmptyCreateText)}
                  labelKey="name"
                  valueKey="id"
                />
              </Grid>
            </Grid>
          </ContentWithHighlightBlock>

          <Grid container spacing={SPACING}>
            <Grid item xs={4}>
              <Field
                required
                highlighted={isMissing}
                subscription={FIELD_SUBSCRIPTION}
                render={RenderTextField}
                label={formatMessage(messages.grossAmountLabel)}
                labelClassName={cx({
                  [classes.highlightedLabel]:
                    (invoiceType === InvoiceType.EXPENSE && !readOnly && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && !readOnly && (isProcessedInvoice || hasIntegration)),
                })}
                name={`${field}.gross_amount`}
                disabled={isAllFieldDisabled}
                validate={composeFieldValidators(
                  customEngineValidator,
                  required({
                    message: formatMessage(formErrorMessages.required),
                  }),
                  numericality({
                    message: formatMessage(formErrorMessages.numericality),
                  })
                )}
                InputProps={grossAmountInputProps}
              />
              <ChangeFieldListener
                calculationBase={calculationBase}
                currencyOptions={currencyOptions}
                field={field}
                handler={onChangeAssignmentGrossAmount}
                name={`${field}.gross_amount`}
                subscription={GROSS_AMOUNT_CHANGE_LISTENER_SUBSCRIPTION}
                vatOptions={vatOptions}
              />
              <ChangeFieldListener name={`${field}.gross_amount`} handler={clearCustomValidationError} />
            </Grid>
            <Grid item xs={4}>
              <Field
                required
                highlighted={isMissing}
                subscription={FIELD_SUBSCRIPTION}
                classes={classes}
                component={RenderFormFieldSelect}
                options={vatOptions}
                labelKey="label"
                valueKey="id"
                label={formatMessage(messages.vatRateLabel)}
                labelClassName={cx({
                  [classes.highlightedLabel]:
                    (invoiceType === InvoiceType.EXPENSE && !readOnly && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && !readOnly && (isProcessedInvoice || hasIntegration)),
                })}
                name={`${field}.vat`}
                disabled={isAllFieldDisabled}
                validate={composeFieldValidators(
                  customEngineValidator,
                  required({
                    message: formatMessage(formErrorMessages.required),
                  })
                )}
                placeholder={formatMessage(formSelectMessages.selectPlaceholder)}
                noResultsText={formatMessage(formSelectMessages.selectNoResultsText)}
              />
              <ChangeFieldListener
                name={`${field}.vat`}
                handler={onChangeAssignmentVatAmount}
                field={field}
                vatOptions={vatOptions}
                calculationBase={calculationBase}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                required
                highlighted={isMissing}
                subscription={FIELD_SUBSCRIPTION}
                render={RenderTextField}
                label={formatMessage(messages.netAmountLabel)}
                labelClassName={cx({
                  [classes.highlightedLabel]:
                    (invoiceType === InvoiceType.EXPENSE && !readOnly && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && !readOnly && (isProcessedInvoice || hasIntegration)),
                })}
                name={`${field}.net_amount`}
                disabled={isAllFieldDisabled}
                validate={composeFieldValidators(
                  customEngineValidator,
                  required({
                    message: formatMessage(formErrorMessages.required),
                  }),
                  numericality({
                    message: formatMessage(formErrorMessages.numericality),
                  })
                )}
                InputProps={netAmountInputProps}
              />
              <ChangeFieldListener
                calculationBase={calculationBase}
                currencyOptions={currencyOptions}
                field={field}
                handler={onChangeAssignmentNetAmount}
                name={`${field}.net_amount`}
                vatOptions={vatOptions}
              />
            </Grid>
          </Grid>

          <Grid container spacing={SPACING} justifyContent="flex-end">
            {isAdvancedAccountingAvailableForUser && (
              <Grid item xs={4}>
                <CompanyVatCategoryField
                  highlighted={isMissingForAccounting}
                  subscription={FIELD_SUBSCRIPTION}
                  component={RenderFormFieldSelect}
                  label={formatMessage(messages.vatCategoryLabel)}
                  labelClassName={cx({
                    [classes.highlightedLabel]:
                      (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                      (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration)),
                  })}
                  name={`${field}.company_vat_category`}
                  disabled={isAllFieldDisabled}
                  fieldNamePrefix={field}
                  invoiceType={invoiceType}
                  options={companyVatCategoryOptions}
                  vatOptions={vatOptions}
                  placeholder={formatMessage(formSelectMessages.selectPlaceholder)}
                  noResultsText={formatMessage(messages.companyVatCategoryEmptyOptionsMessage)}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <Field
                subscription={FIELD_SUBSCRIPTION}
                render={RenderTextField}
                label={formatMessage(messages.vatAmountLabel)}
                labelClassName={cx({
                  [classes.highlightedLabel]:
                    (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration)),
                })}
                name={`${field}.vat_amount`}
                disabled={isAllFieldDisabled}
                validate={composeFieldValidators(
                  customEngineValidator,
                  numericality({
                    message: formatMessage(formErrorMessages.numericality),
                  })
                )}
                InputProps={vatAmountInputProps}
              />
            </Grid>
          </Grid>
          {isAdvancedAccountingAvailableForUser && (
            <Grid container spacing={SPACING}>
              <Grid item xs={4}>
                <Field
                  subscription={FIELD_SUBSCRIPTION}
                  component={RenderFormFieldSelect}
                  label={formatMessage(messages.jobNumberLabel)}
                  labelClassName={cx({
                    [classes.highlightedLabel]:
                      (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                      (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration)),
                  })}
                  name={`${field}.job_number`}
                  disabled={isAllFieldDisabled}
                  selectComponent={JobNumberSelect}
                  options={jobNumberOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  highlighted={isMissingForAccounting}
                  subscription={FIELD_SUBSCRIPTION}
                  component={RenderFormFieldSelect}
                  label={formatMessage(messages.ledgerNumberLabel)}
                  labelClassName={cx({
                    [classes.highlightedLabel]:
                      (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                      (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration)),
                  })}
                  name={`${field}.ledger_number`}
                  disabled={isAllFieldDisabled}
                  selectComponent={AssignmentLedgerNumberSelect}
                  fieldName={`${field}.${invoiceType}_type`}
                  options={selectFieldOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  highlighted={isMissingForAccounting}
                  subscription={FIELD_SUBSCRIPTION}
                  component={RenderFormFieldSelect}
                  label={formatMessage(messages.vatLedgerNumberLabel)}
                  labelClassName={cx({
                    [classes.highlightedLabel]:
                      (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                      (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration)),
                  })}
                  name={`${field}.vat_ledger_number`}
                  disabled={isAllFieldDisabled}
                  selectComponent={VatLedgerNumberSelect}
                  invoiceType={invoiceType}
                />
              </Grid>
            </Grid>
          )}
          {isAdvancedAccountingAvailableForUser && (
            <Grid container spacing={SPACING}>
              <Grid item xs={12}>
                <Field
                  classes={classes}
                  component={renderRecommendationTextField as any}
                  disabled={isAllFieldDisabled}
                  label={formatMessage(messages.commentLabel)}
                  labelClassName={cx({
                    [classes.highlightedLabel]:
                      (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                      (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration)),
                  })}
                  name={`${field}.note`}
                  options={assignmentNotesRecommendations}
                  subscription={FIELD_SUBSCRIPTION}
                />
              </Grid>
            </Grid>
          )}
        </div>
      ))}

      {!readOnly && !isEditDisabled && (
        <div
          className={cx(classes.assignmentButtonBlock, {
            highlighted: hasIntegration,
          })}
        >
          <ButtonWithIcon
            type="button"
            icon={<AddCircleIcon />}
            variant="primaryText"
            onClick={addAssignment(fields)}
            disabled={isFieldDisabled}
            data-testid="add-assignment"
            size="small"
          >
            {formatMessage(messages.newItemButtonText, {
              type: assignmentType.toLowerCase(),
            })}
          </ButtonWithIcon>
        </div>
      )}
    </div>
  )
}

PureInvoiceAssignments.propTypes = {
  assignmentNotesRecommendations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ),
  calculationBase: PropTypes.oneOf(['gross_amount', 'net_amount']).isRequired as React.Validator<CalculationBase>,
  clearCustomValidationError: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
  companyVatCategoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string as React.Validator<Nullable<string>>,
      country: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      invoice_class: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      percent: PropTypes.number.isRequired,
      vat_area: PropTypes.string.isRequired,
    }).isRequired
  ),
  createNewTag: PropTypes.func.isRequired,
  createNewType: PropTypes.func.isRequired,
  creatingNewTag: PropTypes.bool.isRequired,
  currencyOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  expenseTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.number.isRequired,
      id: PropTypes.number.isRequired,
      ledger_numbers: PropTypes.array,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired as React.Validator<ExpenseTypeData[]>,
  expenseUploading: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired as React.Validator<InvoiceAssignmentsFields>,
  hasEditorPermission: PropTypes.bool.isRequired,
  hasIntegration: PropTypes.bool.isRequired,
  incomeUploading: PropTypes.bool.isRequired,
  initialValues: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        vat_percent: PropTypes.number.isRequired,
        net_amount: PropTypes.number.isRequired,
        gross_amount: PropTypes.number.isRequired,
        vat_amount: PropTypes.number.isRequired,
      }).isRequired
    ),
  }).isRequired as React.Validator<InvoiceDetailsFormInitialValues>,
  invoiceType: PropTypes.oneOf(Object.values(InvoiceType)).isRequired as React.Validator<InvoiceType>,
  isAdvancedAccountingAvailableForUser: PropTypes.bool,
  invoiceIntegrationItems: PropTypes.arrayOf(
    PropTypes.shape({
      vat_percent: PropTypes.number.isRequired,
      net_amount: PropTypes.string.isRequired,
      gross_amount: PropTypes.string.isRequired,
      vat_amount: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired as React.Validator<InvoiceDetailsItem[]>,
  isEditDisabled: PropTypes.bool.isRequired,
  isMissing: PropTypes.bool,
  isMissingForAccounting: PropTypes.bool,
  isNavInvoice: PropTypes.bool.isRequired,
  isTagsDisabledForUser: PropTypes.bool.isRequired,
  jobNumberOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired
  ),
  readOnly: PropTypes.bool.isRequired,
  revenueTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  setNewTag: PropTypes.func.isRequired,
  tagOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      company: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  updatePartnerCalculationBase: PropTypes.func.isRequired,
  vatOptions: PropTypes.arrayOf(
    PropTypes.shape({
      cash_register_voucher_visibility: PropTypes.bool.isRequired,
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      percent: PropTypes.number as React.Validator<Nullable<number>>,
    }).isRequired
  ).isRequired,
}

function mapStateToProps(state: Store) {
  return {
    currencyOptions: state.dashboard.common.currencies,
    expenseTypeOptions: state.expense.expenseTypes.data,
    expenseUploading: state.expense.details.uploading,
    hasEditorPermission: userHasEditorPermission(state.auth.company.data.role),
    incomeUploading: state.income.details.uploading,
    revenueTypeOptions: state.income.revenueTypes.data,
    tagOptions: state.dashboard.tags.data,
    companyVatCategoryOptions: state.auth.company.data.vat_categories,
  }
}

export const InvoiceAssignments = connect(mapStateToProps)(PureInvoiceAssignments)

InvoiceAssignments.displayName = 'InvoiceAssignments'
