// TODO: move this into components/form

import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress } from '@material-ui/core'
import cx from 'classnames'
import __get from 'lodash/get'
import __isObject from 'lodash/isObject'
import __orderBy from 'lodash/orderBy'
import __uniqueId from 'lodash/uniqueId'
import { useForm } from 'react-final-form'
import { connect } from 'react-redux'
import { components as reactSelectComponents } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'

import partnersActions from '@services/partners/actions'

import { CustomOption } from '@oldComponents/pages/CegjelzoPage/Fields'

import { selectFormStyles as customReactSelectStyles } from '../styles/select'
import { isFieldHighlighted } from './form'
import { customFilterOption } from './select-filter'
import { bindActionToPromise } from './service'

const START_TO_SEARCH_CHAR_LIMIT = 2

const orderSelectOptions = (options, labelKey) =>
  __orderBy(
    options,
    [
      function (item) {
        return !item.prefix
      },
      function (item) {
        const label = __get(item, labelKey, '')
        if (typeof label === 'string') {
          return label.toLowerCase()
        } else {
          return label
        }
      },
    ],
    ['desc', 'asc']
  )

const generateOption = data => {
  const {
    id,
    name,
    calculation_base,
    zip_code,
    city,
    country,
    address,
    tax_number,
    account_number,
    prefix,
    is_kata_subject,
  } = data
  return {
    id,
    name,
    calculation_base,
    zip_code,
    city,
    country,
    address,
    tax_number,
    account_number,
    original_address: {
      address,
      city,
      country,
      zip_code,
    },
    prefix,
    is_kata_subject,
  }
}

const getRecommendedOptions = (recommendation, labelKey, valueKey) => {
  if (
    __isObject(recommendation) &&
    Object.prototype.hasOwnProperty.call(recommendation, 'options') &&
    Array.isArray(recommendation.options)
  ) {
    const { options: recommendations, texts } = recommendation

    return {
      label: texts.recommendationsLabel,
      options: orderSelectOptions(
        recommendations.map(value =>
          generateOption({
            ...value,
            [valueKey]: value[valueKey] ? value[valueKey] : value[labelKey],
            [labelKey]: value[labelKey],
            prefix: value[valueKey] ? null : texts.newOptionPrefix,
          })
        ),
        labelKey
      ),
    }
  }
}

const useRecommendation = (recommendation, labelKey, valueKey) => {
  const [recommendations, setRecommendations] = React.useState(null)

  React.useEffect(() => {
    setRecommendations(getRecommendedOptions(recommendation, labelKey, valueKey))
  }, [recommendation, labelKey, valueKey])

  const clear = React.useCallback(
    usedLabelText => {
      if (__isObject(recommendations) && Object.prototype.hasOwnProperty.call(recommendations, 'options')) {
        // filter out
        // it has only 1 and match then clear
        const availableRecommendations = recommendations.options.filter(o => o[labelKey] !== usedLabelText)

        if (availableRecommendations.length === 0) {
          setRecommendations(null)
        } else {
          setRecommendations({
            ...recommendations,
            options: availableRecommendations,
          })
        }
      }
    },
    [labelKey, recommendations]
  )

  return [recommendations, clear]
}

const useOptions = (option, noOptionsText) => {
  const emptyOption = React.useMemo(() => ({ name: noOptionsText, isMsg: true }), [noOptionsText])
  const [options, setOptions] = React.useState([])

  const { id, name, calculation_base, zip_code, city, country, address, tax_number, account_number, is_kata_subject } =
    option

  // NOTE do not put the whole option object into useEffect dependency because it will cause
  // an infinite re-render loop in unit test
  React.useEffect(() => {
    setOptions(
      id
        ? [
            generateOption({
              id,
              name,
              calculation_base,
              zip_code,
              city,
              country,
              address,
              tax_number,
              account_number,
              is_kata_subject,
            }),
          ]
        : []
    )
  }, [
    id,
    name,
    calculation_base,
    zip_code,
    city,
    country,
    address,
    tax_number,
    account_number,
    is_kata_subject,
    setOptions,
    emptyOption,
  ])

  return { options, setOptions, emptyOption }
}

// Components overrides
const LoadingIndicator = () => <CircularProgress color="secondary" size={16} />

const Option = ({ children, texts, ...props }) => {
  const {
    data,
    data: { prefix, isMsg },
  } = props

  const optionData = React.useMemo(() => {
    function buildAddress({ zip_code, city, country, address }) {
      return [country, zip_code, city, address].filter(f => f).join(' ')
    }
    return {
      ...data,
      address: buildAddress(data),
    }
  }, [data])

  if (isMsg) {
    return <div style={{ padding: '8px 12px', lineHeight: 1.1, color: '#B1B1B1', fontSize: '80%' }}>{children}</div>
  }

  return (
    <reactSelectComponents.Option {...props}>
      <CustomOption data={optionData} prefix={prefix && <span className="badge">{prefix + ' '}</span>} />
    </reactSelectComponents.Option>
  )
}
Option.propTypes = {
  children: PropTypes.node.isRequired,
  texts: PropTypes.any,
  data: PropTypes.shape({
    prefix: PropTypes.node,
    isMsg: PropTypes.bool,
  }).isRequired,
}

const componentOverrides = { Option, LoadingIndicator }

function PurePartnerSelect({
  defaultValue,
  disabled,
  labelKey,
  valueKey,
  onCreate,
  highlighted = false,
  input: { onChange, value, name, ...inputProps },
  createOptionText,
  noOptionsText,
  loadingText,
  placeholder,
  recommendation,
  searchPartners,
  updateCalculationBase,
}) {
  const [recommendedOptionGroup, clearRecommendation] = useRecommendation(recommendation, labelKey, valueKey)
  // if defaultValue is null pass down empty object instead
  const { options, setOptions, emptyOption } = useOptions(defaultValue ? defaultValue : {}, noOptionsText)
  const { batch, change } = useForm()
  // call updateCalculationBase when defaultValue change
  // form component will handle the comparison to decide whether need to update state or not
  React.useEffect(() => {
    updateCalculationBase(defaultValue)
  }, [defaultValue, updateCalculationBase])

  const onChangeHandler = React.useCallback(
    option => {
      const newValue = __get(option, valueKey, null)
      if (newValue !== value) {
        onChange(newValue)
        batch(() => {
          if (Object.prototype.hasOwnProperty.call(option, 'original_address')) {
            // update new invoice "partner_*" fields w/ partner data
            change('partner_name', option.name || '')
            change('partner_account_number', option.account_number || '')
            change('partner_tax_number', option.tax_number || '')
            change('partner_city', option.original_address.city || '')
            change('partner_country', option.original_address.country || '')
            change('partner_zip_code', option.original_address.zip_code || '')
            change('partner_address', option.original_address.address || '')
            change('partner_is_kata_subject', option.is_kata_subject || '')
          }
        })
      }
    },
    [batch, change, onChange, value, valueKey]
  )
  const onCreateSuccess = React.useCallback(
    partner => {
      const newPartnerOption = generateOption(partner)
      clearRecommendation(partner.name)
      setOptions([...options, newPartnerOption])
      onChangeHandler(newPartnerOption)
    },
    [onChangeHandler, options, setOptions, clearRecommendation]
  )

  const handleChange = React.useCallback(
    (option, { action }) => {
      if (action === 'select-option') {
        // check "prefix" option:
        // truthy: new recommendation call onCreate
        // falsy - existing recommendation call onChange
        // simple options has no prefix
        if (__get(option, 'prefix', false)) {
          // [true, false, null] === false
          onCreate({ [labelKey]: option[valueKey] }, onCreateSuccess)
        } else {
          // (creatable) select without or existing recommendations
          onChangeHandler(option)
        }
      }
    },
    [labelKey, onChangeHandler, onCreate, onCreateSuccess, valueKey]
  )

  const isValidNewOption = React.useCallback(
    (inputValue, selectValue, selectOptions) => {
      const compareOption = (value, option) => {
        const candidate = String(value).toLowerCase()
        // const valueString = String(__get(option, valueKey, '')).toLowerCase()
        const labelString = String(__get(option, labelKey, '')).toLowerCase()
        // return valueString === candidate || labelString === candidate
        return labelString === candidate
      }
      const isGroupedOption = (inputValue, option) => {
        // { label: String, options: Array}
        if (
          __isObject(option) &&
          Object.prototype.hasOwnProperty.call(option, 'label') &&
          Object.prototype.hasOwnProperty.call(option, 'options') &&
          Array.isArray(option.options)
        ) {
          return option.options.some(function (opt) {
            return isGroupedOption(inputValue, opt)
          })
        } else {
          return compareOption(inputValue, option)
        }
      }
      return !(
        !inputValue ||
        selectValue.some(function (option) {
          return compareOption(inputValue, option)
        }) ||
        selectOptions.some(function (option) {
          return isGroupedOption(inputValue, option)
        })
      )
    },
    [labelKey]
  )

  const getNewOptionData = React.useCallback(
    (inputValue, optionLabel) => ({
      [valueKey]: inputValue,
      [labelKey]: optionLabel,
    }),
    [labelKey, valueKey]
  )

  const promptTextCreator = React.useCallback(value => `${createOptionText}: "${value}"`, [createOptionText])

  const noOptionsMessage = React.useCallback(() => noOptionsText, [noOptionsText])
  const loadingMessage = React.useCallback(() => loadingText, [loadingText])

  // OPTIONS

  const defaultOptions = React.useMemo(() => {
    const { texts } = recommendation
    const results = []

    if (
      recommendedOptionGroup &&
      Array.isArray(recommendedOptionGroup.options) &&
      recommendedOptionGroup.options.length
    ) {
      results.push(recommendedOptionGroup)
    }

    if (options.length) {
      results.unshift({
        label: texts.optionsLabel,
        options,
      })
    }

    results.push(emptyOption)

    return results
  }, [recommendation, recommendedOptionGroup, options, emptyOption])

  const loadOptions = React.useCallback(
    inputValue => {
      const { texts } = recommendation

      if (inputValue.length >= START_TO_SEARCH_CHAR_LIMIT) {
        return new Promise(resolve => {
          searchPartners({ name: inputValue }).then(partners => {
            const mappedPartners = partners.map(generateOption)

            if (recommendedOptionGroup && recommendedOptionGroup.options.length) {
              resolve([
                {
                  label: texts.optionsLabel,
                  options: mappedPartners,
                },
                recommendedOptionGroup,
              ])
            } else {
              resolve([
                {
                  label: texts.optionsLabel,
                  options: mappedPartners,
                },
              ])
            }

            setOptions(mappedPartners)
          })
        })
      }

      return new Promise(resolve => {
        if (recommendedOptionGroup && recommendedOptionGroup.options.length) {
          resolve([
            {
              label: texts.optionsLabel,
              options: options,
            },
            recommendedOptionGroup,
          ])
        } else {
          resolve(options)
        }
      })
    },
    [options, recommendation, recommendedOptionGroup, searchPartners, setOptions]
  )

  const handleCreateOption = React.useCallback(
    newValue => {
      onCreate({ [labelKey]: newValue }, onCreateSuccess)
    },
    [onCreate, labelKey, onCreateSuccess]
  )

  const getOptionLabel = React.useCallback(option => option[labelKey], [labelKey])
  const getOptionValue = React.useCallback(option => option[valueKey], [valueKey])

  const memoizedValue = React.useMemo(() => {
    if (!value) {
      return value
    }
    return options.find(o => o[valueKey] === value)
  }, [options, valueKey, value])

  return (
    <AsyncCreatableSelect
      value={memoizedValue}
      classNamePrefix="react-select"
      className={cx({ highlighted: isFieldHighlighted(highlighted, value) })}
      isDisabled={disabled}
      placeholder={placeholder}
      instanceId={__uniqueId('ars')}
      inputId={name}
      {...inputProps}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions} // initially loaded
      onChange={handleChange}
      onCreateOption={handleCreateOption}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={loadingMessage}
      isValidNewOption={isValidNewOption}
      filterOption={customFilterOption}
      formatCreateLabel={promptTextCreator}
      getNewOptionData={getNewOptionData}
      styles={customReactSelectStyles}
      components={componentOverrides}
    />
  )
}

PurePartnerSelect.propTypes = {
  createOptionText: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    calculation_base: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool.isRequired,
  highlighted: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
  }).isRequired,
  labelKey: PropTypes.string.isRequired,
  loadingText: PropTypes.string.isRequired,
  noOptionsText: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  recommendation: PropTypes.shape({
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    texts: PropTypes.shape({
      recommendationsLabel: PropTypes.string.isRequired,
      optionsLabel: PropTypes.string.isRequired,
      newOptionPrefix: PropTypes.string.isRequired,
    }).isRequired,
  }),
  searchPartners: PropTypes.func.isRequired,
  updateCalculationBase: PropTypes.func.isRequired,
  valueKey: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['expense', 'revenue', 'quarantine']).isRequired,
}

function getPartnerFromDetails({ partner }) {
  if (!partner) {
    return null
  }
  const { id, name, calculation_base } = partner
  return { id, name, calculation_base }
}

function getInvoiceData(state, variant) {
  if (variant === 'expense') {
    return state.expense.details.data
  }
  return state.income.details.data
}

function mapStateToProps(state, ownProps) {
  const invoiceData = getInvoiceData(state, ownProps.variant)
  return {
    defaultValue: getPartnerFromDetails(invoiceData),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    searchPartners: bindActionToPromise(dispatch, partnersActions.searchPartners.request),
  }
}
const PartnerSelect = connect(mapStateToProps, mapDispatchToProps)(PurePartnerSelect)

PartnerSelect.displayName = 'PartnerSelect'

export default PartnerSelect
