import { AxiosResponse } from 'axios'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { parseFiltersFromUrlWorkerRunner, SyncFiltersPayload, taxListWorkerRunner } from '@webWorkers'
import { InitPageLoadSagaPayload } from '@services'
import { JOB_STATUS_FINISHED } from '@services/background/process'

import {
  generateBackgroundProcessActionPayload,
  getActiveCompanyId,
  getErrorMessage,
  getTaxListFiltersFromStore,
  getUrlFilterOptionsFromStore,
} from '@helpers'

import { PageChangePayload } from '@hooks'

import { BackgroundProcessActions } from '@constants'

import dashboardActions from '../dashboard/actions'
import { bulkTagging as bulkTaggingApi, fetchTags as fetchTagsApi } from '../dashboard/api'
import filtersActions from '../filters/actions'
import actions from './actions'
import * as api from './api'
import { ExportBackgroundAction } from './backgroundActions'

type TaxListResponse = AxiosResponse<BackendPaginatedListResponse<BackendTaxListResult>>

function* initTaxListPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}: AsyncSagaAction<InitPageLoadSagaPayload>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)

    const tagsResponse: AxiosResponse<Tag[]> = yield call(fetchTagsApi, companyId)
    yield put(dashboardActions.fetchTags.success(tagsResponse.data))

    const filterOptions: ReturnType<typeof getUrlFilterOptionsFromStore> = yield select(
      getUrlFilterOptionsFromStore,
      filtersStateKey
    )

    //* >> sync from url to store: call worker
    const { filters, params, validationLevel }: SyncFiltersPayload = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield all([
      put(filtersActions.initTaxListFiltersFromUrl.request({ filters })),
      put(filtersActions.initTaxListParamsFromUrl.request(params)),
    ])

    yield call(resolve, validationLevel)
    yield put(actions.fetchTaxListV2.request({ navigate }))
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
    yield put(actions.fetchTaxListV2.failure(errorMsg))
  }
}

// worker sagas
function* fetchTaxListV2Saga({ payload: { navigate } }: SagaAction<Pick<InitPageLoadSagaPayload, 'navigate'>>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: TaxListStoreFilters = yield select(getTaxListFiltersFromStore, { withCursor: true })
    // gather tags as we're going to need them for mapping purposes for the list
    const tags: Tag[] = yield select(
      ({
        dashboard: {
          tags: { data },
        },
      }: Store) => data
    )
    // start fetching expenses
    const listResponse: TaxListResponse = yield call(api.fetchTaxListV2, companyId, filters)
    // call worker
    const workerResults: TaxListData[] | WorkerError = yield call(taxListWorkerRunner, {
      taxes: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchTaxListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
    //* << sync from store to url
    const storedFilters: TaxListStoreFilters = yield select(getTaxListFiltersFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield put(actions.fetchTaxListV2.failure(errorMsg))
  }
}
//* When we do not need to fetch expense types and tags separately from the backend, only the list data (ie. when reordering)
function* fetchTaxListV2SimpleSaga() {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: TaxListStoreFilters = yield select(getTaxListFiltersFromStore)
    const tags: Tag[] = yield select(
      ({
        dashboard: {
          tags: { data },
        },
      }: Store) => data
    )

    // start fetching expenses
    const listResponse: TaxListResponse = yield call(api.fetchTaxListV2, companyId, filters)
    // call worker
    const workerResults: TaxListData[] | WorkerError = yield call(taxListWorkerRunner, {
      taxes: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchTaxListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield put(actions.fetchTaxListV2.failure(errorMsg))
  }
}

function* fetchTaxListByPagingV2Saga({ payload: { url } }: SagaAction<PageChangePayload>) {
  try {
    const tags: Tag[] = yield select(
      ({
        dashboard: {
          tags: { data },
        },
      }: Store) => data
    )

    const listResponse: TaxListResponse = yield call(api.fetchTaxListByPagingV2, url)
    // call worker
    const workerResults: TaxListData[] | WorkerError = yield call(taxListWorkerRunner, {
      taxes: listResponse.data.results,
      tags,
    })

    yield put(
      actions.fetchTaxListV2.success({
        data: workerResults,
        next: listResponse.data.next,
        previous: listResponse.data.previous,
      })
    )
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield put(actions.fetchTaxListV2.failure(errorMsg))
  }
}

//* v2 ACTIONS
function* bulkTaggingV2Saga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackendTaggingPayload>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    yield call(bulkTaggingApi, companyId, payload)
    yield put(actions.bulkTaggingV2.success())
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* bulkDeleteV2Saga({ payload, meta: { resolve, reject } }: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: TaxListStoreFilters = yield select(getTaxListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.DELETE_TAXES, payload, filters)
    const response: AxiosResponse<BackgroundActionResponse> = yield call(api.taxBackgroundAction, companyId, apiPayload)
    // every selected items are removed clear selection
    if (response.data.status === JOB_STATUS_FINISHED) {
      // only trigger selection clear when no rejected items
      yield put(actions.bulkDeleteV2.success())
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* startBulkExportV2Saga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<BackgroundActionRequestPayload>) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: TaxListStoreFilters = yield select(getTaxListFiltersFromStore)
    const apiPayload = generateBackgroundProcessActionPayload(BackgroundProcessActions.EXPORT_TAXES, payload, filters)
    const response: AxiosResponse<BackgroundActionResponse> = yield call(
      ExportBackgroundAction.start,
      companyId,
      apiPayload
    )
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* stopBulkExportV2Saga() {
  yield call(ExportBackgroundAction.stop)
}

// watcher Saga
export default function* commonSaga() {
  // list page controller init
  yield takeLatest(actions.initTaxListPageLoad.REQUEST, initTaxListPageLoadSaga)
  yield takeLatest(actions.fetchTaxListV2.REQUEST, fetchTaxListV2Saga)
  yield takeLatest(actions.fetchTaxListByPagingV2.REQUEST, fetchTaxListByPagingV2Saga)
  yield takeLatest(
    [
      actions.updateOrderV2.REQUEST,
      actions.updateRowsPerPageV2.REQUEST,
      filtersActions.toggleTaxListDateFilter.REQUEST,
      filtersActions.updateTaxListFilters.REQUEST,
      filtersActions.resetTaxListFilters.REQUEST,
      actions.bulkTaggingV2.SUCCESS,
      actions.triggerTaxListUpdate.REQUEST,
      actions.bulkDeleteV2.SUCCESS,
      dashboardActions.createMonthlyTaxV2.SUCCESS,
      dashboardActions.updateMonthlyTaxV2.SUCCESS,
      dashboardActions.removeMonthlyTaxV2.SUCCESS,
      actions.resetPagination.REQUEST,
    ],
    fetchTaxListV2SimpleSaga
  )
  yield takeLatest(actions.bulkTaggingV2.REQUEST, bulkTaggingV2Saga)
  yield takeLatest(actions.bulkDeleteV2.REQUEST, bulkDeleteV2Saga)
  yield takeLatest(actions.startBulkExportV2.REQUEST, startBulkExportV2Saga)
  yield takeLatest(actions.stopBulkExportV2.REQUEST, stopBulkExportV2Saga)
}
